.conversation-search {
  padding: 25px 35px 25px 22px;
  display: flex;
  align-items: center;
  position: relative;
}
.contactList {
  /* height: 100vmax; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.contact {
  flex-direction: row;
  align-self: center;
}

.conversation-search-input {
  color: #ffffff;
  padding: 8px 10px;
  padding-left: 50px;
  padding-top: 0;
  width: 288px;
  height: 47px;

  background: #17171d;
  /* opacity: 0.7; */
  border-radius: 30px;
  border: none;
  margin-left: -10px;
}
.conversation-search-inputbox {
  color: #ffffff;
  height: 47px;
  background: transparent;
  border-radius: 30px;
  border: none;
  width: 100%;
  font-size: 14px;
}

.dropdownnewwrapperMessenger {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* right: -150px;
  top: -45px; */
}

.dropdownArrowGroup {
  position: absolute;
  top: 55px;
  left: -170px;
}

.dropdownnewwrapperMessenger .dropdownnew {
  width: 180px;
}

/* .conversation-search-input::placeholder {
  text-align: center;
}

.conversation-search-input:focus::placeholder {
  text-align: center;
} */
.searchIcon {
  width: 31px;
  position: relative;
  left: 36px;
  height: 31px;
  top: 8px;
}
.menuIcon {
  vertical-align: initial;
  width: 21px;
  height: 20px;
}
.addGroupIcon {
  width: 40px;
  /* position: absolute;
  left: 317px;
  top: 18px;
  height: 16px; */
}
.plusIcon {
  /* width: 13px;
  position: absolute; */
  /* left: 295px;
  top: 20px */
  width: 40px;
}
.IconsDiv {
  cursor: pointer;
  text-align: center;
  background: transparent;
  border: 0;
}
.IconsDiv:hover,
.IconsDiv:focus,
.IconsDiv:active {
  background: none !important;
  border: none;
}

.dropdownArrowGroupNewChat {
  position: absolute;
  top: 10px;
  right: 10px;
}
.dropdownNewChat {
  display: block;
  /* right: 0px; */
  left: -40px;

  top: 28px;
  position: absolute;
  width: 177px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #17171d;
  opacity: 0.8;
  border-radius: 10px;
  z-index: 100;
}

.dropdownNewChat div {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
  padding: 5px 20px;
}
.dropdownNewChat a,
.dropdownNewChat a:hover {
  color: #ffffff;
  text-decoration: none;
}
