.select-box {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 16px;
  color: white;  
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10;
}

.select-box__current {
  position: relative;
  cursor: pointer;
  outline: none;
  max-height: 49px;
  height: 100%;
  background: #333333;
  color: white;
  border-radius: 25px;
}

.select-box__current:focus + .select-box__list {
  opacity: 1;
  transform: scaleY(1);
}
.select-box__current:focus + .select-box__list .select-box__option {
  cursor: pointer;
}
.select-box__current:focus .select-box__icon {
  transform: translateY(-50%) scaleY(-1);
}
.select-box__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 20px;
  transition: 0.2s ease;
}
.select-box__value {
  display: flex;
}
.select-box__input {
  display: none;
}

.select-box__input:checked + .select-box__input-text {
  display: flex;
  background: transparent;
  color: white;
  align-items: center;
  padding-left: 20px;
  height: 49px;
}
.select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  background: transparent;
}

.select-box__list {
  position: absolute;
  z-index: -1;
  top: 24.5px;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  transition: transform 0.25s;
  transform-origin: 50% 0%;
  transform: scaleY(0);
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}
.select-box__list::-webkit-scrollbar-track {
  background: #FFFFFF;
}
.select-box__list::-webkit-scrollbar-thumb {
  background: #888;
}

.select-box__list::-webkit-scrollbar {
  width: 8px;
}

.select-box__option {
  display: block;
  padding: 15px;
}

.select-box-list{
  background: #333333;
  display: flex;
  align-items: center;
}

.select-box-list:hover{
  background: #202020;
}

.select-box-list:first-child{
  padding-top: 24.5px;
}

.select-box-list:last-child{
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}


.select-box__option:first-child {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.select-box__option{
  margin: 0;
}
.select-box__option:hover, .select-box__option:focus {
  color: white;
}
@keyframes HideList {
  from {
    transform: scaleY(1);
 }
  to {
    transform: scaleY(0);
 }
}
