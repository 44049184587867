.member-container {
    display: flex;
    flex-direction: row;
    padding: 10px 0px 10px 20px;
    min-height: 75px;
}

.member-container .member-image-container {
    display: flex;
    /* flex: 2;
    justify-content: center; */
}

.member-container .member-name-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    color: #FFFFFF;
    font-weight: bold;
    padding-left: 20px;
}

.member-container .member-del-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}
.member-container .member-del-container svg{
    cursor: pointer;
    color: #FFFFFF;
}

.member-name-container .position {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: normal;
    opacity: 0.7;
}