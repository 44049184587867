.paymentBox{
    width: 300px;
    /* background: #17171D; */
    border-radius: 30px;
    min-height: 250px;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 10px;
}
.returnRDEEButton{
    background: #4453D6 !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: normal;
    font-family: "Segoe";
    font-size: 14px;
    color: #FFFFFF !important;
    border: none !important;
    width: 270px;
    height: 40px;
    margin-bottom: 15px;
}
.paymentTitlesuccess{
    margin-top: 20px;

    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 29px;
    text-align: center;

    color: #FFFFFF;
}
.paymentStatusBoxContainer{
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
}


.paymentGobacksuccess{
    font-family: "Segoe";
    font-size: 14px;
    color: #FFFFFF !important;
    text-align:center;
    margin: 0px;
}
.successIcon{
    width: 80px;
}

.failedPaymentIcon{
    width: 120px;
}

.failedDescription{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;

    color: #FFFFFF;

    opacity: 0.8;
}