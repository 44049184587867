.paymentContainer {
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
.userProfilemainContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  background: #FFFFFF;
}
.userprofileContent {
  /* display: flex; */
  /* flex: 6 1; */
  /* flex-direction: column; */
  width: calc(100vw - 500px);
  height: calc(100vh - 10px);
  overflow-y: auto;
  overflow-x: auto;
  padding-bottom: 80px;
}
.myProfileTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  margin-left: 40px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.profileImage {
  border: 5px solid #4453D6;
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border-radius: 86px;
}
.profileinnerContainer {
  margin-left: 30px;
  /* height: calc(100vh - 100px); */
  /* overflow: hidden; */
  /* overflow-y: scroll;
  overflow: auto; */
}
.userProfileBoxDetail {
  padding: 5px;
  background: #ffffff;
  border-radius: 9px;
  margin-right: 20px;
  width: calc(100vw - 500px);
  max-width: 1000px
  /* display: inline; */
}
.searchAbleName{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 38px;
  margin: 0px;
  color: #17171D;
}

.userProfileBoxDetail .username {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 65px;
  
  color: #333333;
  margin: 0px;
  /* margin-top: 43px; */
}

.userProfileBoxDetail .workplace {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
}
.workplace img {
  width: 18px;
}
.userProfileBoxDetail .userProfileBoxDetailunivercityplace {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #4453d6;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
}
.userProfileBoxDetailunivercityplace img {
  width: 18px;
  margin-right: 5px;
}
.userProfileBoxDetail .localtion {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
}
.localtion img {
  width: 15px;
}
.userProfileBoxDetail .buttonBox {
  float: right;
  display: grid;
}
.userProfileBoxDetail .ProfileButtons {
  margin: 15px;

  text-align: center;
  width: 200px;
  height: 50px;
  color: #ffffff;
  border: 0px;

  background: #4453d6;
  border-radius: 30px;

  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  margin-top: 51px;
  color: #ffffff;
  margin-bottom: 0px;
}
.userProfileBoxDetail .removeProfileButtons {
  margin: 15px;

  text-align: center;
  width: 200px;
  height: 50px;
  background: #ffffff !important;
  border: 0px;

  border: 1px solid #4453d6 !important;
  border-radius: 30px;
  margin-bottom: 0px;

  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;

  color: #4453d6 !important;
}

.contact-container-parent {
 width: 491px
}

.profileDiv {
  height: 100px !important;
  width: 100%;
  background: white;
  border-radius: 10px;
}
.txtColor {
  color: #fff !important;
}
.padd10 {
  padding: 10px;
}
.padd14 {
  padding: 14px;
  margin-top: 17px;
  word-break: break-word;
}
.marginT10 {
  margin-top: 10px;
}

.card {
  width: 100%;
  background: #333333;
  border-radius: 10px;
  padding: 10px;
}
.headeingname {
  height: 44px;
  width: 210px;
  line-height: 22px;
  background: #4453d6;
  border-radius: 0px 30px 30px 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding: 11px;
  padding-left: 25px;
}.headeingname .boldText{
  font-weight: bold;
}
.buttonCard {
  width: 100%;
  background: #333333;
  border-radius: 9px;
}

.titleP {
  margin-bottom: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-top: 2px;
  font-family: Segoe;
  color: #ffffff;
}

.titlePColor {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  
  color: #FFFFFF;
  
  opacity: 0.6;
}

.nameStyle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  padding-top: 2px;
  color: #FFFFFF;
}

.dateText {
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.borderBotton {
  border-bottom: 1px solid #adadad;
}
.buttonStyle {
  background: #4092b7;
  width: 100%;
  border-radius: 0px 0px 9px 9px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  min-height: 50px;

  color: #ffffff;
}
.columnTitle {
  margin-bottom: 20px;
}

.backButton {
  background: transparent;
  border: 0px;
}
.backButton img {
  width: 23px;
}
.ratingdiv {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  text-align: center;
  color: #ffffff;
}
.reviiewscn {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin: 0px;
  color: #A5A5A5;
  opacity: 0.6;
  padding-left: 10px;
  
}

.top100 {
  background: #f5d028;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #17171d;
  border: none;
  width: 78px;
  height: 25px;
  padding-top: 3px;
  margin-top: 2px;
}

.reputationsStatus {
  font-family: Segoe;
  font-style: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-top: 10px;
  font-weight: bold;
}
.reputationsStatus img{
  margin-right: 10px;
}
.reputationsStatus span{
  font-weight: normal;
  opacity: 0.6;
  padding-left: 5px;
  padding-right: 5px;
}
.viewallbuton {
  width: 104px;
  height: 38px;
  margin-top: 10px;
  background: #4453d6;
  border-radius: 30px 0px 0px 30px;
  border: none;
  cursor: pointer;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.infodiv {
  width: 399px;
  min-height: 145px;
  background: #17171d;
  border-radius: 10px;
  padding: 15px;
  margin: auto;
  
}
.FrankPa {
  margin: 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 10px;
}
.date2601 {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  color: #ffffff;
  padding-left: 10px;

  opacity: 0.6;
}
.timelkcnfjrf {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
}
.servicewdklhfs {
  margin-top: 10px;
  background: #4453d6;
  border-radius: 30px;
  height: 40px;
  width:max-content;
  text-align: center;
  padding: 10px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  
  color: #FFFFFF;
  padding-right: 15px;
  padding-left: 15px;
}
.profileCardBox{
  width:  472px;
  background: #333333;
  border-radius: 10px;
  padding-top: 20px;
  margin-right: 25px;
  padding-bottom: 20px;
  /* height: 460px; */
}
.profileCardBox .innerContentBox{
  padding: 10px;
  padding-left: 25px;
  padding-right: 20px;
}
.profileContentDiv{
  width: 111px;
}
.profileContentTitleDiv{
  width: 90px;
}
.reputationHeading{
  width:  158px;
  height: 44px;
  background: #4453D6;
  border-radius: 0px 30px 30px 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  padding: 11px;
  padding-left: 25px;
  color: #FFFFFF;
}

.educationCardBox{
  width:  313px;
  background: #333333;
  border-radius: 10px;
  padding-top: 20px;
  margin-right: 25px;
  min-height: 150px;
  margin-right: 15px;
  padding-bottom: 20px;
}
.educationCardBox .innerBox{
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.educationBlueButton{
  width: 161px;
  height: 44px;
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  background: #4453D6;
  border-radius: 0px 30px 30px 0px;
  color: #FFFFFF;
  padding: 10px;
  padding-left: 20px;
}
.registerBoxInnerContent{
  padding-left: 25px;
  padding-right: 20px;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1);*/
  padding-bottom: 10px; 
}


.userProfilebackbutton{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #666666;
  background: transparent;
  border: none;

}
.userProfilebackbutton:hover{
  background: transparent;
  color: #666666;
}
.userProfilebackbutton div{
  width: 25px;
  height: 25px;
  background: #4453D6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
 

}
.userProfilebackbutton span{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
}
.userProfilebackbutton img{
  width: 15px;
}

.BusinessDetailContactbuttonStyle {
  background: #4453D6 !important;
  width: 200px;
  border-radius: 30px 0px 0px 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  height: 49px;
  color: #ffffff !important;
  border: none !important;
  margin-bottom: 10px;
}

@media only screen and (min-width: 400px) and (max-width: 1025px) {

  .userprofileContent {
    width: calc(100vw - 20px);
  }

  .userProfileBoxDetail {
    width: calc(100vw - 50px);
  }


}


@media only screen and (min-width: 1920px)   {

 .userprofileContent{
  width: calc(100vw - 850px);
  margin-left: 330px;
  padding-top: 25px;
 }

.userProfileBoxDetail {
  width: 100% !important;
}
}
.noReviewText{
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  margin-top: 20px;
}
.profileUserStatus{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  display: inline-flex;
  color: #333333;
}

.otherUserprofileTaglineMessage{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-top: 0px !important;
  color: #17171D;

  opacity: 0.5;
}
