.newTransactionContainer {
  font-style: normal;
}
.floatR {
  float: right;
}
.textRight {
  text-align: right;
}
.mLeft10 {
  margin-left: 10px;
}
.displayFlex {
  display: flex;
}
.newTransactionTextMainDiv {
  /* width: calc(100vw - 550px);
  background: #FFFFFF;
  border-radius: 10px;
  overflow: hidden; */
}
.newTransactionheaderDiv {
  width: 100%;
  border-radius: 10px;
}
.newTransactionTextDiv {
  background: #4453d6;
  width: 260px;
  height: 44px;
  border-radius: 0px 30px 30px 0px;
  margin-top: 20px;
  padding: 10px;
}
.newTransactionText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.newTransactionDotBoldDiv {
  border: 1px dashed #c4c4c4;
  border-bottom-style: dotted;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}
.sendFundText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 42px;
  color: #333333;
  margin: 15px;
  text-transform: capitalize;
}
.newTransactionMainContactDiv {
  /* width: calc(100vw - 550px); */
  /* height: 533px; */
  height: calc(100vh - 250px);
  display: flex;
  /* justify-content: center; */
  padding-left: 40px;
  padding-right: 40px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.newTransactionContact {
  width: 470px;
  /* height: 533px; */
  overflow: hidden;
  overflow-y: auto;
  /* padding-left: 20px; */
}
.newTransactionSendingBlock {
  /* width: 462px;
  height: calc(100vh - 300px);
  overflow: hidden; */
  padding-left: 20px;
  padding-bottom: 20px;
  /* overflow-y: auto; */
}
.selectFromContactText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  opacity: 0.7;
}

.contactSearchNewTransaction {
  position: relative;
  padding-top: 10px;
}

.contactSearchNewTransaction .searchInput {
  width: 100%;
  height: 47px;
  border-radius: 50px;
  font-family: "Segoe";
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  background: #333333 !important;
  padding-left: 50px;
  color: #ffffff;
}
.contactSearchNewTransaction .searchInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1; /* Firefox */
}

.contactSearchNewTransaction .searchInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

.contactSearchNewTransaction .searchInput::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffffff;
}
.contactSearchNewTransaction .searchContactIcon {
  width: 26px;
  position: absolute;
  top: 22px;
  left: 13px;
}
.contactSearchNewTransactionBoxInput {
  position: relative;
  margin-left: auto;
}
.profileIconNewTransaction {
  margin-top: 10px;
  width: 50px;
  height: 50px;
  border: 2px solid #4453d6;
  box-sizing: border-box;
  border-radius: 86px;
}
.mLeft10 {
  margin-left: 10px;
}
.nameNewTransactionDiv {
  width: 462px !important;
  cursor: pointer;
}
.displayFlexNewTran {
  display: flex;
  padding-left: 20px;
}
.nameNewTransaction {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  margin: 0px;
  color: #17171d;
  line-height: 27px;
  padding-top: 3px;
}

.designationNewTransaction {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  color: #333333;
  opacity: 0.7;
  margin: 0px;
}
.instituteNewTransaction {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin: 0px;
  color: #333333;
  line-height: 16px;
  opacity: 0.7;
}
.sendingToText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 42px;
  /* or 175% */
  color: #333333;
}
.sendingToTextGray{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 42px;
  /* or 175% */
  color: #C4C4C4;
}
.enterAmountText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  margin-top: 10px;
  margin-left: 12px;
  margin-bottom: 5px;
  opacity: 0.7;
}
.enterAmountError{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color:  #D80031;
  margin-top: 10px;
  margin-left: 12px;
  margin-bottom: 5px;
}
.enterAmountInputType {
  background: black;
  width: 172px;
  height: 47px;
  border-radius: 30px;
  text-align: center;
  color: #ffff;
}
.mTop20 {
  margin-top: 20px;
}
.textArea {
  width: 100%;
  height: 136px;
  padding: 10px;
  background: #333333;
  border-radius: 10px;
  color: #ffff;
}
.RecipientText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  margin-top: 5px;
  margin-bottom: 5px;
  opacity: 0.7;
}
.sendFundButton {
  width: 156.76px;
  height: 40px;
  background: #4453d6 !important;
  color: #ffffff !important;
  text-align: center;
  border-radius: 30px;
  float: right;
  margin-right: 35px;

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: capitalize;
}
::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff !important;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  color: #ffffff !important;
}

::placeholder {
  color: #ffffff !important;
}

.pinBox {
  width: 414px;
  height: 269px;
  background: #333333;
  border-radius: 10px;
  padding-top: 1px;
  margin-top: 10px;
}
.enterPinTextDiv {
  background: #ffff;
  width: 130px;
  height: 44px;
  color: #333333;
  border-radius: 0px 30px 30px 0px;
  margin-top: 20px;
  padding: 10px;
  text-align: center;
}
.enterPinText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #333333;
}
.enterConfirmPinText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  padding: 10px 70px 0px 83px;

  color: #ffffff;
}
.textCenter {
  text-align: center;
}

.sendAnotherTransaction{
  background: #4453D6 !important;
  border-radius: 30px;
  width: 274px;
  height: 40px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
  color: #FFFFFF !important;
}
.returnToDashboard{
  background: #333333 !important;
  border-radius: 30px;
  width: 274px;
  height: 40px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: #FFFFFF !important;
}
.historycontaonermaindiv{


  width: calc(100vw - 540px);
  /* overflow: hidden; */
  margin-right: 30px;
  margin-left: 30px;
  background: #FFFFFF;
  border-radius: 20px;
  margin-top: 50px;
  min-height: 500px;

  
}


@media only screen and (min-width: 400px) and (max-width: 1025px) {
  .historycontaonermaindiv{
    width: calc(100vw - 50px);
  }
}

@media only screen and (min-width: 1920px)   {

  .historycontaonermaindiv{


    width: calc(100vw - 800px);
    
    margin-right: 30px;
    margin-left: 320px;
    background: #FFFFFF;
    border-radius: 20px;
    margin-top: 110px;

    
  }
}