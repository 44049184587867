/* .message-list-container {
  padding: 10px;
  padding-bottom: 70px;
} */
.chatUserTopHeader{
  display: inline-flex;
  align-items: center;
}
.chatUserTopHeader .avatarImg{
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

.confirmmsg{
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
}

.chatUserTopHeader .username{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin: 0px;
  color: #FFFFFF;
  padding-left: 10px;
}
.chatUserTopHeader .lastSeenStatus{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  margin: 0px;
  color: #FFFFFF;
  padding-left: 10px;

}

.menuOptionButton{
  background: transparent;
  border: 0px;
  padding: 0px;
  position: relative;
  top: 20px;
}
.menuOptionButton img{
  width: 25px;
}
.menuOptionButton:hover, .menuOptionButton:focus{
  background: transparent;
  border: 0px;
}
.menubuttonDiv{
  text-align: right;
  padding-right: 20px;
}
.toolbarStickySingleChat{
  /* position: sticky; */
  height: 80px;

}

.goDownScrollSingleChatButton{
    position: fixed;
    left: calc(100vw - 220px);
    bottom: 150px;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background: #FFFFFF !important;
    border: none !important;
    padding: 0px;
}
@media only screen and (min-width: 400px) and (max-width: 1025px) {
  .goDownScrollSingleChatButton {
    left: calc(100vw - 100px);
  }


}
@media only screen and (min-width: 1920px) {
  .goDownScrollSingleChatButton {
    left: calc(100vw - 250px);
    bottom: 200px;
  }


}

