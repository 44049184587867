.create-group-container {
    /* display: flex;
    flex-grow: 1; */
    padding: 10px 20px;


    display: inline-flex;
    width: calc(100vw - 10px);
    overflow: hidden;
    /* overflow-x: scroll; */
}

.create-group-container .group-info-container {
    display: flex;
    flex: 5;
    flex-direction: column;
}

.create-group-container .user-contact-container {
    display: flex;
    /* flex: 2; */
    width: 464px;
}

.create-group-container .group-info-container .group-info-header {
    display: flex;
    /* flex: 1; */
    font-weight: bold;
    padding: 5px 10px;
    color: #FFFFFF;
    font-size: 18px;
}


/* .create-group-container .group-info-container .group-info-body {
    display: flex;
    flex: 8;
    margin: 0 auto;
    flex-direction: column;
}
 */
.create-group-container .group-info-container .group-info-body .select-text {
    font-family: Helvetica;
    font-style: italic;
    font-weight: normal;
    color: #707070;
    padding: 20px;
    margin: auto;
}