.conversation-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:10px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.3);
  min-height: 73px;
  margin-top: 0px !important;
  margin-left: 10px;
  margin-right: 10px;

  
  /* border-width: medium;
  border-style: solid; */
}

.userActive-messenger-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.conversation-subView{
  display: flex;
  align-items: center;
  overflow: hidden;
}


.conversation-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 270px;
  overflow: hidden;
  position: relative;
  padding-top: 5px

  /* border-width: medium;
  border-style: solid; */
}

.conversation-photo-item {
  width: 50px;
  height: 50px;
  position: relative;
}
.conversation-photo-item::before{
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 2px solid #4453D6;
  overflow: hidden;

}

.conversation-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FF0000;
  float: right;
  align-self: center;
  margin-left: 2px;
  margin-top: 1px;
}

.conversation-title {
  font-family: 'Segoe';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 0px;
  min-width: 190px;
  color: #17171D;
  max-width: 190px;
  width: 190px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.conversation-list-item .date-item{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  justify-self: flex-end;
  width: max-content;
  min-width: 60px;
  text-align: right;
}

.conversation-list-item:hover .date-item{
  color: #888;
}

.conversation-title-date{
  position: absolute;
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
  top: 7px;
  right: 15px;
}

.conversation-snippet {

  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;

  color: #17171D;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right:20px;
  /* font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  height: 21px;
  overflow: hidden;
  margin-bottom: 0px; */
}

.conversation-list-item:hover .conversation-snippet {
  color: #FFFFFF;

  /* color: #888; */
  /* background: #333333;
  opacity: 0.5; */
}

.conversation-list-item:hover .conversation-title {
  color: #FFFFFF;
  /* color: #888; */
  /* background: #333333;
  opacity: 0.5; */
}

.conversation-list-item:hover{
  background: #17171D;
  opacity: 0.7;
  color: #FFFFFF;
  border-radius: 40px;
  /* height: 65px; */
}

.conversation-list-itemActive{
  background: #4453D6;
  opacity: 0.9;
  border-radius: 40px;
  color: #FFFFFF;
  height: 65px;
}
.conversation-list-itemActive .conversation-title ,.conversation-list-itemActive .conversation-snippet ,.conversation-list-itemActive .date-item{
  color: #FFFFFF;
}

.userStatusCircle{
  position: relative;
  right: 20px;
  top: 30px;
  height: 25px;
}
.userChatActiveStatus{
  width: 15px;
  height: 15px;
  border-radius: 50px;
  margin: 4px;
}

.chatListFileICons{
  width: 15px;
  margin-right: 5px;
}