.sellerorderDEtailContainer {
  display: inline-flex;
  /* background: linear-gradient(180deg, #4453d6 0%, #571b65 100%); */
  width: 100vw;
  /* height: calc(100vh - 85px); */
}
.sellergvSiemensAvapro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #ffffff;
}
.sellergvSiemensAvapro p {
  font-size: 35px;
  margin: 0px;
}
.sellerOrderInfo {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  width: 206px;
}

.orderPlacerData{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  
  color: #FFFFFF;
  
  opacity: 0.7;
}
.sellerconfcsirmedb {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  padding-left: 30px;
}
.sellerconfcsirmedb p {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  margin: 0px;
}
.sellerOrdeced50PM {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  opacity: 0.7;
}
.sellerdetsbackbutton {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #ffffff;
  background: transparent;
  border: none;
  margin-top: 5px;
  margin-right: 31px;
}
.sellerdetsbackbutton:hover {
  background: transparent;
  color: #ffffff;
}
.sellerdetsbackbutton div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.sellerdetsbackbutton span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  color: #ffffff  !important; 
}
.sellerdetsbackbutton img {
  width: 15px;
}
.sellermessagebuton {
  background: #333333  !important; 
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff  !important; 
}
.sellerconfirerbuton {
  background: #ffffff !important; 
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #17171d  !important; 
}
.sellercamcelorderbuton {
  background: #571b65  !important; 
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff  !important; 
}
.sellerrelistvgfbuton {
  background: #ffffff  !important; 
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #17171d  !important; 
}
.sellercancelleddiv {
  background: #5a5a5a;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  text-align: center;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff  !important; 
  opacity: 0.8;
  border: 0px;
  margin-left: 10px;
}
.sellerrelistncelleddiv {
  background: #ffffff;
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #17171d;
}
.sellerordopownpro {
  width: 523px;
  height: 37px;
  background: #333333;
  border-radius: 0px 30px 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  cursor: pointer;
  color: #FFFFFF;
}
.sellerdetopdnpro2 {
  width: 523px;
  height: 37px;
  background: #333333;
  border-radius: 0px 30px 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.placdSTIONSpro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding-left: 15px;
}
.sellerMureproscdv {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #767575;
  padding-left: 15px;
  padding-top: 12px;
}
.sellerordMRIpro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  padding-top: 12px;
  padding-left: 10px;
}
.sellerSystmepro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #767575;
  padding-left: 15px;
}
.sellereTSeapro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #f5d028;
  padding-left: 250px;
}

.placecloseimgstyle {
  transform: rotate(180deg);
  margin-right: 15px;
}
.Manufacturerpro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #767575;
  padding-left: 15px;
  padding-top: 12px;
}
.marketBuyerDownloadReportbutton {
  width: 150px;
  margin: auto;
  background: linear-gradient(180deg, #439fc8 0%, #4358a7 100%);
  border-radius: 5px;
  height: 34px;
  border: none;
  font-size: 14px;
}
.placerigCircleImage {
  display: flex;
  margin-left: 16px;
  flex-wrap: wrap;
}
.placerigCircleImage img {
  margin: 10px;
}
.selleradrepipro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #f5d028;
  padding-left: 215px;
}
.selleradrepipro a{
  color: #f5d028 !important;
}
.sellerelinfobopro {
  background: #333333;
  border-radius: 10px;
  width: 500px;
  min-height: 523px;
  margin-top: 26px;
  padding-bottom: 15px;
}
.sellersnfoheadiung {
  width: 226px;
  height: 44px;
  background: #ffffff;
  border-radius: 0px 30px 30px 0px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 42px;
  color: #333333;
  text-align: center;
}

.sellertcsdcdpro {
  background: #f5d028;
  border-radius: 30px;
  width: 78px;
  height: 25px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #17171d;
  opacity: 0.8;
  margin-left: 123px;
  line-height: 25px;
}
.sellerccdcrthapro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  color: #ffffff;
  margin: 0px;
  padding-left: 15px;
}
.sellerhjvfonpro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.7;
  padding-left: 15px;
}
.sellervbuttonprdcdco {
  background: #4453d6;
  border-radius: 30px;
  width: 164.77px;
  height: 37px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  border: none;
  margin-right: 10px;
}
.sellerRevprdcdco {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  opacity: 0.8;
  padding-left: 27px;
}
.sellerxrescdfpropro {
  width: 399px;
  height: 145px;
  background: #17171d;
  border-radius: 10px;
  margin-top: 10px;
  margin: auto;
  padding: 20px;
  overflow-x: auto;
}
.sellereFcdcdaulpro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
  padding-left: 12px;
}
.sellerfjkrorpro {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  color: #ffffff;
  opacity: 0.6;
  padding-left: 12px;
}
.sellerudcfdvetthes {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
}
.sellerdetailmaindiv {
  display: flex;
  padding: 30px;
  width: calc(100vw - 10px);
  height: calc(100vh - 130px);
  overflow-x:auto;
  overflow-y:auto;
}

.placeSonyprof {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  padding-left: 10px;
}





.sellerConfirmOrderModal{
  background:#4453D6;
  border-radius: 30px;
  padding: 25px;
}
.sellerConfirmOrderModal .confirmmsg{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #FFFFFF;

  opacity: 0.8;
}
.sellerConfirmOrderModal .confirmTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #FFFFFF;
  margin-bottom: 15px;
  
}
.sellerConfirmOrderModal .buttonDiv{
 text-align: right;
}
.sellerConfirmOrderModal .buttons{
  min-width: 100px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #FFFFFF;
  border-radius: 30px;
  background: transparent;
  border: 1px solid #FFFFFF ;
  height: 40px;
  margin-top: 15px;


}
.sellerConfirmOrderModal .yesButton{
  background:#17171D !important;
  margin-left: 10px;
  border: none !important;
}

.sellerConfirmOrderModal  .textAreainput{
  width: 100%;
  height: 150px;
  resize: none;
  border-radius: 10px;
  background: #17171D;
  opacity: 0.4;
  padding:5px;
  color: #FFFFFF !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
}
.whiteBgDiv{
  height: 15px;
  background: #FFFFFF;
  width: calc(100% - 25px);
  max-width: 500px;
}

.sellerpurposeText{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
}

.sellerpurposeInput {
  margin-top: 5px;
  padding-left: 10px;
  border: none;
  width: 100%;
  height: 49px;
  background: #333333;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.5;
  border-radius: 30px;
}

.confirmSellerPin {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin: auto;
}

.activeYellowCollapse{
  background: #F5D028;
  color: #17171D;
}

.sellerOrderDetailContentBox{

 display: inline-flex;
 background: #FFFFFF;
  width: calc(100% - 25px);
  max-width: 500px;
}

.sellerOrderDetailContentBox > div:nth-of-type(1) {
  min-width: 190px;
}
.sellerOrderDetailContentBox > div:nth-of-type(2) {
  padding-left: 50px;
  max-width: 250px;
  word-break: break-all;
}

.selectInvoiceFileButton{
  background: #ffffff !important; 
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #17171d  !important; 
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.uploadInvoiceText{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  margin: 0px;
}




.confirmPlaceOrderModal{
  background:#FFFFFF;
  padding: 25px;
  min-width: 384px;
  border-radius: 10px;

}

.confirmPlaceOrderModal .previewInvoiceFilename{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* or 133% */

  display: flex;
  align-items: center;
  text-decoration-line: underline;

  color: #4453D6 !important;

}

.confirmPlaceOrderModal .headerTitle{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
}
.confirmPlaceOrderModal .preViewInvoiceFileBox{
  min-height: 315px;
  width: 315px;
  border-radius: 10px;
  margin-top: 15px;
}

.confirmPlaceOrderModal .invoiceFileTitle{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #333333;

  opacity: 0.8;
}

.confirmPlaceOrderModal .userInfoPreviewBox{
  width: 100%;
  align-content: center;
  display: inline-flex;
}
.userInfoPreviewBox .userprofileImageBox{
  width: 50px;
  position: relative;
}
.userInfoPreviewBox .userprofileImageBox::before{
  overflow: hidden;
  height: 40px;
  width: 40px;
  content: '';
  position: absolute;
  border-radius: 86px;
  border: 2px solid #4453D6;
}
.userInfoPreviewBox .userprofileImage{
  overflow: hidden;
  height: 40px;
  width: 40px;
  border-radius: 86px;
}


.userInfoPreviewBox .userProfileName{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  margin: 0px;
}

.borderBottom1DashedSeller{
  border-bottom: 1px dotted #A2A2A5;
  width: 100%;
}

.sellerselectUploadProofButton{
  height: 37px;
  width: 102px;
  border-radius: 30px;
  background: #17171D;
  font-family: 'Segoe' ;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;
}
.sellerfileRemoveIconStyle{
  background: transparent !important;
  border: none !important;
  outline: none !important;
}
.sellerSendInvoiceButton{
  background: #4453d6 !important;
  border-radius: 30px;
  width: 207px;
  height: 37px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
}

.sellerCancelBlackButton{
  background: #17171D !important;
  border-radius: 30px;
  width: 207px;
  height: 37px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
}

.confirmPlaceOrderModal .downloadInvoiceButton{
  background: #17171D !important;
  border-radius: 30px !important;
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF !important;
  width: 115px;
  border: none !important;
  position: relative;
  bottom: 28px;
  justify-content: center;
  align-items: center;
  display: inline-flex;

}


.confirmShipmentDropdown {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}


.confirmShipmentTrackNumberField {
  border: none !important;
  width: 276px;
  height: 49px;
  background: #333333 !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff !important;
  padding-left: 10px;
}
.sellerDisputeResolution{
  background: #17171D;
  opacity: 0.9;
  border-radius: 20px;
  width: 100%;
  min-height: 100px;
  padding: 15px;
  border: none !important;
  color: #FFFFFF !important;
  outline: none !important;
  resize: none;
}

.sellerinputItemFields{
  background: #333333 !important;
  border-radius: 30px;
  width: 100%;
  height: 42px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 10px;
}
.sellerSideinputLabels{
  padding-left: 15px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #FFFFFF;

  opacity: 0.8;

}

.selletOrderTrackbutton {
  background: #571B65  !important;
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
}


.sellerpayForInternalButton{
  background: #4453d6 !important;
  border-radius: 30px;
  width: 207px;
  height: 37px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
}
.sellerpayForExternalLinkButton{
  background: #333333!important;
  border-radius: 30px;
  width: 207px;
  height: 37px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
}

.RDEEsellerRefund {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin: 0px;
  padding-top: 20px;
}
.RDEEsellerRefund span {
  font-weight: 300;
}

.confirmShipmentTypeDropdown {
  border-radius: 30px;
  outline: none;
  height: 49px;
  width: 276px !important;
  padding: 15px !important;
  box-shadow: none !important;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  background: #333333;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  opacity: 1 !important;
}
.confirmShipmentTypeContainerDropdown {
  color: #17171d;
  background: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 49px;
  width: 276px !important;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.confirmShipmentTypeContainerDropdown .selectedDropdownItem {
  background: #4453D6;
}




@media only screen and (min-width: 400px) and (max-width: 1025px) {
  .sellerdetailmaindiv {
      display: flex;
      padding-top: 0px;
      width: calc(100vw - 10px);
  }

}
@media only screen and (min-width: 1026px) and (max-width: 1919px) {
  .sellerdetailmaindiv {
      display: flex;
      padding-top: 0px;
      width: calc(100vw - 500px);
  }

}

@media only  screen and (min-width: 1920px){
  .sellerdetailmaindiv{
  display: flex;
  padding: 30px;
  width: calc(100vw - 800px);
}
.sellerorderDEtailContainer{
  margin-left: 300px;
}
}
