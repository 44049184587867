.group-member-container {
    /* display: flex;
    flex-direction: column;
    width: 25vw;
    max-width: 500px; */
    display: flex;
    flex-direction: column;
    padding-left: 75px;
}

.group-member-container .group-member-header {
    /* text-align: center; */
    color: #FFFFFF;
    font-weight: bold;
    padding: 10px 0px;
    opacity: 0.8;

}

.group-member-container .group-member-body {
    display: flex;
    flex-direction: column;
    height: 50vh;
    min-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
}

.group-member-container .group-member-body .empty-member-list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.group-member-container .group-member-footer {
    display: flex;
    flex: 1;
    margin: 0 auto;
    padding: 5px 0px;
}