// Mixins
@mixin button {
  width: 100%;
  max-width: 327px;
  height: 49px;
  border-radius: 30px;
  font-size: 16px;
  color: $white;
  border: none;
}
// Variables
// Colors
$white: #fff;
$button-blue: #4453d6;

.div-flex-password-successful {
  font-family: Segoe;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .logo img {
    margin: 0 auto;
    max-width: 100%;
    margin-bottom: 32px;
    padding-bottom: 0;
  }
  .password-successful-container {
    width: 100%;
    height: 100%;
    max-width: 327px;
    max-height: 306px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .informationSubmitpClass p {
      margin: 0;
      font-size: 24px;
      color: $white;
    }
    .password-successful-text p {
      margin: 0;
      font-size: 16px;
      color: $white;
      text-align: center;
    }
    form {
      width: 100%;
      .password-successful-button {
        @include button();
        background-color: $button-blue;
      }
    }
  }
}
