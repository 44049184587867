
.paymentContainer {
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}

.mainContainerService {
  width: 100%;
  background: #ffffff;
  /* margin-left: 15px; */
}

.wellComeText {
  width: 708px;
  height: 39px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 42px;
  margin: 0px;
  color: #4658d4;
}

.serviceOnDemandText {
  font-weight: 300;
  font-size: 45px;
  color: #45443c;
  margin: 0px;
  margin-top: -15px !important;
}

.inlineFlex {
  display: inline-flex;
}

.serviceRequestCard {
  padding: 20px;
  width: 250px;
  height: 110px;
  left: 340px;
  top: 193px;
  background: #4ca9b7;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.serviceCard {
  padding: 20px;
  width: 200px;
  background: #4658d4;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.displyImgRequestCard {
  width: 58.38px;
  height: 59.3px;

  margin-right: 10px;
  background: #ffffff;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
}

.displyImg {
  width: 100%;
  height: 100%;
}

.generateTextCard {
  font-weight: bold;
  font-size: 12px;
  margin: 0px !important;
  color: #ffffff;
}

.serviceTextCard {
  font-weight: 300;
  font-size: 20px;
  color: #ffffff;
  line-height: 1;
}

.provideTextCard {
  margin: 0px !important;
  font-weight: bold;
  font-size: 12px;

  color: #ffffff;
}

.serviceTextCard2 {
  font-weight: 300;
  font-size: 20px;
  color: #ffffff;
  line-height: 1;
}

.paddTop10 {
  padding-top: 10px !important;
}

.searchText {
  font-weight: bold;
  font-size: 18px;
  margin: 0px !important;
  color: #4658d4;
}

.serviceProviderText {
  font-weight: 300;
  font-size: 33px;
  color: #45443c;
}

.searchItem {
  width: 600px !important;
  height: 49px !important;
  background: #c4c4c4 !important;
  opacity: 0.4 !important;
  border: 1px solid #c4c4c4 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}

.selectArea {
  width: 145px !important;
  height: 49px !important;
  background: #c4c4c4 !important;
  opacity: 0.4 !important;
  border: 1px solid #c4c4c4 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  margin-right: 5px;
}

.SelectOffered {
  width: 165px !important;
  height: 49px !important;

  background: #c4c4c4 !important;
  opacity: 0.4 !important;
  border: 1px solid #c4c4c4 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  margin-right: 5px;
}

.searchButton {
  width: 125px;
  height: 49px;
  background: #4658d4;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  margin-left: 8px;
}

.searchButton:hover {
  background: #4658d4;
  border: 1px solid #c4c4c4;
}

.mTop10 {
  margin-top: 10px;
}

.mTop30 {
  margin-top: 30px;
}

.myRequestText {
  font-weight: 300;
  font-size: 33px;
  margin: 0px !important;
}

.myRequestCard1View {
  width: 230px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-right: 20px;
  cursor: pointer;
  margin-top: 10px;
}

.activeBackground1 {
  background: #4658d4;
  color: #ffffff;
}

.SiemensText {
  margin: 0px !important;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
}

.RequestText {
  left: 19.9%;
  right: 74.17%;
  top: 77.1%;
  bottom: 21.3%;
  margin: 0px !important;

  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #c4c4c4;
}

.iconClass {
  height: 15px;
  width: 15px;
}

.card1Text {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.w100 {
  width: 100% !important;
}

.GeServicing {
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin: 0px !important;
}

.card2Text {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #a5a5a5;
}

.iconClassCard2 {
  height: 15px;
  width: 15px;
}

.FrameImg {
  width: 350px !important;
  /* margin-left: -50px; */
  /* height: 80% !important; */
  float: right;
  max-width: 400px;
  margin-left: -50px;
  /* width: 350px !important;
   */
  /* height: 80% !important; */
}

.buttonBar {
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-flex;
}

.buttonBar button {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #a5a5a5;
  background: transparent;
  border: none;
  min-width: 70px;
  height: 30px !important;
}

.activeButton {
  background: #4ca9b7 !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  border: none !important;
}

.buttonBar button:hover {
  background: #4ca9b7 !important;
  color: #ffffff !important;
  border-radius: 10px !important;
}

.buttonBar button:focus,
button:active {
  border: 0px !important;
  box-shadow: none !important;
}

.progress {
  height: 0.5rem !important;
  margin-top: 10px;
}

.notificationCSS {
  width: 480px !important;
}

.parentRow {
  padding: 30px;
  width: calc(100vw - 480px) !important;
  background: #ffffff !important;
  overflow-x: auto;
  height: calc(100vh - 20px);
}

.inlineFlex100 {
  width: 100% !important;
  display: inline-flex !important;
}

.serviceOnDemandSearchBoxes {
  flex-wrap: wrap;
  margin-top: 10px;
  display: inline-flex;
}

/* .btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  width: 145px !important;
  height: 40px !important;
  background: #C4C4C4 !important;
  opacity: 0.4 !important;
  border: 1px solid #C4C4C4 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  margin-right: 5px;
  color: #060e2170 !important;
  font-size: 10px !important;
} */

.btn-group,
.btn-group-vertical {
  width: 160px;
}

.btn-group-lg > .btn,
.btn-lg {
  width: 145px !important;
  height: 40px !important;
  background: #c4c4c4 !important;
  opacity: 0.4 !important;
  border: 1px solid #c4c4c4 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  margin-right: 5px;
  color: #060e2170 !important;
  font-size: 10px !important;
}

.dropdown-menu.show {
  width: 300px;
  height: 147px;
  left: 0px;
  top: 0px;

  background: #6975de;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.dropdown-header {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;

  color: #ffffff;
}
.dropdown-item {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid;
  border-bottom-style: dotted;
  padding: 10px;
  color: #ffffff;
}
.scrollable-element {
  scrollbar-color: red yellow !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background: #4453d6;
}
.serviceonlistdiv {
  width: 760px;
  height: 58px;
  background: #4453d6;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}
.erviceRequestd {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #c4c4c4;
}
.servisiemed {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
}
.servicetandi {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.serviceoawadd {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.serviceonrecid {
  width: 760px;
  height: 58px;
  background: #333333;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  justify-content: space-around;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.serviceondltex{
font-style: normal;
font-weight: normal;
font-size: 14px;
color: #C4C4C4;


}
.serviceonGESe{
font-style: normal;
font-weight: bold;
font-size: 18px;
color: #FFFFFF;


}

.p-multiselect .p-multiselect-label-container{
  overflow: hidden;
  background: #c4c4c4;
  height: 47px;
  padding-top: 6px;
  width: 225px;
  opacity: 0.5 !important;
  border-radius: 10px;
}
 .p-multiselect{
    margin-right: 10px !important;
    border-radius: 10px !important;
    border: 0px !important;
  
}
 .p-multiselect .p-multiselect-trigger{
  background: #c4c4c4;
    height: 47px;
    padding-top: 6px;
    opacity: 0.4 !important;
}
.p-multiselect .p-multiselect-panel, .p-multiselect .p-multiselect-header {
  background: #4453d6 !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  
  
}
.p-multiselect-item label{
  color: #FFFFFF;
}
.myserdasb{
  margin-left: 20px;
width: 296px;
height: 110px;
background: #4453D6;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
border-radius: 5px;
display: flex;
align-items: center;
cursor: pointer;
}
.mydashimagdi{
width: 58.38px;
height: 59.3px;
background: #FFFFFF;
border-radius: 8px;
margin-left: 17px;
}
.mydasservte{ 
font-style: normal;
font-weight: bold;
font-size: 18px;
color: #FFFFFF;
padding-left: 19px;
    margin: 0px;
}
.mydasdsast{
font-style: normal;
font-weight: 300;
font-size: 36px;
color: #FFFFFF;
padding-left: 17px;
 margin: 0px;
 line-height: 36px;
}
.innerSearchBoxContent{
  display: inline-flex;
  /* justify-content: center; */
  width: 100%;
}
.statusIconsSod{
  margin-left: 10px;
  margin-top: 3px;
}

.sodOverley{
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: #007A7C;
}
