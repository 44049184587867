.placeorderdetailsbackbutton {
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #666666;
  background: transparent;
  border: none;
  margin-top: 5px;
  margin-right: 31px;
}
.placeorderdetailsbackbutton:hover {
  background: transparent;
  color: #666666;
}
.placeorderdetailsbackbutton div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.placeorderdetailsbackbutton span {
  font-family: "Segoe";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  color: #666666;
}
.placeorderdetailsbackbutton img {
  width: 15px;
}
/* .placeorderbuttonx {
  background: #4453d6;
  border-radius: 30px;
  width: 206px;
  height: 49px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
} */

.placeorderDEtailContainer {
  display: inline-flex;
  background: #ffffff;
  height: calc(100vh - 85px);
  overflow: auto;
  width: calc(100vw - 300px);
}
.placedetailmaindiv {
  display: flex;
  padding: 30px;
  overflow-y: auto;
  align-items: baseline;
  width: calc(100vw - 10px);
  overflow-x: auto;
}
.placeSiemensAvapro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #17171d;
}
.placeSiemensAvapro p {
  font-size: 35px;
  margin: 0px;
}

.OrderPlaceDetail {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #333333;
}
.Orderconfcsirmedb {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  padding-left: 119px;
}
.OrderPlaced50PM {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  opacity: 0.7;
}
.placemessagebuton {
  background: #333333 !important;
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
}
.placepayfororderbuton {
  background: #4453d6 !important;
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
}
.placeOrderTrackbutton {
  background: #571b65 !important;
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
}
.placepayfororderbuton1 {
  background: #5a5a5a !important;
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
}
.placecamcelorderbuton {
  background: #571b65 !important;
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
}

.ordercancelleddiv {
  background: #5a5a5a;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  text-align: center;
  line-height: 36px;
  margin-top: 8px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.8;
}
.placemebutonsck2 {
  background: #333333;
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
.placoscsrderbuton2 {
  background: #4453d6;
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin-left: 10px;
}
.Paymentcompletedt {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  color: #333333;
  margin: 0px;
}
.placeorderdropownpro {
  width: 523px;
  height: 37px;
  background: #333333;
  border-radius: 0px 30px 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  cursor: pointer;
  color: #ffffff;
}
.labelTitleText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #767575;
  padding-left: 15px;
  padding-top: 12px;
}
.labelValueText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  padding-top: 12px;
  padding-left: 10px;
}

.placeTSeapro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #f5d028;
  padding-left: 250px;
}
.placedoadrepipro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #f5d028;
  padding-left: 215px;
}
.placedoadrepipro a {
  color: #f5d028 !important;
}
.placerigCircleImage {
  display: flex;
  margin-left: 16px;
  flex-wrap: wrap;
}
.placerigCircleImage img {
  margin: 10px;
}
.placeselinfobopro {
  background: #333333;
  border-radius: 10px;
  width: 500px;
  height: 523px;
  margin-top: 26px;
}
.placesnfoheadiung {
  width: 229px;
  height: 44px;
  background: #4453d6;
  border-radius: 0px 30px 30px 0px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 42px;
  color: #ffffff;
  text-align: center;
}
.placevbuttonprdcdco {
  background: #4453d6 !important;
  border-radius: 30px;
  width: 164.77px;
  height: 37px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
  border: none;
  margin-left: 73px;
}
.plaeJuccdcrthapro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  color: #ffffff;
  margin: 0px;
  padding-left: 15px;
}
.placehjvfonpro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.7;
  padding-left: 15px;
}
.placeRevprdcdco {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  opacity: 0.8;
  padding-left: 27px;
}
.placexrescdfpropro {
  width: 399px;
  height: 145px;
  background: #17171d;
  border-radius: 10px;
  margin-top: 10px;
  margin: auto;
  padding: 20px;
  overflow-x: auto;
}
.placeFcdcdaulpro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
  padding-left: 12px;
}
.placefjkrorpro {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  color: #ffffff;
  opacity: 0.6;
  padding-left: 12px;
}
.placeudcfdvetthes {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
}
.placetcsdcdpro {
  background: #f5d028;
  border-radius: 30px;
  width: 78px;
  height: 25px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #17171d;
  opacity: 0.8;
  margin-left: 123px;
  line-height: 25px;
}
.placecloseimgstyle {
  transform: rotate(180deg);
  margin-right: 15px;
}

.payforordemodal {
  width: 327px;
  min-height: 382px;
  background: #17171d;
  border-radius: 30px;
}
.placwpayfororder {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  padding-left: 10px;
  width: 186px;
}
.detaildaororder {
  width: 295px;
  height: 91px;
  background: #4453d6;
  opacity: 0.6;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  margin-top: 25px;
}
.walletbalancepayforo {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin: 0px;
  padding-top: 20px;
}
.balcncewalltpahyfor {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin: 0px;
}
.balcncewalltpahyfor span {
  font-size: 20px;
  font-weight: bold;
}
.RDEEcreditsvfpay {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin: 0px;
  padding-top: 20px;
}
.RDEEcreditsvfpay span {
  font-weight: 300;
}
.Siemenspayfororder {
  /* margin: 0px; */
}
.OrderidpayTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
  padding-left: 20px;
}

.Orderidpayfor {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #ffffff;
  opacity: 0.8;
  margin: 0px;
  padding-left: 20px;
}
.USpatyjfdpayfor {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: right;
  color: #ffffff;
  margin: 0px;
  padding-left: 100px;
}
.paymentcofirmbuttonpay {
  background: #4453d6 !important;
  border-radius: 30px;
  width: 274px;
  height: 40px;
  margin-top: 20px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}
.pinmodalpayplce {
  width: 327px;
  min-height: 239px;
  background: #17171d;
  border-radius: 30px;
}
.payforpinEnteryourPIN {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  padding-top: 25px;
  padding-top: 25px;
  width: 200px;
  margin: auto;
}
.payforwrongpinmessa {
  background: #d80031;
  border-radius: 10px;
  width: 295px;
  /* height: 90.96px; */
  font-family: Helvetica;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  padding: 5px;
  margin-top: 30px;
  overflow: hidden;
}
.orderpaidodalplace {
  width: 327px;
  min-height: 413px;
  background: #17171d;
  border-radius: 30px;
}
.Congratulationspayplace {
  margin-top: 29px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}
.Congratulationspayplace p {
  margin: 0px;
}

.returntoorderplace {
  width: 274px;
  height: 40px;
  border-radius: 30px;
  margin-top: 20px;
  border: none;
  background: #4453d6;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}
.ViewAllOplace {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #c4c4c4;
  border: none;
  background: transparent;
  margin-top: 5px;
}

.PlaceOrderConfirmOrderModal {
  background: #4453d6;
  border-radius: 30px;
  padding: 25px;
}
.PlaceOrderConfirmOrderModal .confirmmsg {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #ffffff;

  opacity: 0.8;
}
.PlaceOrderConfirmOrderModal .confirmTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #ffffff;
  margin-bottom: 15px;
}
.PlaceOrderConfirmOrderModal .buttons {
  min-width: 100px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
  border-radius: 30px;
  background: transparent;
  border: 1px solid #ffffff;
  height: 40px;
  margin-top: 15px;
}
.PlaceOrderConfirmOrderModal .yesButton {
  background: #17171d !important;
  margin-left: 10px;
  border: none !important;
}
.PlaceOrderConfirmOrderModal .textAreainput {
  width: 100%;
  height: 150px;
  resize: none;
  border-radius: 10px;
  background: #17171d;
  opacity: 0.4;
  padding: 5px;
  color: #ffffff !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
}

.detailPlacingorder {
  width: 250px;
  height: 91px;
  background: #171717;
  opacity: 0.6;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  margin-top: 25px;
  border-radius: 10px;
}

.purposeText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
}

.purposeInput {
  margin-top: 5px;
  padding-left: 10px;
  border: none;
  width: 100%;
  height: 49px;
  background: #333333;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.5;
  border-radius: 30px;
}
.placeOrderdetailActiveBG {
  background: #4453d6 !important;
  /* color: #17171D; */
}
.placeOrderContentBox {
  display: inline-flex;
}
.placeOrderContentBox > div:nth-of-type(1) {
  min-width: 190px;
}
.placeOrderContentBox > div:nth-of-type(2) {
  padding-left: 50px;
  max-width: 250px;
  word-break: break-all;
}

.borderBottom1Dashed {
  border-bottom: 1px dotted #a2a2a5;
  width: 100%;
}
.paymentPreviewModal {
  background: #ffffff;
  padding: 25px;
  min-width: 384px;
  border-radius: 10px;
}

.paymentPreviewModal .previewInvoiceFilename {
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* or 133% */

  display: flex;
  align-items: center;
  text-decoration-line: underline;

  color: #4453d6 !important;
}

.paymentPreviewModal .headerTitle {
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
}
.paymentPreviewModal .preViewInvoiceFileBox {
  min-height: 315px;
  width: 315px;
  border-radius: 10px;
  margin-top: 15px;
}

.paymentPreviewModal .invoiceFileTitle {
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
  word-break: break-all;
  opacity: 0.8;
}

.paymentPreviewModal .userInfoPreviewBox {
  width: 100%;
  align-content: center;
  display: inline-flex;
}
.userInfoPreviewBox .userprofileImageBox {
  width: 50px;
  position: relative;
}
.userInfoPreviewBox .userprofileImageBox::before {
  overflow: hidden;
  height: 40px;
  width: 40px;
  content: "";
  position: absolute;
  border-radius: 86px;
  border: 2px solid #4453d6;
}
.userInfoPreviewBox .userprofileImage {
  overflow: hidden;
  height: 40px;
  width: 40px;
  border-radius: 86px;
  /* border: 2px solid #4453D6; */
}

.userInfoPreviewBox .userProfileName {
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  margin: 0px;
}
.paymentPreviewModal .downloadButton {
  background: #17171d !important;
  border-radius: 30px !important;
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #ffffff !important;
  width: 115px;
  border: none !important;
  position: relative;
  bottom: 28px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}
.paymentPreviewModal .payForOrderBlueButton {
  background: #4453d6 !important;
  border-radius: 30px;
  width: 207px;
  height: 37px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
}
.paymentPreviewModal .payForExternalLinkButton {
  background: #333333 !important;
  border-radius: 30px;
  width: 207px;
  height: 37px;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
}
.selectUploadProofButton {
  height: 37px;
  width: 102px;
  border-radius: 30px;
  background: #17171d;
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
}
.fileRemoveIconStyle {
  background: transparent !important;
  border: none !important;
  outline: none !important;
}

.buyerDisputeReason {
  background: #17171d;
  opacity: 0.9;
  border-radius: 20px;
  width: 100%;
  min-height: 100px;
  padding: 15px;
  border: none !important;
  color: #ffffff !important;
  outline: none !important;
  resize: none;
}

.confirmReturnShipmentDropdown {
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.confirmReturnShipmentTrackNumberField {
  border: none !important;
  width: 100%;
  height: 49px;
  background: #333333 !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff !important;
  padding-left: 10px;
}

.buyerconfirmShipmentTypeDropdown {
  border-radius: 30px;
  outline: none;
  height: 49px;
  width: 334px !important;
  padding: 15px !important;
  box-shadow: none !important;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  background: #333333;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  opacity: 1 !important;
}
.buyerconfirmShipmentTypeContainerDropdown {
  color: #17171d;
  background: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 49px;
  width: 334px !important;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.buyerconfirmShipmentTypeContainerDropdown .selectedDropdownItem {
  background: #4453d6;
}

@media only screen and (max-width: 1024px) {
  .placeorderDEtailContainer {
    width: calc(100vw - 20px);
  }
}
@media only screen and (min-width: 1920px) {
  .placedetailmaindiv {
    display: flex;
    padding: 30px;
    width: calc(100vw - 800px);
    overflow-x: auto;
    margin-left: 300px;
  }
}
