.paymentContainer {
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  /* background: linear-gradient(162.73deg, #4453D6 14.48%, #8B2FA0 79.76%) !important; */
}

.lockbutton {
  border-width: 1px;
  border-radius: 5px;
  border: 1px solid #ffffff;
  margin: 20px auto;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  height: 54px;
}
.div-flex {
  height: 100vh; /* Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica;
}
.inputClass1 {
  width: 30px;
  margin: auto;
  /* background: #0C0F1D; */
  border-radius: 10px;
  border: none;
  color: #ffffff;
  height: 40px;
}
.LClass {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  /* text-align: center; */
  color: #ffffff;
  text-align: center;
}
.color {
  color: #ffffff;
  font-size: 16px;
}
.head {
  color: #ffffff;
  /* font-size: 24px; */
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  text-align: center;
  margin: 0px !important;
}
.head2 {
  color: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  text-align: center;
  margin: 0px !important;
  line-height: 30px;
}
.sechead {
  font-size: 20px;
  color: #ffffff;
  margin-top: 20px;
}
.no_margin {
  margin: 0px;
}
.head3 {
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  margin-top: 20px;
}

.welcomeBoxTitletext {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  text-align: center;
  color: #ffffff;
}

.welcomeBoxTitletext_bottom {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;

  color: #ffffff;
}
.letGOButtonClass {
  border: 0px !important;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 321.84px;
  height: 53.56px;
  border-radius: 30px;
  background-color: #17171D !important;
}

.fontWeight700{
  font-weight: 700 !important;
}
.mTo30{
  margin-top: 30px;
}
.lastDiv{
    width: 289px;
    height: 80px;
    background: #4453D6;
    border-radius: 30px 0px 0px 0px;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 10px;
}
.lastText{
  font-family: Segoe;
  font-style: normal;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}
.rdeePayTextDiv{
  width: 260px !important;
}
.walletContentBox{
  width: 500px;
  text-align: center;
  margin: auto
}
.pinCenter{
  text-align:center;
  /* position: absolute;
  top: 40%;
  right: 0;
  left: 0; */
}

.walletBoxCenter{
  height: calc(100vh - 200px);
  overflow: auto;
  display: flex;
  justify-content: center; 
}
/* .paymentBoxContainer{} */

.paymentBoxContainer{
  width: calc(100vw - 500px);
  overflow: hidden;
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}
.settlePinContainer{
  width: calc(100vw - 20px);
}

@media only screen and (min-width: 400px) and (max-width: 1025px) {
  .paymentBoxContainer{
    width: calc(100vw - 20px);
  }
}

