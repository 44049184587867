.contentAppContiner{
  width: 100vw;
  height: calc(100vh - 100px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 50px;
  padding-right: 20px;
  padding-top: 20px;

}
.contentAppContiner .heading{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 65px;
  margin-top: 10px;
  color: #FFFFFF;
}
.contentAppContiner .catheadingTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  line-height: 27px;
  margin-top: 10px;
  color: #FFFFFF;
  font-size: 20px;
  border-bottom: 1px solid #24A2A8;
}
.appsbackbutton {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #FFFFFF;
  background: transparent;
  border: none;
  margin-top: 0px;
}
.appsbackbutton:hover {
  background: transparent;
  color: #FFFFFF;
}
.appsbackbutton div {
  width: 25px;
  height: 25px;
  background: #FFFFFF;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.appsbackbutton span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  color: #FFFFFF;
}
.appsbackbutton img {
  width: 15px;
}
.streamplayerCss{
  margin-top: 20px;
  width: 234px !important;
  height: 132px !important;
  background: #aaaaaa;
  margin-right: 20px;
}
.streamPlayerFlexWrap{
  flex-wrap: wrap;
  display: flex;
}
@media only screen and (min-width: 1920px)   {

  .contentAppContiner{
  
    margin-left: 300px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 800px)   {
  .streamgoBackButtonBox{
   width: calc(100vw - 500px) !important;
  }
}
@media only screen and (min-width: 300px) and (max-width: 799px)   {
  .streamgoBackButtonBox{
    width: 100%;
  }
}

