.ICEPRO {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #c4c4c4;
}
.qvfGE {
  /* GE Servicing Session */
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  color: #45443c;
}
.xsersc {
  /* Description: */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  color: #6c6c6c;
  padding-top: 17px;
}
.sclooking {
  /* Hello Dr. John, I am looking forward to a GE Servicing Session with you as I am really in need of an opinion from a professional person to guide me urgently. */
  padding-top: 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  /* or 133% */
  color: #17171d;
}
.seStatus {
  /* Status: */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  padding-top: 15px;
  color: #6c6c6c;
}
.sewAwarded {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #6c6c6c;
}
.sewAwarded span {
  padding-left: 5px;
}
.serload {
  /* Rectangle 22 */
  width: 243px;
  height: 7px;

  background: #c4c4c4;
  border-radius: 10px;
}
.serwait {
  /* Rectangle */

  background: #8c8c8c;
  border-radius: 10px;
  /* WAITING FOR PAYMENT */
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  align-items: center;
  text-align: center;

  color: #ffffff;
  margin-top: 19px;
  height: 50px;
  padding-top: 14px;
}
.sertancx {
  /* Rectangle 21 */
  width: 256px;
  height: 127px;
  margin-top: 17px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 15px;
}
.sertan {
  /* Tanya Lorenda */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #45443c;
}
.serAngeles {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #6c6c6c;
}
.serbutto {
  width: 138px;
  background: #4453d6;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.serJOB {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  padding-top: 50px;
  color: #4453d6;
}

.sermanufaxf {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #8c8c8c;
  padding-top: 13px;
  width: 180px;
}
.serGESer {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #17171d;
  padding-top: 13px;
  width: 180px;
  padding-left: 20px;
}
.seerdow {
  background: #8c8c8c;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  color: #ffffff;
  margin-left: 20px;
}
.serSTART {
  background: #4658d4;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  align-items: center;
  text-align: center;

  color: #ffffff;

  height: 50px;
  padding-top: 14px;
  margin-top: 8px;
}
.serREJECT {
  border: none;
  background: #8c8c8c;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  align-items: center;
  text-align: center;
  width: 120px;
  color: #ffffff;
}
.serACCEPT {
  border: none;
  background: #4658d4;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  align-items: center;
  text-align: center;
  width: 120px;
  color: #ffffff;
}
.serLEAVE {
  background: #4658d4;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  height: 50px;
  padding-top: 14px;
  margin-top: 8px;
}
.srrejecmodal {
  width: 422px;
  height: 252px;
  background: #333333;
  border-radius: 10px;
}
.serRejectService {
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  color: #eeeeee;
  padding-top: 39px;
}
.serRequestor {
  /* Do you wish to reject this service request from [Service Requestor Name]? */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  /* or 141% */
  padding-top: 12px;
  color: #ffffff;
}
.sernobuttom {
  /* Rectangle 32 */
  width: 130.66px;
  height: 49px;
  border: none;
  background: #a5a5a5;
  border-radius: 10px;
  /* NO */
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  margin-right: 21px;
  color: #ffffff;
}
.seryesbutton {
  /* Rectangle 31 */
  width: 117px;
  height: 49px;
  border: none;
  background: #4ca9b7;
  border-radius: 10px;
  /* YES */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  margin-right: 31px;
  color: #ffffff;
}
.serrejectedserv {
  /* Rectangle 660 */
  width: 422px;
  height: 252px;

  background: #333333;
  border-radius: 10px;
}
.dersRejected {
  /* Service Request Rejected */
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  text-align: center;
  padding-top: 39px;
  color: #eeeeee;
}
.serrejectedthis {
  /* You have rejected this service request from [Service Requestor Name]. */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  /* or 141% */
  padding-top: 12px;
  padding-left: 60px;
  color: #ffffff;
}
.sermodalbutton {
  /* Rectangle 31 */
  width: 284px;
  height: 49px;

  background: #4ca9b7;
  border-radius: 10px;
  /* RETURN TO MY REQUESTS */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  border: none;
  color: #ffffff;
  margin-top: 30px;
}

.gobackButtons {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  color: #c4c4c4;
  display: inline-flex;
  cursor: pointer;
  background: none;
  border: 0px;
  float: right;
}
.gobackButtons:hover {
  background: none !important;
  color: #c4c4c4;
  border-radius: 10px !important;
}
.gobackButtons:focus,
gobackButtons:active {
  border: 0px !important;
  box-shadow: none !important;
}
.gobackButtons > div {
  background: #c4c4c4;
  border-radius: 5px;
  text-align: center;
  width: 25px;
  height: 26px;
}
.gobackButtons img {
  width: 14px;
}
.gobackButtons span {
  padding-left: 5px;
  padding-top: 2px;
}

.receivedRequestMaineContainer {
  margin: 10px;
  width: calc(100vw - 490px);
  overflow: hidden;
  overflow-x: scroll;
  padding: 20px;
}
.receivedRequestrightPannel {
  padding: 5px;
}

.requesterProfile{
    width: 65px;
    border-radius: 100px;
    height: 65px;
}
