.user-card {
  
  /* padding: 10px 20px; */
  width: 100%;
}
.user-card .inneruserContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* .user-card img {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
} */

.user-card .user-profile-name {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  padding: 7px 0px;
}

.user-card .user-profile-status {
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.7;
}
.getGreetingTimeCss {
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 38px;

  color: #17171d;
  margin: 0px;
}
.userName {
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 40px;
  min-width: 300px;
  margin: 0px;
  color: #333333;
  text-transform: capitalize;
  /* text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden; */
}
.todayStatus {
  font-family: "Segoe";
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  align-items: center;
  justify-self: flex-end;
  margin-right: 5px;
  padding-right: 5px;
  color: #333333;
  opacity: 0.7;
  padding-right: 15px;
}

.messengerCircle {
  width: 84px;
  height: 84px;
  background: #4453d6;
  border-radius: 100px;
  text-align: center;
  padding-top: 24px;
  margin-bottom: 4px;

}
.messengerCircle  img{
    width: 40px;
    vertical-align: initial;
}
.RDEEpayCircle {
  width: 84px;
  height: 84px;
  background: #17171d;
  border-radius: 100px;
  text-align: center;
  padding-top: 24px;
  margin-bottom: 4px;


}
.RDEEpayCircle  img{
    width: 42px;
    vertical-align: initial;
}
.marketPlaceCircle {
  width: 84px;
  height: 84px;
  background: #17171d;
  border-radius: 100px;
  text-align: center;
  padding-top: 24px;
  margin-bottom: 4px;

}
.marketPlaceCircle  img{
    width: 42px;
    vertical-align: initial;
    margin-right: 4px;
}
.subtitle {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  /* line-height: 18px; */
  text-align: center;
  color: #333333;
}
.IconsCircleBox{
    padding: 5px;
    width: 90px;
    cursor: pointer;
    display: grid;
    justify-content: center;
    height: 110px;
}
.profileImageBox{
  position: relative;
}
.profileImageBox::before{
  content: '';
  position: absolute;
  width: 96px;
  height: 96px;
  border-radius: 100px;
  border: 4px solid #4453D6;
}
.userImage{
  width: 96px;
  height: 96px;
  border-radius: 100px;
  /* border: 4px solid #4453D6; */
}
.displayInlineFlex{
  display: inline-flex;
}
.userStatusInput{
  padding-left: 10px;
  resize: none;
  min-width: 180px;
  border: none !important;
  margin-top: 10px;
  min-height: 30px;
  border-radius: 30px !important;
  background: #17171D !important;
  color: #ffffff;
  box-shadow: none !important;
  outline: none !important;
  /* margin-left: 10px; */
}
@media only screen and (min-width:1200px) {

.whatsToday{
  /* width: calc(100vw - 600px); */
  justify-content: flex-end;
  display: flex !important;


}
}
@media only screen and (min-width: 300px) and (max-width: 725px) {
  .userName{
    font-size: 28px;
  }
  .getGreetingTimeCss{
    font-size: 20px;
  }
}

