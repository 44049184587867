/* .popoverBG{
    background: #17171D;
    opacity: 0.5;
    width: 490px;
    padding-top: 50px;
} */


.popoverBG {
    z-index: 2;
    width: 490px;
    position: relative;
    color: #FFFFFF;
    padding-top: 80px;
    padding-bottom: 15px;
    border-radius: 0px 0px 30px 30px;
}
.popoverBG::before {
    content: "";
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    background: #17171D;
    border-radius: 0px 0px 30px 30px;
    opacity: 0.9;
    z-index: -1;
}

.shareLabelBox{
    background: linear-gradient(270deg, #4453D6 0%, #571B65 100%);
    border-radius: 0px; 
    width: 100%;
    height: 54px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #FFFFFF;
    display: inline-flex;
    padding-left: 35px;
    padding-top: 10px;
}
.shareLabelBox .msgicon{
    height: 37px !important;
    width: 37px !important;
    vertical-align: baseline;
    vertical-align: initial;
    margin-right: 10px;
}
.popoverContainer{
    padding-left: 35px;
    padding-top: 15px;
}
.searchMemberTitle{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 133% */

    align-items: center;

    color: #FFFFFF;
}

.searchUsersInput { 
    background: #17171D;
    opacity: 0.7;
    border-radius: 30px;
    width: 404px;
    height: 42px;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .userSearchListingContainer{
    max-height: 300px;
    overflow-x: auto;
  }

  
/* width */
.userSearchListingContainer::-webkit-scrollbar {
    width: 8px;
    border-radius: 5px;
  }
  
  /* Track */
  .userSearchListingContainer::-webkit-scrollbar-track {
    background: #FFFFFF !important; 
    border-radius: 5px;

  }
   
  /* Handle */
  .userSearchListingContainer::-webkit-scrollbar-thumb {
    background: #000000 !important;
    border-radius: 5px;

  
  }
  .userContactSearchList{
      display: inline-flex;
      width: 100%;
      margin-top: 15px;
      cursor: pointer;
  }

  .userImageDiv{
      width: 55px;
      text-align: left;
  }
  .userImageDiv .userimages{
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 86px;
    width: 40px;
    height: 40px;
  }

  .userInfoDiv{

  }

  .msgSearchUsername{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .msgSearchUserlastseen{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    
    color: #C4C4C4;
    
  }