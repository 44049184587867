.select-box--container {
  width: 100%;
  margin: 0;
  padding: 0;
  max-height: 60px;
  position: relative;
}

* {
  box-sizing: border-box;
}
.dropdownBoxBorderBottomHide {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.select-box--box {
  cursor: pointer;
  display: initial;
}
.dropdownBg {
  /* background: #17171d; */
  opacity: 0.7;
}
.openDropdownActiveBg{
  background: #4453D6 !important;
  opacity: 1 !important;
}
.select-box--selected-item {
  color: #FFFFFF ;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* display: inline-block;
  height: 100%;
  width: 100%;
  padding: 0px;
  vertical-align: middle; */
}

.select-box--items {
  /* background: #FFFFFF; */
  max-height: 175px !important;
  min-height: 175px !important;
  overflow: auto !important;
  /* border: 1px solid var(--form-inputborder-color); */
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  padding: 0px !important;
  position: absolute;
  z-index: 10;
}
.select-box--items div {
  /* border-bottom: 1px solid var(--form-inputborder-color); */
  padding: 10px;
  padding-left: 20px;
}
.selectdropdownArrow {
  transform: rotateZ(180deg);
}
.dropdownArrowImg {
  width: 20px;
}
.selectedDropdownItem{
    background: #17171D;
    color: #FFFFFF;

}

/* dropdown list scroller start*/



/* Let's get this party started */
.select-box--items::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.select-box--items::-webkit-scrollbar-track {
  -webkit-border-radius: 10px ;
  border-radius: 10px ;
  background: #4453D650;
}

/* Handle */
.select-box--items::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px ;
  border-radius: 10px;
  background:#4453D6;
}
/* dropdown list scroller end */


@media only screen and (min-width: 300px) and (max-width: 767px) {
  .dropdownArrowImg {
    width: 14px;
  }

}
