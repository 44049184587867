.authsettindiv {
  min-width: 350px;
  width: 454px;
  min-height: 242px;
  background: #333333;
  border-radius: 10px;
  margin-left: 25px;
  margin-top: 15px;
}
.aauthmhding {
  background: #ffffff;
  border-radius: 0px 30px 30px 0px;
  width: 266px;
  height: 44px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 41px;
  text-align: center;
  color: #333333;
}
.autschsetimg {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.6;
  padding-left: 10px;
}
.changepassdjk {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin: 0px;
  padding-top: 23px;
  padding-left: 10px;
}
.setingchangepassdiv {
  width: 414px;
  /* height: 489px; */
  background: #333333;
  border-radius: 10px;
  margin-top: 15px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.aautchangehmhding {
  background: #ffffff;
  border-radius: 0px 30px 30px 0px;
  width: 212px;
  height: 44px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 41px;
  text-align: center;
  color: #333333;
}
.entersetinghjk {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  padding-top: 25px;
}
.changepassAuthSetting {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  padding-left: 25px;
  margin: 0px;
}

.paddwordjkcbinput {
  width: 327px;
  height: 60px;
  background: #17171d;
  border-radius: 30px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin: 0 auto;
}
.settsavenesbutton {
  width: 327px;
  height: 54px;
  background: #4453d6 !important;
  border: none;
  border-radius: 30px;
  margin-top: 25px;
}
.passchangedmaindiv {
  width: 414px;
  height: 335px;
  background: #333333;
  border-radius: 10px;
  margin-left: 25px;
  margin-top: 50px;
}
.newpassavefkj {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  padding-top: 30px;
}
.Younewpasswor {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  padding-top: 14px;
}
.passlexxauthmainduv {
  min-width: 414px;

  width: 414px;
  height: 242px;
  background: #333333;
  border-radius: 10px;
  margin-top: 15px;
  margin-left: 58px;
}
.apasslesshgehmhding {
  background: #ffffff;
  border-radius: 0px 30px 30px 0px;
  width: 317px;
  height: 44px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 41px;
  text-align: center;
  color: #333333;
}
.TrusonaAut {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  /* margin: 20px; */
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 40px;
  align-items: center;
  display: flex;
  justify-content: space-between;

}

.sascdvecgbutton {
  width: 327px;
  height: 54px;
  background: #4453d6 !important;
  border: none;
  border-radius: 30px;
  color: #FFFFFF !important;
  margin-top: 20px;
}

.passwordLessOFFON {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  text-align: right;
  margin-right: 40px;
  margin-bottom: 10px;
}
.passwordLessOFFON span {
  color: #4aea87;
}

.trusonaSwitch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 12px;
  margin-top: 10px;
}

.trusonaSwitch input { 
  opacity: 0;
  width: 0;
  height: 0;
}


.trusonalider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;     
  -webkit-transition: .4s;
  transition: .4s;
  
}

.trusonalider:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  left: 0px;
  bottom: -4px;
  background-color: #4AEA87;
  -webkit-transition: .4s;
  transition: .4s;
}


input:checked + .trusonalider {
  background-color: #4AEA87;
}

input:focus + .trusonalider {
  box-shadow: 0 0 1px #4AEA87;
}

input:checked + .trusonalider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.trusonalider.round {
  border-radius: 34px;
}

.trusonalider.round:before {
  border-radius: 50%;
}
