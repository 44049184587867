.sideMenuItem{
  padding:5px;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
}
.sideMenuItem span{
  font-weight: bold;
}
.activeMenuBar{
  background: #FFF;
  color: #000;
  border-radius: 5px;
}
