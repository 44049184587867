
.historyContainer {
  font-style: normal;
  margin-left: 40px;
  /* width: 100%; */
}
.floatR{
  float: right;
}
.textRight{
 text-align: right;
}
.mLeft10{
  margin-left: 10px;
}
.displayFlex{
  display: flex;
  align-items: center;
}
.transactionHistoryMainDiv{
  /* position: absolute; */
  /* width: 970px; */
  /* left: 31px; */
  /* top: 158px; */
  background: #FFFFFF;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
  /* overflow-x: auto; */
}
.headerDiv{
  width: 100%;
  border-radius: 10px;
}
.transactionHistoryTextDiv{
  background: #4453D6;
  height: 45px;
  width: 190px;
  border-radius: 0px 30px 30px 0px;
  margin-top:20px;
  padding: 10px;
}
.transactionHistoryText{
  font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #FFFFFF;
    
}
.transactionFiltersDiv{
  margin-top: 10px;
  display: flex;
}
.transactionsearchDiv{
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 0px 10px;
}
.transactionDotBoldDiv{
  border:2px gray;
  border-bottom-style: dotted;
  margin: 15px;
}
.transactionDotDiv{
  border:1px gray;
  border-bottom-style: dotted;
  margin: 15px;
}
.w50{
  width: 50%;
}
.clearSearch{
    padding: 4px;
    width: 140px;
    background: #2da0a7;
    border-radius: 25px;
    height: 36px;
    color: white;
}

.historyFilters {
  width: 145px !important;
  height: 36px;
  background: black !important;
  opacity: 0.9;
  border-radius: 25px !important;
  font-style: normal;
  font-weight: normal;
  font-family: "Segoe";
  font-size: 14px;
  color: #ffffff !important;
  border: none !important;
  margin: 10px;
  
}

.historyFilters .p-dropdown-trigger {
  background: transparent !important;
  color: #ffffff !important;
  margin-right: 8px !important;
  border-radius: 100% !important;
  height: 40px;
}

.historyFilters label {
  background: transparent !important;
  color: #ffffff !important;
  padding-top: 5px !important;
  font-family: "Segoe";
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  border-radius: 25px !important;
  /* opacity: 0.5; */
  text-align: center;

}

.historyFilters .p-dropdown-panel .p-dropdown-items {
  background: #17171d !important;
  opacity: 0.9 !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.historyFilters .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  /* background: #17171D  !important; */
  /* opacity: 0.9 !important; */
  color: #ffffff !important;
  /* background-color: #2da0a7 !important; */
}
.historyFilters .p-dropdown-item .p-highlight{
   background-color: #24A2A8 !important;
}
.p-dropdown .p-dropdown-panel {
  border-radius: 32px !important;
}
.historyFilters .p-dropdown-panel {
  border: none !important;
  margin-top: -14px !important;
  /* padding-top: 10px; */
}

.historyFilters .p-dropdown {
  width: 80px !important;
 
}
.historySearch{
  position: relative;
  padding-top: 10px;
}


.historySearch .searchInput {
  width: 300px;
  height: 36px;
  border-radius: 50px;
  font-family: "Segoe";
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  background: black !important;
  padding-left: 50px;
  color: #ffffff;
}
.historySearch .searchInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #FFFFFF;
  opacity: 1; /* Firefox */
}

.historySearch .searchInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #FFFFFF;
}

.historySearch .searchInput::-ms-input-placeholder { /* Microsoft Edge */
  color: #FFFFFF;
}
.historySearch .searchContactIcon {
  width: 26px;
  position: absolute;
  top: 16px;
  left: 13px;
}
.contactsearchBoxInput {
  position: relative;
  margin-left: auto;
}

.transactionHistoryDiv{
  /* background: green; */
  height: 75px;
  border-bottom: 1px solid black;
  border-bottom-style: dotted;
  display: flex;
  margin: 25px;
}
.textCenter{
  text-align: center;
}
.historyResultDiv{
  padding: 25px;
  /* overflow: hidden; */
  /* overflow-y: auto;
  height: calc(100vh - 200px); */
}
.noTransactionYetText{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}
.nameTransactionHistoryDiv{
  min-width: 250px !important;
}
.nameTransactionHistory{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin: 5px;
    color: #17171D;
}
.postTransactionHistoryDiv{
  width: 230px !important;
}
.postTransactionHistory{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin: 5px;
  color: #17171D;
}
.dateTransactionHistoryDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px !important;
  padding-top: 8px;
  padding-bottom: 8px;
}
.dateTransactionHistory{
  font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 19px;
text-align: center;

color: #333333;

opacity: 0.7;
}

.amountTransactionHistory{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #333333;

}
.detailsTransactionHistory{
  width: 121px !important;
}

.detailsView{
  padding: 7px;
  width: 121px;
  border-radius: 25px;
  height: 36px;
  color: #FFFFFF;
  background-color: #4453d6;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 19px;
}
.historyHistorymaindiv{
  width: calc(100vw - 500px);
  /* height: calc(100vh - 130px); */
  margin-right: 60px;
  /* overflow: hidden; */
  border-radius: 10px;
  margin-top: 25px;
  height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y:auto; 
}

.historyHistorymaindiv::-webkit-scrollbar-track {
  background: #17171D !important;
}
.historyHistorymaindiv::-webkit-scrollbar-thumb {
  background: #FFFFFF !important;
}
.historyHistorymaindiv::-webkit-scrollbar-thumb:hover {
  background: #FFFFFF !important;
  /* background: #000000 !important; */
}

.historyHistorymaindiv::-webkit-scrollbar {
  width: 8px;
  /* background: #000000 !important; */
}


.stepGoBackDiv{
  width: calc(100vw - 500px);
}
.pendingreqdiv{
width: 970px;
height: 277px;
background:#FFFFFF;
margin-left: 40px;
border-radius: 10px;
padding-top: 15px;
}
.pendingcloseicon{
  float: right;
    width: 20px;
    height: 20px;
    margin-top: 10px;
    margin-right: 10px;
}
.pendingreqheading{
  width: 211px;
   height: 44px;
   
  background: #007A7C;
border-radius: 0px 30px 30px 0px;


font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 43px;
text-align: center;
color: #FFFFFF;
}

.payrequestdiv{
width: 210px;
height: 116px;
background: #17171D;
border-radius: 10px;
margin-top: 14px;
margin-left: 24px;
}
.notidot{
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 19px;
color: #FFFFFF;
padding-top: 10px;
padding-left: 10px;
}
.circlered{
  height: 12px;
  width: 12px;
  border-radius: 12px;
  background: #FD4B4B;
  margin-left: 10px;
  margin-top: 10px;
}
.circlepositive{
  height: 12px;
  width: 12px;
  border-radius: 12px;
  background: #007A7C;
  margin-left: 10px;
  margin-top: 10px;
}
.notidotName{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  padding-top: 2px;
  padding-bottom: 2px;

  padding-left: 10px;
  }
.notidotDate{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: gray;
  padding-top: 2px;
  padding-bottom: 5px;

  padding-left: 10px;
}
.amountPendingRequestRed{
  width: 182px;
  height: 37px;
  background-color: #FD4B4B;
  border-radius: 20px;
  text-align: center;
  margin-left: 12px;
}

.amountPendingRequestPositive{
  width: 182px;
  height: 37px;
  background-color: #007A7C;
  border-radius: 20px;
  text-align: center;
  margin-left: 12px;
}

.viewAllRequest{
  width: 211px;
  height: 44px;
  background: #007A7C;
  border-radius: 30px 0px 0px 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 43px;
  text-align: center;
  color: #FFFFFF;
  float: right;
  margin-top: 30px;
}
.transactionUserImg{
  width: 50px;
  height: 50px;
  border: 2px solid #4453D6;
  box-sizing: border-box;
  border-radius: 86px;
}

.detailModalTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #333333;
  opacity: 0.7;
}
.detailModalDate{
  display: flex;
  justify-content: space-between;

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-top: 6px;
  margin-bottom: 6px;

  color: #333333;
}
.detaillableTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #17171D;

}
.detailProfileBox{
  display: inline-flex;
}
.detailProfileBox .usernameTitle{
  font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
margin: 0px;
color: #17171D;
text-align: left;


}
.detailProfileBox .position{
  font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 19px;
text-align: left;
color: #757676;
}

.detailProfileBox .imageBox img{
border: 2px solid #4453D6;
box-sizing: border-box;
border-radius: 86px;
width: 50px;
height: 50px;

}
.detailProfileBox .imageBox{
  width: 80px;
  text-align: center;
}
.detailModaldescription{
  font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;

color: #757676;
}
.detailBorder{
  border: 1px dashed #5D5D5D;
}

.loadMoreButton{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  background: transparent  !important;
  border: none !important;
  color: #4453D6 !important;
  opacity: 0.7;
}

.transactionHistoryWrapper{
  display: flex;
  padding: 10;
  align-items: center;
  justify-content: space-between;
}

.refreshButton{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: transparent !important;
  margin-top: 25px;
  margin-right: 25px;
  float: right;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  border: 2px solid #333333 !important;
  box-sizing: border-box;
  border-radius: 30px;
  color: #17171D !important;
  width: 121px;
  height: 36px;
}

.historyGoback{
    font-family: 'Segoe';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: inline-flex;
    align-items: center;
    text-align: right;
    text-transform: uppercase;
    color: #C4C4C4;
    background: transparent;
    border: none;
}

.historyGoback:hover{
  background: transparent;
  color: #C4C4C4;
}
.historyGoback div{
  width: 25px;
  height: 25px;
  background: #4453D6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
 

}
.historyGoback span{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
}
.historyGoback img{
  width: 15px;
}


@media only screen and (min-width: 400px) and (max-width: 1025px) {
  .historyHistorymaindiv{
    width: calc(100vw - 20px);
  }
  .stepGoBackDiv{
    width: calc(100vw - 20px);
  }
}

@media only screen and (min-width: 1920px)   {

  .historyHistorymaindiv{
    width: calc(100vw - 860px);
    margin-right: 60px;
    /* overflow: hidden; */
    border-radius: 10px;
  
    margin-left: 300px;
  }
  .stepGoBackDiv{
    margin-top: 5px;
  }
 }