.Bulkadddsbackbutton {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #ffffff !important;
  background: transparent !important;
  border: none;
  margin-top: 5px;
  margin-right: 31px;
}
.Bulkadddsbackbutton:hover {
  background: transparent;
  color: #ffffff;
}
.Bulkadddsbackbutton div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.Bulkadddsbackbutton span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  color: #ffffff;
}
.Bulkadddsbackbutton img {
  width: 15px;
}

.addBulkContainer {
  padding: 30px;
  height: calc(100vh - 128px);
  width: calc(100vw - 500px);
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
}
.bulkAddsellerTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 38px;

  color: #FFFFFF;
}
.bulkUploadTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 40px;
  color: #FFFFFF;
}

.stepOneBulk{
  font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 32px;

color: #FFFFFF;
}
.stepOneBulk span{
  font-weight: bold;
}
.downloadListText{
  font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
align-items: center;

color: #FFFFFF;

opacity: 0.8;
}
.productTypeTitle{
  font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 17px;
line-height: 20px;
display: flex;
align-items: center;

color: #FFFFFF;
}

.categoryTypeButton{
  display: inline-flex;
  margin-top: 10px;
}
.categoryTypeSelection{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  display: flex;
  align-items: flex-end;

  color: #FFFFFF;
  align-items: center;
  opacity: 0.8;
  cursor: pointer;
  margin-right: 30px;
}

.downloadTemplateButton{
  background: #333333 !important;
  border-radius: 30px !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  color: #FFFFFF !important;
  width: 169.58px;
  height: 40px;
  border: none !important;
}

.submitTomarketPlace{
  background: #333333 !important;
  border-radius: 30px !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  color: #FFFFFF !important;
  width: 218px;
  height: 40px;
  border: none !important;
}
.submitTomarketPlaceNext{
  background: #F5D028 !important;
  border-radius: 30px !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  color: #17171D !important;
  width: 218px;
  height: 40px;
  border: none !important;
}
.selectedFileButton{
  width: 304px;
  height: 40px;
  background: #FFFFFF !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  margin-top: 15px;
  color: #333333 !important;
  border: none !important;
}
.overleySelectButton{
  position: relative;
  right: 30px;
  margin-bottom: 0px !important;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.overleySelectButton .hideFile{
  display: none;
}

.fileErrorButton{
  background: #FD4B4B !important;
  border-radius: 30px !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  color: #FFFFFF !important;
  width: 169.58px;
  height: 40px;
  border: none !important;
}
.fileSuccessButton{
  background: #FFFFFF !important;
  border-radius: 30px !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  color: #17171D !important;
  width: 169.58px;
  height: 40px;
  border: none !important;
}

.inlineBulkContent{
  display: inline-flex;
}

.whitleGridBox{
  margin-left: 50px;
  width: 441px;
  min-height: 436px;
  background: #FFFFFF;
  border-radius: 10px;
  padding-top: 15px;
}

.monitorStatusbutton{
  background: #4453D6;
  border-radius: 0px 30px 30px 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  width: 209px;
  height: 37px;
  color: #FFFFFF;
  display: flex;
  padding-left: 10px;
}

.bulkGridHeader{
  display: inline-flex;
  border-bottom: 1px solid #333333;
}
.bulkGridHeader div{
  width: 200px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 39px;
  text-align: center;
  color: #17171D;
}

.bulkGridItem{
  display: inline-flex;
}

.bulkGridItem div{
  width: 200px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  align-items: center;
  text-align: center;
  color: #333333;
  word-break: break-word;
  display: flex;
  justify-content: space-around;
  padding: 5px;
}
.radioSelection{
  margin-right: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  display: inline-block;
  visibility: visible;
  border: 2px solid #FFFFFF;
  align-items: center;
  display: flex;
}

.categoryTypeSelection  .checkRadio {
  width: 15px;
  height: 15px;
  border-radius: 50px;
  display: inline-block;
  background: #FFFFFF;
  margin-left: 3px;
}
.noHistory{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 39px;
  align-items: center;
  text-align: center;
  color: #333333;
}
.downloadFileButton{
  color: #FFFFFF;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  align-items: center;
  text-align: center;
  width: 120px;
  height: 35px;
  background: #4453D6 !important;
  border: none !important;
  border-radius: 20px;
}

@media only screen and (min-width: 400px) and (max-width: 1025px) {
  .addBulkContainer{
    width: calc(100vw - 10px);
  }

}

@media only screen and (min-width: 1920px)   {
  .addBulkContainer {
    width: calc(100vw - 600px);
    margin-left: 300px;
  }

}


