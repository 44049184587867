.navigateButton{
    background: transparent !important;
    border: none;
    outline: none !important;
    margin-right: 10px;
    font-size: 18px;
    align-items: center;
    display: inline-flex;
}
.navigateButton img{
  width: 35px;
  height: 20px;
}
.navigationButtonBar{
  text-align: right;
  padding-right: 10px;
  padding-top: 10px;

}
