.contactUsHeading{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;

    color: #FFFFFF;
}
.contactUsTitle{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 28px;
    opacity: 0.8;
    color: #FFFFFF;
}
.blackCircleBox{
    background: #17171D;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: table-cell;
    text-align: center;
    margin-right: 15px;
}
.blackCircleBox img{
    width: 20px;
}
.supportText{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;

    color: #FFFFFF;
    margin-top: 10px;
    display: inline-flex;
    line-height: 35px

}
.contactUSContainer{
    padding-top: 20px;
    padding-left: 50px;
    width: calc(100vw - 500px);

}
.underConstructionSettings{
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-size: 22px;
    color: #FFFFFF;
    margin-top: 10px;
    line-height: 35px;
    font-weight: bold;
    width: 100%;
}
/* .supportText img{
    width: 30px;
    margin-right: 10px;
} */