.payContainer {
  font-family: Helvetica;
  font-style: normal;
  padding-top: 20px;
  /* width: 500px; */
  /* background-color: #333333; */
  text-align: center !important;
  /* height: 100vh; */
  /* overflow: hidden; */
}
.blanceTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
  align-items: center;
  text-align: center;
  opacity: 0.7;
  color: #ffffff;
}
.colorWhite {
  color: #ffffff;
}

.balanceAmount {
  font-size: 18px;
  line-height: 35px;
  text-align: center !important;
  margin-top: 10px;

  font-family: Segoe;

  color: #ffffff;
}
.balanceAmount span {
  font-size: 34px;
}
.balanceCredit {
  line-height: 21px;
  text-align: center !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  opacity: 0.9;

  text-align: center;

  color: #FFFFFF;
}
.sendPayButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4453d6 !important;
  border-radius: 999px;
  height: 47px;
  width: 164px;
  border: none !important;
  color: #eeeeee;
  font-size: 18px;
  margin: 0 auto;
}
.requestButton {
  background: #fff !important;
  border-radius: 20px;
  width: 140px;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  /* opacity: 0.5; */
  color: #333333;
  border: none !important;
}
.topUpButton {
  background: #4092b7;
  border-radius: 10px;
  width: 120px;
  height: 50px;
  border: none !important;
  color: #eeeeee;
}
.borderDiv {
  margin-left: 50px;
  border: 1px solid #24a2a8;
  margin-right: 50px;
  margin-top: 30px;
}
.sendPayButton:hover,
.sendPayButton:active,
.requestButton:hover,
.requestButton:active,
.topUpButton:hover,
.topUpButton:active {
  background: #4453d6 !important;
  border: none !important;
}
.rdeePayOptionsCSS {
  text-align: left;
  margin-left: 60px;
  margin-bottom: 15px;
}
.topUpOptionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #007A7C !important;
  border-radius: 30px;

  height: 45px;
  border: none !important;
  color: #eeeeee;
  font-size: 18px;
  text-align: center;
}
.walletSettingOptionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #007A7C !important;
  border-radius: 30px;
  height: 45px;
  border: none !important;
  color: #eeeeee;
  font-size: 18px;
  text-align: center;
}
.viewRequestOptionButton {
  background: #007A7C !important;
  border-radius: 30px;
  width: 200px;
  height: 45px;
  border: none !important;
  color: #eeeeee;
  text-align: left;
  padding-left: 20px;
}
.transactionMainTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}

.transactionPayOption {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
  align-items: center;
  text-align: center;
  opacity: 0.7;
  color: #FFFFFF;
}

.transactionTitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 10px;
}
.paystatusTitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #ffffff;
}
.usernameText {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  margin-top: 5px;
  color: #ffffff;
  margin-bottom: 3px;
}
.payDate {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.payAmount {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #4092b7;
  margin-left: 20px;
  float: right;
}

.payAmountFaild {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #ff4b4b;
  margin-left: 20px;
  float: right;
}

.bodyWidth {
  margin-left: 15px;
}
.avatarImg {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 86px;
  width: 52px;
  height: 50px;
}

.payCard {
  background: #333333;
  border-radius: 10px;
  padding: 12px;
  margin-right: 20px;
  min-width: 330px;
  margin-bottom: 20px;
}

.transactionContainer {
  display: flex;
  flex-wrap: wrap;
}
.recentText {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  opacity: 0.5;
  margin-left: 10px;
}
/* .modal{
  width: min-content;
} */
.amountmodal {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* text-align: right; */
  color: #4092b7;
  /* margin-left: 20px; */
}
.headtext_m {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  text-align: center;

  color: #ffffff;

  margin-top: 15px;
}
.topup_img {
  height: 100px;
  width: 100px;
}
.topuptext {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;

  color: #ffffff;
}
.para {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #C4C4C4;
  padding-left: 13px;
}
.para1 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  padding-left: 13px;
}
.para1 span{
  font-size: 16px;
  opacity: 0.7;

}
.closeimg {
  height: 26px;
  width: 26px;
  float: right;
}
.modal_headtext {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-top: 25px;
  margin-bottom: 5px;
  /* identical to box height */

  color: #ffffff;
  opacity: 0.5;
}
.sendtitle {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 15px;

  color: #ffffff;
}
.sendtitle2 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  float: left;
  color: #ffffff;
  margin-bottom: 13px;
  margin-top: 14px;
}
.div-flex {
  height: 100vh; /* Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica;
}
.sendContactList {
  justify-content: center;
  display: inline-flex;
}
.sendlist {
  display: inline-flex;
}
.textLeft {
  text-align: left;
}
.boldHeadings {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #4092b7;
}

.contactusernameText {
  font-family: Helvetica;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-top: 15px;
}

.errorMessage {
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ff3c3c;
}

.pinImage {
  width: 67px;
  margin-top: 80px;
  margin-bottom: 30px;
}

.PinBox {
  justify-content: center;
  display: inline-flex;
  width: 300px;
}
.pinText {
  font-size: 20px;
  line-height: 23px;
  text-align: center;
}
.congratulationText {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  margin-top: 10px;
}

.congratulationContent {
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  text-align: center;
  margin-top: 20px;
}
.anotherTransaction {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 30px;
  color: #4092b7;
  cursor: pointer;
}
.ModalborderStyle {
  opacity: 0.3;
  border: 2px solid #ffffff;
  border-style: dashed;
  margin-left: 20px;
  margin-right: 20px;
}
.modal-content {
  background: transparent;
  border: none;
}
.commanButtonClass {
  border: 0px !important;
  margin-top: 20px;
  margin-bottom: 10px;
  float: right;
  margin-top: 140px;
  width: 100%;
  background: #4453D6;

  border-radius: 30px;

}
.requestTopUpbutton {
  margin-top: 10px;
  background: #4453D6 !important;
  margin-bottom: 20px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  height: 40px;
  color: #FFFFFF;
}

.transactionHistoryScrollContent {
  min-height: 400px;
  max-height: calc(100vh - 50vh);
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;

  /* background:red; */
  width: calc(100% - 50px);
  margin-left: -5px;
  padding-left: 10px;
}

.loadMore {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #4092b7;
  text-align: center;
  margin-top: 20px;
}

.searchContactScroll {
  min-height: 300px;
  max-height: 300px;
  overflow: scroll;
  overflow-x: hidden;
}
.balance {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  text-align: left;
}
.pAYamiunt {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: #ffffff;
  text-align: left;
}
.PAYRDECREDIT {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  text-align: left;
}
.sendto {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  text-align: left;
  padding-left: 22px;
}
/* .listrecf{
width: 464px;
height: 696px;
background: #333333;
opacity: 0.25;
border-radius: 10px;
margin-top: 15px;
} */
.paysearcgrec {
  width: 461px;
  height: 49px;
  background: #333333;
  border-radius: 5px;
  border: none;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.5;
  padding-left: 45px;
  /* position: relative; */
}
.listrecf {
  width: 490px;
  /* height: 608px; */
  background: #000000;
  border-radius: 10px;
  margin-top: 15px;
}
.paysearch {
  /* position: absolute; */
  top: 88px;
  left: 358px;
}
.Contacts {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  text-align: left;
  padding-top: 23px;
  padding-left: 22px;
}
.paynextbut {
  background: linear-gradient(180deg, #439fc8 0%, #4358a7 100%);
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  border: none;
  width: 230px;
  height: 42px;
  margin-left: 500px;
}
.line {
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 480px;
  margin-top: 7px;
}
.payconnimh {
  width: 40px;
  height: 40px;
  /* background: url(3.jpg); */
  border-radius: 86px;
}
.payMoore {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  text-align: left;
  padding-left: 16px;
}
.paymarketing {
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.7;
  text-align: left;
  padding-left: 16px;
}
.psymedical {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.7;
  text-align: left;
  padding-left: 16px;
}
.paySending {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #4092b7;
}
.owmuch {
  /* How much? */
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: #ffffff;
  text-align: left;
  padding-top: 31px;
}
.payinpiuyt {
  float: left;
  width: 252px;
  height: 49px;
  background: #333333;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.5;
  border: none;
  padding-left: 10px;
  margin-top: 18px;
}
.amoupayin {
  margin-top: 15px;
  padding-left: 10px;
  float: left;
  border: none;
  width: 533px;
  height: 49px;
  background: #333333;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.5;
}
.paysentdingna {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  text-align: left;
  padding-left: 15px;
}
.posenfpay {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  text-align: left;
  padding-left: 15px;
  opacity: 0.7;
}
.possenfng {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.7;
  text-align: left;
  padding-left: 15px;
}

.userListContainer {
  height: calc(100vh - 350px);
  overflow: hidden;
  overflow-y: scroll;
}
.nextButtonClass {
  height: calc(100vh - 200px);
}

.modalHeaderTitle{
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
padding-left: 20px;
color: #FFFFFF;
text-align: left;
}
.receivemsg{
  font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 6px;
    color: #FFFFFF;
}
.reasonTitle{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    padding-left: 20px;
    color: #FFFFFF;
    margin: 0px;
    text-align: left;
}

.topup_purpose {
    margin-top: 10px;
    padding-left: 10px;
    border: none;
    background: #333333;
    border-radius: 5px;
    font-style: normal;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.5;
    width: 100%;
    border-radius: 30px !important;
}