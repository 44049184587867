.productListImg{
    
    box-sizing: border-box;
    border-radius: 300px;
    width: 86px;
    height: 86px;
    overflow: hidden;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
}
.productListImg img{
    width: 100%;
    height: 100%;
}

.rightSideImgBox{
    display: inline-flex;
    flex-wrap: wrap;
}

  .zoomProductModalBody{
    /* padding: 25px; */
    background: #FFFFFF;
    min-width: 900px;

    /* width: calc(100vw - 200px); */
    /* position: absolute; */
    /* width: 900px; */
    /* height: 700px; */
    border-radius: 30px;
    padding: 0px !important;
    overflow: hidden;
  }

  .zoomImageArea{
    width: 100vw;
    max-height: calc(100vh - 150px);
  }

@media only screen  and (min-width: 600px)  and (max-width: 1024px) {
  .zoomProductModalBody{
    width: 900px !important;
  }

}

  .productTitleName{

    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 38px;

    color: #17171D;
  }
  .productPriceText{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 38px;

    color: #17171D; 
  }
  .productDeviceLocation{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin: 0px;
    color: #333333;
  }
  .zoomActionButton{
    width: 50px;
    height: 50px;
    background: #4453D6 !important;
    border-radius: 100px;
    border: none !important;
    margin: 5px;
    padding: 0px;
  }
  /* .zoomActiomButton img{
      width: 100%;
  } */

  .resetZoomButton{
    width: 80px;
    background: #4453D6 !important;
    border: none !important;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #FFFFFF !important;
    margin: 5px;

    }

    .activeBorderCircle{
      position: relative;
    }

    .activeBorderCircle::before{
      position: absolute;
      content: '';
      border: 5px solid #4453D6;
      overflow: hidden;
      border-radius: 300px;
      width: 86px;
      height: 86px;
      overflow: hidden;
    }


    .closeZoomButtonIcon{
        background: transparent !important;
        border: 0px;
        outline: 0px;
        padding: 0px;
        /* float: right; */
        margin-right: 15px;
        margin-top: 10px;
        position: absolute;
        right: 0px;
      }
      .closeZoomButtonIcon img{
        width: 30px;
      }
      