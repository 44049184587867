.group-details-container {
  display: flex;
  width: 30vw;
  max-width: 350px;
  min-width: 200px;
  height: 10vh;
  max-height: 500px;
  flex-direction: column;
  flex-grow: 1;
}


.group-details-container .group-details-header {
  display: flex;
  color: white;
  align-self: center;
  flex: 1;
  font-weight: 800;
}

.group-details-container .group-details-body {
  display: flex;
  flex: 5;
  flex-direction: column;
}

.group-details-container .group-details-body .group-info-container {
  display: flex;
  flex: 2;
  flex-direction: row;
}

.group-details-container
  .group-details-body
  .group-info-container
  .group-detail-image-container {
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: center;
}

.group-detail-image-container img {
  border-radius: 4vw;
  height: 8vw;
  width: 8vw;
  min-height: 50px;
  min-width: 50px;
  align-self: center;
}

.group-details-container
  .group-details-body
  .group-info-container
  .group-image-info {
  display: flex;
  flex: 6;
  flex-direction: column;
}

.group-details-container
  .group-details-body
  .group-info-container
  .group-image-info
  div {
  padding: 3px 0px;
}

.group-detail-picture-name {
  color: white;
  font-weight: 800;
  font-size: 0.9rem;
}

.group-image-detail {
  font-style: italic;
  font-size: 0.8rem;
  color: #707070;
}

.group-details-container .group-details-body .group-name-container {
  display: flex;
  flex: 3;
  padding: 15px 0px;
  flex-direction: column;
  margin-top: 17px;
}

.group-details-container .group-details-body .group-name-container input {
  padding: 0px 15px;
}

.group-details-container
  .group-details-body
  .group-name-container
  .footer-text {
  color: white;
  font-size: 0.7rem;
  padding: 5px 0px;
}

.group-details-container .group-details-footer {
  flex: 1;
  margin: 0 auto;
}
.firsttext {
  /* Provide a group subject and group icon */

  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #ffffff;
}
.grouppicSelection {
  /* Group Picture* */

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #ffffff;
  margin-top: 10px;
}
.picturewar {

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-weight: normal;
  font-size: 12px;

  color: #ffffff;
  width: 250px;
  opacity: 0.5;
}
.submitbutton {
  background: linear-gradient(180deg, #439fc8 0%, #4358a7 100%);
  border-radius: 5px;
  width: 300px;
  margin-top: 185px;
}
.inputdiv {
  margin-top: 17px;
  width: 310px;
}
.colwidth {
  width: 400px;
}
.buttondiv {
  margin-top: 200px;
  padding-left: 68px;
}
.footer-text {
  font-size: 12px;
  color: #a5a5a5;
  font-style: italic;
}

.groupIConButton {
  display: inline-flex;
  justify-content: space-between;
  width: 270px;
}
.groupImageUploadcreating{
    font-family: Segoe !important;
    font-style: normal !important;
    font-weight: normal !important;
    background-color: #4453d6 !important;
    font-weight: bold !important;
    border-radius: 20px !important;
    text-decoration: none !important;
    margin-top: 15px !important;
    width: 95px !important;
    height: 32px !important;
    font-size: 14px !important;
    line-height: 19px !important;
    align-items: center !important;
    text-align: center !important;
    color: #ffffff !important;
    border: none !important;
  }
