.searchmainmaindiv {
  width: 404px;
  min-height: 389px;
  background: #333333;
  border-radius: 10px;
  margin-top: 18px;
  margin-left: 58px;
  padding-bottom: 20px;
}
.searcificmhding {
  background: #ffffff;
  border-radius: 0px 30px 30px 0px;
  width: 193px;
  height: 44px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 41px;
  text-align: center;
  color: #333333;
}
.Otheruser {
  padding-left: 43px;
  padding-top: 24px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.OFFON {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  text-align: right;
  margin-right: 40px;
  margin-bottom: 10px;
}
.OFFON span {
  color: #4aea87;
}
.SearchHand {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 43px;
}
.PhoneNumb {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 43px;
}
.EmailID {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 43px;
}
.BusinessProfi {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 43px;
}
.searchanbutton {
  width: 318px;
  height: 54px;
  border: none;
  border-radius: 30px;
  background: #4453d6;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-top: 25px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 12px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;     
  -webkit-transition: .4s;
  transition: .4s;
  
}

.slider:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  left: 0px;
  bottom: -4px;
  background-color: #4AEA87;
  -webkit-transition: .4s;
  transition: .4s;
}


input:checked + .slider {
  background-color: #4AEA87;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4AEA87;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.contetToggleBoxArea{
  display: flex;
    justify-content: space-between;
    width: 360px;
}
