
.inputfile{
  display: none;
}

.videoBoxContainer{
  padding-left: 50px;
  padding-top: 20px;
  width: calc(100vw - 10px);
  height: calc(100vh - 10px);

}


@media only  screen and (min-width: 1920px){

  .videoBoxContainer{
    padding-left: 300px;
  }

}
