.rootmainsscettingdivs{
  height: 100vh;
  width: calc(100vw - 0px);
}
.mainsscettingdivs {
  display: flex;
  
  /* overflow-y: auto; */
  width: calc(100vw - 0px);
  overflow-x: auto;
  margin-top: 15px;
}
.scrollSettingContent{
  height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x:auto;
  margin-top: 30px; 
  width: calc(100vw - 480px);
  min-width:500px;
}

@media only  screen and (min-width: 1920px){
  .mainsscettingdivs {
    margin-top: 50px;
    margin-left: 300px;
    width: calc(100vw - 300px);
  }
  }

.accountbackbutton {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #D9D9D9 !important;
  background: transparent;
  border: none;
  margin-top: 0px;
}
.accountbackbutton:hover {
  background: transparent;
  color: #666666;
}
.accountbackbutton div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.accountbackbutton span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  color: #ffffff;
}
.accountbackbutton img {
  width: 15px;
}
.accccSETTINGS {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: #ffffff;
}
.accsettingsbutton {
  background: #ffffff !important;
  border-radius: 30px;
  width: 213px;
  height: 49px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #333333 !important;
  border: none;
  margin-top: 20px;
}
.accsettingsbutton img {
  margin-right: 12px;
}
.accsettingsbutton2 {
  background: #333333;
  border-radius: 30px;
  width: 213px;
  height: 49px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  border: none;
  margin-top: 20px;
}
.accsettingsbutton2 img {
  margin-right: 12px;
}
.authsebutton {
  background: #333333;
  border-radius: 30px;
  width: 264px;
  height: 49px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin-top: 15px;
}

.authsebutton img {
  margin-right: 12px;
}
.notisetbutton {
  background: #333333;
  border-radius: 30px;
  width: 241px;
  height: 49px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin-top: 15px;
}

.notisetbutton img {
  margin-right: 12px;
}
.notisetbton2 {
  background:#ffffff !important;
  border-radius: 30px;
  width: 241px;
  height: 49px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #333333 !important;
  margin-top: 15px;
}

.notisetbton2 img {
  margin-right: 12px;
}
.searcsetbutton {
  background: #333333;
  border-radius: 30px;
  width: 200px;
  height: 49px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin-top: 15px;
}

.searcsetbutton img {
  margin-right: 12px;
}
.searbutton2 {
  background: #FFFFFF !important;
  border-radius: 30px;
  width: 200px;
  height: 49px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #333333 !important;
  margin-top: 15px;
}

.searbutton2 img {
  margin-right: 12px;
}
.wallsetbutton {
  background: #333333;
  border-radius: 30px;
  width: 197px;
  height: 49px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin-top: 15px;
}

.wallsetbutton img {
  margin-right: 12px;
}
.walactivetbutton {
  background: #ffffff !important;
  border-radius: 30px;
  width: 197px;
  height: 49px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #333333 !important;
  margin-top: 15px;
}

.walactivetbutton img {
  margin-right: 12px;
}
.apabsetbutton {
  background: #333333;
  border-radius: 30px;
  width: 264px;
  height: 49px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin-top: 15px;
  text-align: left;
  padding-left: 20px;
}

.apabsetbutton img {
  margin-right: 12px;
}
.apabutton2 {
  background: #FFFFFF !important;
  border-radius: 30px;
  width: 264px;
  height: 49px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #333333 !important;
  margin-top: 15px;
  text-align: left;
  padding-left: 20px;
}

.apabutton2 img {
  margin-right: 12px;
}
.contsetbutton {
  background: #333333;
  border-radius: 30px;
  width: 210px;
  height: 49px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin-top: 15px;
}

.contsetbutton img {
  margin-right: 12px;
}
.logsetbutton {
  background: #333333;
  border-radius: 30px;
  width: 137px;
  height: 49px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin-top: 15px;
}

.logsetbutton img {
  margin-right: 12px;
}

.accsetheading {
  background: #ffffff;
  border-radius: 0px 30px 30px 0px;
  width: 210px;
  height: 44px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 41px;
  text-align: center;
  color: #333333;
}



.settinghjohn {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #ffffff;
  padding-left: 20px;
  margin: 0px;
  padding-top: 18px;
}
.settingDrJohn {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  color: #ffffff;
  padding-left: 20px;
}

.settingfiernjd {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.6;
  padding-left: 44px;
  margin: 0px;
}
.settibnjhdkjon {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 44px;
}
.settibssnjhdkjon {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 20px;
}
.sescsdingfiernjd {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.6;
  padding-left: 20px;
  margin: 0px;
}
.sescsdinlastnamgf {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.6;
  padding-left: 39px;
  margin: 0px;
}
.settibssnalenaxal {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 39px;
}
.settinguserimg {
  width: 100px;
  height: 100px;
  margin-top: 20px;
  margin-left: 39px;
}
.seteditaccsedetbox {
  width: 705px;
  height: 631px;
  background: #333333;
  border-radius: 10px;
  margin-left: 25px;
  margin-top: 36px;
}
.settingttileinput {
  width: 88px;
  height: 49px;
  background: #17171d;
  border-radius: 30px;
}
.firstnameinput {
  width: 169px;
  height: 49px;
  background: #17171d;
  border-radius: 30px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin-left: 20px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.settingtitlelabel {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.6;
  margin: 0px;
  padding-left: 33px;
}
.settingtfirstnsmla {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.6;
  margin: 0px;
  padding-left: 53px;
}
.settinglastnlabel {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.6;
  margin: 0px;
  padding-left: 33px;
}
.lastnameinput {
  width: 169px;
  height: 49px;
  background: #17171d;
  border-radius: 30px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin-left: 20px;
}
.settingaboutlabel {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.6;
  margin: 0px;
  padding-left: 53px;
}
.aboutsssninput {
  width: 617px;
  height: 49px;
  background: #17171d;
  border-radius: 30px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin-left: 39px;
}
.aboutninput {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.6;
  margin: 0px;
  padding-left: 39px;
  padding-top: 10px;
  margin-left: 39px;
}

.emailjcdkninput {
  width: 460px;
  height: 49px;
  background: #17171d;
  border-radius: 30px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin-left: 39px;
}
.phonedkninput {
  width: 233px;
  height: 49px;
  background: #17171d;
  border-radius: 30px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin-left: 39px;
}
.klsjfkejcdkninput {
  width: 617px;
  height: 66px;
  background: #17171d;
  border-radius: 30px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin-left: 39px;
}

.authsebutton2 {
  background: #ffffff !important;
  border-radius: 30px;
  width: 264px;
  height: 49px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color:#333333 !important;
  margin-top: 15px;
}

.authsebutton2 img {
  margin-right: 12px;
}
.seterrorpassword{
width:327px;
background: #D80031;
border-radius: 10px;
font-family: Helvetica;
font-style: italic;
font-weight: normal;
font-size: 16px;
text-align: center;
color: #FFFFFF;
/* height: 100px; */
margin: 0 auto;
margin-top: 17px;
padding: 5px;
}