.contact {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #333333;
    cursor: pointer;
    padding: 10px 10px 10px 20px;
    min-height: 80px;
    justify-content: space-between;
}

.contact .contact-image-container {
    display: flex;
    /* flex: 1; */
    align-items: center;
}

.contact-image {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    min-width: 25px;
    min-height: 25px;
    border: 1px solid #FFFFFF;
    margin:auto;
}

.text-image {
    color: #FFFFFF;
    background-color: #4092B7;
    width: 40px !important;
    height: 40px!important;
    border-radius: 100px!important;
    min-width: 25px;
    min-height: 25px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact .contact-info {
    display: flex;
    flex: 5;
    flex-direction: column;
    padding-left: 15px;
}

.contact .contact-info .contact-name {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
}

.contact .contact-info .contact-profile {
    font-style: italic;
}

.contact .contact-info div {
    display: flex;
    flex:1;
}

.edit-image {
    height: 2vh;
    cursor: pointer;
}
.addContactIcons{
    margin:auto;
}
.addContactIcons img{
    width: 24px;
}