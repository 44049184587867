.info-card {
    min-width: 200px;
    max-height: 200px;
    display: flex;
    flex-direction: column;
}

.info-card .info-card-header {
    display: flex;
    flex: 1;
    color: #ddd;
    font-weight: 700;
    justify-content: space-between;
}

.info-card .info-card-header img {
    float: right;
    height: 2.5vh;
    cursor: pointer;
}

.info-card .info-card-body {
    position: relative;
    display: flex;
    flex: 6;
    flex-direction: column;
}

.info-card .info-card-body img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 3px;
}

.info-card .info-card-body p {
    position: absolute;
    bottom: 0;
    font-size: 1.1rem;
    font-weight: bolder;
    color: white;
    margin-left: 10px;
    z-index: 20;
}

.info-card .info-card-body .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.4);
}
.groupInfoCard{
    background: #333333;
    min-width: 400px;
    min-height: 350px;
    border-radius: 10px;
    padding-bottom: 30px;
    padding-right: 20px;


}
.groupTitleButton{
    background: #4453D6 !important;
    margin-top: 10px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border: none;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 146px;
    height: 44px;

}
.groupTitleButton span{
    font-weight: bold;
}
.groupName{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 38px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    color: #FFFFFF;
    width: 250px;

}
.onOfftext{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 38px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    color: #FFFFFF;
    margin: 0;
}
.onOfftext span{
    color: #4AEA87;
}
.groupImageStyle{
    background:#4453D6;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 60px;
    width: 95px;
    height: 95px;
}
.groupNameImageSection{
    display: inline-flex;
    padding-left: 15px;
    padding-top: 15px;
    width: 100%;
    /* margin: 20px; */
    text-align: center;
}

.notificationGroupTitleText{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    padding-top: 15px;
    /* display: inline-flex; */
}

.groupSwitch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 12px;
  }
  
  .groupSwitch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .groupSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF;     
    -webkit-transition: .4s;
    transition: .4s;
    
  }
  
  .groupSlider:before {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    left: 0px;
    bottom: -4px;
    background-color: #4AEA87;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  
  input:checked + .groupSlider {
    background-color: #4AEA87;
  }
  
  input:focus + .groupSlider {
    box-shadow: 0 0 1px #4AEA87;
  }
  
  input:checked + .groupSlider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .groupSlider.round {
    border-radius: 34px;
  }
  
  .groupSlider.round:before {
    border-radius: 50%;
  }
  .toggleNotificationSection{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    padding-top: 0px;
  }

  .borderBottom{
    margin: 25px;
    margin-top: 0px;
    border: 1px solid rgba(255, 255, 255, 0.1)
  }

  .manageGroup{
    background: #FFFFFF !important;
    border-radius: 30px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    margin-left: 10px;
    color: #17171D !important;
    width: 158px;
    height: 37px;
    border: none !important;
  }
  .leaveGroup{
    background: #4453D6 !important;
    border-radius: 30px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;

    color: #FFFFFF !important;
    width: 158px;
    height: 37px;
    border: none !important;
    margin-right: 30px;
    margin-left: 15px;
  }