.marketproducbackbutton {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #666666;
  background: transparent;
  border: none;
  margin-top: 5px;
  margin-right: 31px;
}
.marketproducbackbutton:hover {
  background: transparent;
  color: #666666;
}
.marketproducbackbutton div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.marketproducbackbutton span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  color: #666666;
}
.marketproducbackbutton img {
  width: 15px;
}
.placeorderbuttonx {
  background: #4453d6 !important;
  border-radius: 30px;
  width: 165px;
  height: 42px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  /* margin-left: 177px; */
}
.SiemensAvapro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #17171d;
}
.SiemensAvapro span {
  font-size: 35px;
}
.DeviceTypepro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #333333;
  margin: 0px;
}
.Locationpro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #333333;
  margin: 0px;
}
.detailsdropdownpro {
  width: 523px;
  height: 37px;
  background: #333333;
  border-radius: 0px 30px 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  cursor: pointer;
  color: #FFFFFF;
}
.SPECIFICATIONSpro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  /* color: #ffffff; */
  padding-left: 15px;
}
.closeimgstyle {
  transform: rotate(180deg);
  margin-right: 15px;
}
.Manufacturerpro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #767575;
  padding-left: 15px;
  padding-top: 12px;
}
.SystemNamepro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #767575;
  padding-left: 15px;
}
.MRIpro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  padding-top: 12px;
}
.Symphonyprof {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
}
.detaidropdnpro2 {
  width: 523px;
  height: 37px;
  background: #333333;
  border-radius: 0px 30px 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 17px;
  cursor: pointer;
  color: #FFFFFF;
}
.detaidropdActiveBG{
  background: #4453D6 !important;
  /* color: #17171D; */
}
.TitleSeapro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  text-decoration-line: underline;
  color: #f5d028;
  padding-left: 250px;
}
.downloadrepipro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #f5d028;
  padding-left: 215px;
}
.downloadrepipro a{
  color: #f5d028;
}
.prodetailmaindiv{
  display: flex;
  padding: 30px;
  height: calc(100vh - 100px);
  overflow-y: auto;
  align-items: baseline;
  width: calc(100vw - 500px);
  overflow-x: auto;
}
.circleimage1{
width: 86px;
height: 86px;
border-radius: 86px;
cursor: pointer;
}
.circleimage2{
width: 86px;
height: 86px;
border-radius: 86px;
margin-left: 13px;
}
.sellerinfobopro{
background: #333333;
border-radius: 10px;
width: 500px;
height: 523px;
margin-top: 26px;
}
.sellerinfoheadiung{
width: 229px;
height: 44px;
background: #4453D6;
border-radius: 0px 30px 30px 0px;
margin-top: 17px;
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 42px;
color: #FFFFFF;
text-align: center;

}
.top100pro{
background: #F5D028;
border-radius: 30px;
width: 78px;
height: 25px;
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 12px;
text-align: center;
color: #17171D;
opacity: 0.8;
margin-left: 123px;
line-height: 25px;
}
.veiewpeofilebuttonpro{
background: #4453D6;
border-radius: 30px;
width: 164.77px;
height: 37px;
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 14px;
text-align: center;
color: #FFFFFF;
border: none;
margin-left: 73px;
}
.JulieMarthapro{
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 21px;
color: #FFFFFF;
margin: 0px;
padding-left: 15px;
}
.Reputationpro{
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 16px;
color: #FFFFFF;
opacity: 0.7;
padding-left: 15px;
}
.RecentRevpro{
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 18px;
color: #FFFFFF;
opacity: 0.8;
padding-left: 27px;
}
.reviewdivpropro{
width: 399px;
min-height: 145px;
background: #17171D;
border-radius: 10px;
margin-top: 10px;
margin: auto;
padding: 20px;
overflow-x: auto;
margin-top: 10px
}
.usersgetthes{
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 12px;
color: #FFFFFF;
}
.FrankPaulpro{
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 16px;
color: #FFFFFF;
margin: 0px;
padding-left: 12px;
}
.dateprorpro{
font-family: Segoe;
font-style: italic;
font-weight: normal;
font-size: 11px;
color: #FFFFFF;
opacity: 0.6;
padding-left: 12px;
}
.productName_BOLD{

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  /* or 100% */


  color: #FFFFFF;
}

.rightSideCircleImage{
  display: flex;
    margin-left: 16px;
    flex-wrap: wrap;
}
.rightSideCircleImage img{
  margin: 10px;
  cursor: pointer;
}
.maineProductDEtailContainer{
  display: inline-flex;
  background:#FFFFFF;
}



.placeOrderModal{
  background:#4453D6;
  border-radius: 30px;
  padding: 25px;
}

.placeOrderModalForMessage{
  background:#4453D6;
  position: absolute;
  width: '800';
  height: '700';
  border-radius: 30px;
  padding: 5px;
}
.placeOrderModal .confirmmsg{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-top: 5px;
  margin-bottom: 5px;

  color: #FFFFFF;

  opacity: 0.8;
}
.placeOrderModal .confirmTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #FFFFFF;
  margin-bottom: 5px;
  
}
.placeOrderModal .buttonDiv{
  display: inline-flex;
  float: right;
}
.placeOrderModal .buttons{
  min-width: 100px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #FFFFFF;
  border-radius: 30px;
  background: transparent;
  border: 1px solid #FFFFFF ;
  height: 40px;

}
.placeOrderModal .yesButton{
  background:#17171D !important;
  margin-left: 10px;
  border: none !important;
}
.placeOrderModal .viewButton{
  background: #17171D !important;
  margin-left: 10px;
  font-size: 13px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  width: 150px;
  color: #FFFFFF !important;
  border: none !important;

}

.placeOrderModal .inputItemFields{
    background: #333333 !important;
    border-radius: 30px;
    width: 235px;
    height: 42px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    padding-left: 10px;
}
.placeOrderModal .inputLabels{
  padding-left: 15px;

}

.placingOrderDropdown {
  /* width: 441px;
  height: 868px; */
  background: transparent;
  border-radius: 10px;
  margin-top: 20px;
  /* overflow-x: auto; */
}
.placingOrderDropdown .p-dropdown{
  border: none !important;
  height: 35px;
  background: transparent;
}

.placingOrderDropdown .p-dropdown label{
  padding: 10px !important;
}
.placingOrderDropdown  .p-dropdown .p-dropdown-trigger{
  margin-top: 4px !important;
}
.priceItem{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
    margin: 0px;
}
.errorProductAvailable{
  font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #D80031;
    margin: 0px;
    font-size: 16px;
    margin-top: 10px;
}

.productDetailCollapsingBox{
 
  display: inline-flex;
}
.productDetailCollapsingBox > div:nth-of-type(1) {
  min-width: 190px;
}
.productDetailCollapsingBox > div:nth-of-type(2) {
  padding-left: 50px;
  max-width: 250px;
  word-break: break-all;
}

.placeOrderConfirmBoxPrice{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-align: right;
    color: #ffffff;
    margin: 0px;
}
.productHeadingTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;

  color: #17171D;
}
.productHeadingTitle span{
  font-weight: bold;
}
.productDescription{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #333333;
}
.shareButton{
  background: #17171D !important;
  color: #FFFFFF !important;
  border: none !important;
  border-radius: 30px;
  width: 120px;
  height: 42px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
}


.shareModalBox{
  background:#17171D;
  border-radius: 30px;
  /* padding: 25px; */
}
.shareModalBox .textInput{

  background: #17171D;
  opacity: 0.8;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #FFFFFF !important;
  height: 46px;
  width: 100%;
  margin-left: 5px;
  padding-left: 10px;

}
.shareModalBox{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 5px;
  
}
.shareModalBox .productInfoBox{
  display: inline-flex;
  justify-content: flex-start;
  background: #FFFFFF;
  color: #17171D;
  margin-top: 10px;
}


.messageToseller {
  background: #333333 !important;
  border-radius: 30px;
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
  margin-right: 10px;
  margin-bottom: 10px;
}

.productInfoBox .product_Name{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #17171D;
  margin-left: 12px;
  word-break: break-all;
}
.productInfoBox .product_location{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  
  color: #333333;
  margin-left: 12px;
  word-break: break-all;
} 
.searchMember{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  align-items: center;

  color: #FFFFFF;
}

.product_contactListing{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  color: #FFFFFF;
}
.shareContactBox{
  display: inline-flex;
  margin-top: 10px;
}
.shareContactBox > div:nth-of-type(1) ,.shareContactBox > div:nth-of-type(3){
  width: 65px;
  text-align: center;
}
.shareContactBox > div:nth-of-type(2) {
  width: 150px;
}
.shareContactBox .contact_userName{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  
  color: #FFFFFF;
}
.shareContactBox .contact_lastSeen{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  word-break: break-all;
  color: #C4C4C4;
}

.shareContactBox .sendICon{
  width: 25px;
  margin-top:10px; 
}
.closeShareModal{
  background: transparent !important;
  border: none !important;
  padding: 0px !important;
}
.shareAgainButton{
  background: #4453D6 !important;
  border-radius: 30px;
  color: #FFFFFF !important;
  border: none !important;
  width: 100%;
  height: 40px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;

}


@media only screen and (min-width: 400px) and (max-width: 1025px) {
    .prodetailmaindiv{
      width: calc(100vw - 20px);
    }

}
@media only  screen and (min-width: 1920px){
  .prodetailmaindiv{
    display: flex;
    padding: 0px;
    /* height: calc(100vh - 100px); */
    overflow-y: auto;
    align-items: baseline;
    width: calc(100vw - 800px);
    overflow-x: auto;
    margin-left: 330px;
    }
    .marketproducbackbutton{
      margin-top: 20px;
    }

    .maineProductDEtailContainer {
      position: absolute;
      top: 20px;
      width: 100vw;
    }
 
}
