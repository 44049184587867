.notuingmaindiv {
  min-width: 400px;
  width: 404px;
  min-height: 380px;
  background: #333333;
  border-radius: 10px;
  margin-top: 15px;
  margin-left: 58px;
}
.aaunotificmhding {
  background: #ffffff;
  border-radius: 0px 30px 30px 0px;
  width: 240px;
  height: 44px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 41px;
  text-align: center;
  color: #333333;
}
.RDEEMessseng {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 43px;
  padding-top: 24px;
}
.RDEEPay {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 43px;
}
.rdemarketplaxdsv {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 43px;
}
.OtherNot {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 43px;
}
.Emailaler {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 43px;
}
.notificavecbuttin {
  width: 318px;
  height: 54px;
  border: none;
  border-radius: 30px;
  background: #4453D6 !important;
  margin-top: 15px;
}



.notificationswitch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 12px;
  margin-top: 10px;
}

.notificationswitch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.notificationslider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;     
  -webkit-transition: .4s;
  transition: .4s;
  
}

.notificationslider:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  left: 0px;
  bottom: -4px;
  background-color: #4AEA87;
  -webkit-transition: .4s;
  transition: .4s;
}


input:checked + .notificationslider {
  background-color: #4AEA87;
}

input:focus + .notificationslider {
  box-shadow: 0 0 1px #4AEA87;
}

input:checked + .notificationslider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.notificationslider.round {
  border-radius: 34px;
}

.notificationslider.round:before {
  border-radius: 50%;
}

.notiticationContetToggleBoxArea{
  display: flex;
    justify-content: space-between;
    width: 360px;
}

.NotificationOFFON {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  text-align: right;
  margin-right: 40px;
  margin-bottom: 10px;
}
.NotificationOFFON span {
  color: #4aea87;
}
