.contacts-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: #0d0d0d;
    color: #7a7a7a;
    border-radius: 8px;
    flex-grow: 1;
    padding-top: 20px;
}

.contacts-container .contacts-header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 0px 10px;
}

.contacts-container .contacts-header .contact-header-content {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    padding-left: 20px;
}

.contacts-container .contacts-body {
    display: flex;
    flex: 7;
    flex-direction: column;
}

.contacts-container .contacts-body .contacts {
    height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.contacts-container .contacts-body .no-contacts {
    display: flex;
    flex-grow: 1;
    justify-content: center;
}