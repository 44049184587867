.toolbar {
    /* background-color: black; */
    box-sizing: border-box;
    z-index: 90;
    /* border-bottom: 2px solid #333333; */
    background-color:transparent;
}

.toolbar nav {
    height: 100%;
}