.paymentContainer {
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
.servicemainContainer {
  /* width: 100%; */
  width: 480px;

  background: #4658d4 !important;
  padding-top: 20px;
  padding-left: 20px;
  font-family: Helvetica;
  height: calc(100vh - 10px);
  overflow: hidden;
  overflow-y: scroll;
}
.flex {
  height: 100% !important;
}

.inlineFlex {
  display: inline-flex;
}
.floatRight {
  float: right !important;
}
.EditButton {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  background: transparent;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-left: 15px;
  /* height: 38px; */
  /* width: 55px; */
}
.EditButton:hover {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  background: transparent;
}
.notiIcon {
  width: 24px;
}
.profileImages {
  margin-right: 10px;
  height: 75px;
  width: 75px;

  border-radius: 50px;
}
.padd10 {
  padding: 10px;
}

.name {
  font-weight: normal;
  font-size: 25px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  margin: 0px;
  margin-top: 20px;
}
.mTop10 {
  margin-top: 10px;
}
.w100 {
  width: 100%;
}
.textCenter {
  text-align: center !important;
}
.certificate {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-left: 10px;
  color: #9fdbe7;
}
.starIcon {
  width: 100px;
  height: 20px;
}

.bottomBorder {
  padding-bottom: 20px;
  border-bottom: 1px solid #4ca9b7;
}
.latestActivity {
  font-weight: bold;
  color: #ffffff;
  opacity: 0.6;
  margin: 0px !important;
  font-size: 15px;
  margin-top: 20px;
}
.activityDiv {
  width: 100%;
  height: 55px;
  left: 1481px;
  top: 430px;
  margin-top: 10px;
  background: #6dc5cc;
  border-radius: 10px;
}
.AcivityImg {
  height: 30px;
  width: 30px;
}
.acivityName {
  font-size: 14px;
  color: #ffffff;
  margin: 0px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
}
.acivityWork {
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  margin: 0px;
  opacity: 0.7;
}
.acivityIconDisplay {
  width: 35px;
  height: 35px;
  /* margin-right: 10px; */
  background: #ffffff;
  border-radius: 8px;
  float: left;
  padding: 5px;
}
.AcivityIcon {
  width: 100%;
}
.w90 {
  width: 95%;
  margin-left: 5px;
  margin-top: -3px;
}
.padd10 {
  padding: 10px;
}
.viewAllButton {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  background: transparent;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  min-width: 100px;
  margin-top: 15px !important;
  /* width: 55px; */
}
.viewAllButton:hover {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  background: transparent;
}
.mTop60 {
  margin-top: 60px !important;
}
.dociconClassCard2 {
  height: 15px;
  width: 15px;
  margin-top: 5px;
}

.notificationTitle {
  font-weight: normal;
  font-size: 25px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 15px;
}
.mylatestfeed {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.6;
}
.bluerec {
  width: 100%;
  height: 61px;
  background: #4ca9b7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.bluesideimg {
  width: 40px;
  height: 40px;
  border-radius: 86px;
  margin-left: 10px;
}
.beverlbluesid {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  padding-left: 20px;
}
.bluesreqn {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  opacity: 0.7;
  padding-left: 20px;
}
.bluesideda {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: right;
  color: #ffffff;
  opacity: 0.7;
  /* padding-left: 179px; */
  padding-top: 12px;
}
.serchprov {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  text-align: center;
  color: #ffffff;
  padding-top: 30px;
}
.searchprovin {
  width: 100%;
  height: 49px;
  background: #6975de;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #ffffff;
  padding-left: 10px;
  margin-top: 14px;
}
.servfferdsi {
  width: 100%;
  height: 49px;
  background: #6975de;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding-top: 7px;
  margin-top: 12px;
}
.seatbluesi {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  background: transparent;
  margin-top: 13px;
  min-width: 100px;
  padding: 10px;
}
.vblsuereqre {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  text-align: center;
  color: #ffffff;
}
.requesrecrec {
  width: 100%;
  height: 58px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.sssdecfb {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #060f21;
  padding-left: 20px;
}
.recdivjkhfd {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #4ca9b7;
  padding-left: 20px;
}
.bluewarded {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: right;
  text-transform: capitalize;

  color: #6c6c6c;
}
.divmyplaces {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  text-align: center;
  color: #ffffff;
}
.bldspdreqdiv {
  width: 100%;
  height: 58px;
  background: #4ca9b7;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}
.bldaytcf {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #eeeeee;
  padding-left: 20px;
}
.bludknjke {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  padding-left: 20px;
}
.bplaceded {
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 16px;
display: flex;
align-items: center;
text-align: right;
color: #FFFFFF;
text-transform: capitalize;


}
.centerNameBox{
  width: 290px;
}


.profilemultipleselect{
  margin-top: 10px;
}
.profilemultipleselect .p-multiselect{
  border: 1px solid #FFFFFF !important;
  width: 430px;
}
.profilemultipleselect .p-multiselect .p-multiselect-label-container{
  background: #6975de;
  height: 47px;
  padding-top: 6px;
  width: 100% !important;
  opacity: 0.8 !important;
  text-align: left;
  padding-left: 10px;
  color: #FFFFFF;
}