.scrollMessengerView {
  height: calc(100vh - 80px);
  margin-top: 0px;
  overflow: hidden;
}
.messenger {
  display: grid;
  display: inline-flex;
  width: 100vw;
  height: calc(100vh - 80px);
  padding-left: 15px;
}

.container {
  padding: 10px;
}

.scrollable {
  /* position: relative; */
  overflow: hidden;
  /* overflow-y: scroll; */
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  min-width: 384px;
  background: #ffffff;
  border-radius: 10px;
  margin: 10px;
  /* opacity: 0.95; */
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  /* opacity: 0.5; */
  /* background: black;
  grid-row-start: 1; */
  /* grid-row-end: span 3; */
}

.content {
  /* display: inline-flex; */
  margin-top: 10px;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  /* background: black;
  grid-row-start: 1;
  grid-row-end: span 3; */
}

.content .screen-container {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.content .compose {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.footer {
  grid-column-start: 2;
  background: #fff;
}

@media only screen and (min-width: 301px) and (max-width: 500px) {
  .content {
    width: 500px;
    padding: 10px;
  }
  .messenger {
    width: 500px;
    overflow: hidden;
    overflow-x: scroll;
  }
}

.messengerBack {
  background: transparent;
  border: none;
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  color: #c4c4c4;
  text-transform: uppercase;
  padding: 0px;
}
.messengerBack div {
  width: 24px;
  height: 24px;
  background: #4453d6;
  border-radius: 4px;
  text-align: center;
  margin-right: 5px;
}
.messengerBack span {
  font-weight: bold;
  margin-left: 5px;
}
.messengerBack img {
  width: 15px;
}
.messengerBack:hover,
.messengerBack:focus {
  background: transparent;
  border: none;
}
.qrcodeContainerBox{
  width: 100vw;
  color: rgb(255, 255, 255);
  align-items: center;
  height: calc(100vh - 150px);
  vertical-align: middle;
  display: flex;
}
.qrCodeScannerBox {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100vw
}
.qrCodeScannerTextBox {
  color: #ffffff;
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  padding-right: 50px;
}
.qrCodeScannerTitle {
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;

}
.qrCodeScannerStepText {
  color: #ffffff;
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  padding-left: 20px;
  line-height: 33px;
}
.qrScannerSubmit {
  background: #4453d6 !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border: none;
  margin: 10px;
}

@media only screen and (min-width: 1920px) {
  .messenger {
    /* display: grid; */
    width: calc(100vw - 350px);
    margin-left: 320px;
    height: calc(100vh - 100px);
    margin-top: 20px;
    padding-left: 0px !important;
  }
  .messengerBack {
    margin-top: 10px;
  }
  .scrollMessengerView {
    height: calc(100vh - 80px);
    /* margin-top: 0px !important; */
  }
  .content {
    margin-left: 26px;
  }
  .qrcodeContainerBox{
    /* width: calc(100vw - 294px); */
    padding-left: 300px;
   
  }
  .qrCodeScannerBox{
    width: calc(100vw - 300px);
  }
}
