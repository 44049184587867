.EditbusinessTopContainer{
  display: inline-flex;
}
.editBusinessContainer {
  height: calc(100vh - 87px);
  width: calc(100vw - 20px);
  background: #ffffff;
  padding-left: 10px;
  padding-bottom: 100px;
  overflow-y: auto;
  margin-left: 10px;
  max-width: 1086px;
}
.editBusinessTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 65px;

  color: #333333;
}
.primaryBusinessSubTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  padding-left: 3px;
  color: #17171D;
}
.EditBusinessBoxRow{
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  min-width: 1086px;
}
.businessRightSideProfileBox{
  display: inline-flex;
  min-width: 500px;
  margin-left: 50px;
}
.businessRightSideProfileBox .profileImageDiv{
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 86px;
  margin-right: 20px;
  /* border: 4px solid #4453D6; */
  position: relative;
}
.businessRightSideProfileBox .profileImageDiv::before{
  position: absolute;
  content:'';

  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 86px;
  margin-right: 20px;
  border: 4px solid #4453D6;
}
.businessRightSideProfileBox .displayProfilePicTitle{
  font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;

color: #333333;
}
.businessRightSideProfileBox .uploadFileTitle{
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  color: #333333;

  opacity: 0.8;
}
.businessRightSideProfileBox .imguseruploadButton {
  width: 95px;
  height: 32px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #17171d;
  border: 2px solid #333333;
  border-radius: 30px;
  cursor: pointer;
  line-height: 30px;
  margin-top: 10px;
}
.businessFieldsLabel{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #333333;
  padding-left: 10px;
}
.inlineEditBoxes{
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  min-width: 1040px;
  margin-top: 20px;
}
.editBusinessNameInput{

  background: #333333 !important;
  border-radius: 30px !important;
  height: 49px;
  width: 508px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  padding-left: 20px;

}
.editBusinessOtherInputs{

  background: #333333 !important;
  border-radius: 30px !important;
  height: 49px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  padding-left: 20px;

}


/* .editBusinessdropdown > div{
  background: #333333  !important;
  color: #FFFFFF !important;
  border-radius:30px;
  border: none !important;

} */
.editBusinessYearDropdown {
  border-radius: 30px;
  outline: none;
  height: 49px;
  width: 133px !important;
  padding: 15px !important;
  box-shadow: none !important;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  background: #333333;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  opacity: 1 !important;
}
.editBusinessYearContainerDropdown {
  color: #17171d;
  background: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 49px;
  width: 133px !important;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.editBusinessYearContainerDropdown .selectedDropdownItem{
  background: #4453d6;
}

.saveChangesBusinessButton{
  height: 49px;
  width: 234px;
  border-radius: 30px !important;
  border: none !important;
  font-family: Segoe;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF !important;
  background: #4453D6 !important;

}


.editBusinessProfilebackbutton {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #666666 !important;
  background: transparent !important;
  border: none;
  margin-top: 0px;
}
.editBusinessProfilebackbutton div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.editBusinessProfilebackbutton span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
}
.editBusinessProfilebackbutton img {
  width: 15px;
}

.businessMemberPannel{
  background: #333333;
  border-radius: 10px;
  min-height: 100px;
  margin-top: 20px;
}
.businessMemberPannel_capsule{
  background: #4453D6;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  color: #FFFFFF;
  border-radius: 0px 30px 30px 0px;
  height: 44px;
  width: 209px;
  padding-left: 20px;
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
}
.businessSearchMemberlabel{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;

  color: #FFFFFF;
}
.businessmemberSearchbox{
  position: relative;
  border-radius: 30px;
  height: 47px;
  width: 337px;
  /* height: 47px;
  width: 337px;
  display: inline-flex;
  padding-left: 15px;
  padding-right: 10px; */
  z-index: 1;
  overflow: hidden;
  display: inline-flex;
  margin-top: 5px;
  


}
.businessmemberSearchbox::before{
  position: absolute;
  content: '';
  z-index: -1;
  background: #17171D !important;
  opacity: 0.5;
  border-radius: 30px;
  height: 47px;
  width: 337px;
  display: inline-flex;
  padding-left: 15px;
  padding-right: 10px;
}
.businessmemberSearchbox .inputSearchBox{
    width: 100%;
    height: 100%;
    background: transparent;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    font-family: Segoe;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF !important;
    padding-left: 10px;
    padding-right: 10px;

}

.EditbusinessMemberListing{
  width:465px;
}
.EditbusinessMemberListingHeader{
  display: inline-flex;
  justify-content: space-between;
  margin-left: 5px;
  padding-left: 15px;
  padding-right: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  align-items: center;
}
.editBusinessMemberAction{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #FFFFFF;
  display: inline-grid;
  line-height: 15px;
}
.editBusinessactionButtons{
  background: transparent !important;
  border: none !important;
  padding: 0px;
}
.editBusinessMemberInfoBox{
  width: 365px;
  display: inline-flex;
  padding: 5px;
  align-items: center;
}
.memberProfileImgDiv{
  position: relative;
  z-index:1;
  height: 50px;
  width: 50px;
  border-radius: 86px;
  overflow: hidden;
  margin-right: 20px;
  margin-top: 3px;
}
.memberProfileImgDiv::before{
  position: absolute;
  /* z-index:-1; */
  content:'';
  height: 50px;
  width: 50px;
  border-radius: 86px;
  overflow: hidden;
  border: 2px solid #FFFFFF;


}
.memberProfileNameInfo{
  max-width:285px;
}
.memberProfileNameInfo .userFullname{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */


  color: #FFFFFF;
}
.memberProfileNameInfo .userProfessionData{
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #FFFFFF;

  opacity: 0.7;
}
.memberProfileNameInfo .userPositionData{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #FFFFFF;

  opacity: 0.7;
}


.businessMemberAddConfirmationModal{
  background: #4453D6;
  border-radius: 30px;
  padding: 23px;
}
.businessMemberAddConfirmationModal .confirmmsg{
  
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.8;
}
.businessMemberAddConfirmationModal .confirmTitle{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  
}
.businessMemberAddConfirmationModal .buttons{
  min-width: 100px;
  height: 40px;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  border-radius: 30px;
 
  
  border-radius: 30px;
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  color: #FFFFFF !important;
  border: 0px;

}
.businessMemberAddConfirmationModal .noButton{
  background: transparent !important;
  color: #FFFFFF !important;
  border: 1px solid #FFFFFF;

}
.businessMemberAddConfirmationModal .yesButton{
  background: #17171D;
  /* border-radius: 30px; */
  margin-left: 10px;
}
.businessMemberAddConfirmationModal .buttonDiv{
  display: inline-flex;
  float: right;
}



@media only screen and (max-width: 1919px) and (min-width: 1025px){

.editBusinessContainer {
    width: calc(100vw - 500px);
}
}
@media only screen and  (min-width: 1920px){

  .editBusinessContainer {
      width: calc(100vw - 500px);
      margin-left: 330px;
  }
  .EditbusinessTopContainer{
    position: absolute;
    width: 100vw;
    top: 20px;
  }
  }
