.mainview {
  /* background: #333333; */
  border-radius: 10px;
  width: max-content;
  /* height: 53px; */
  margin-top: 16px;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}
.notificationheading {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: #ffffff;
}
.notificationIcons {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
}
.notificationtitle {
  color: #ffffff;
  min-width: 130px;
  max-width: 140px;
  font-style: normal;
  padding-top: 5px;
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.notificationdate {
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  min-width: 100px;
  max-width: 120px;
  color: #ffffff;
  padding-top: 5px;
}
.confirm {
  color: #ffffff;
  min-width: 200px;
  max-width: 300px;
  margin-left: 20px;
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  padding-top: 5px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
.notificationmsg {
  align-items: center;

  min-width: 300px;
  max-width: 400px;
  margin-left: 20px;
  padding-top: 5px;

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  align-items: center;

  color: #c4c4c4;
}
.closeButton {
  background: transparent !important; 
  border: none !important;
  padding-right: 0px;
  z-index: 20 !important;
}
.closeButton img {
  height: 25px;
  margin-bottom: 13px;
}
.closeButton:focus,
.closeButton:active,
.closeButton:hover {
  background: transparent;
  border: none;
  padding-right: 0px;
}
.mainNotificationView {
  /* My Notifications */

  /* margin: 50px; */
  /* padding: 30px; */
  /* width: calc(100vw - 10px); */
  /* height: calc(100vh - 150px);
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto; */
  padding-left: 20px;
}

.unread-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #24a2a8;
  float: right;
  margin-top: 10px;
}
