.sersidefirres {
  width: 480px;
  height: calc(100vh - 10px);
  background: #4ca9b7;
  overflow-y: auto;
  padding-bottom: 20px;
}
.sersidebut {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  background: transparent;
}
.sersiduimg {
  width: 102px;
  height: 102px;
  border-radius: 86px;
}
.sersidJohnDo {
  /* Dr. John Doe Albert */
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  text-align: center;
  color: #ffffff;
  padding-top: 25px;
}
.sersidsecondro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 34px;
}
.sersidrtified {
  /* Certified Neurologists */
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #9fdbe7;
  padding-top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sersidratst {
  width: 170px;
  height: 29.79px;
  margin-top: 34px;
}
.sersidlin {
  width: 361px;
  height: 0px;
  border: 1px solid #9fdbe7;
  margin-top: 33px;
  margin-left: 60px;
}
.sersidmypl {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  text-align: center;
  color: #ffffff;
  padding-top: 20px;
}
.sersidmyreqre {
  width: 400px;
  height: 58px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}
.sersidddare {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #060f21;
  text-align: left;
  padding-left: 10px
  /* padding-right: 121px; */
}
.sersidseij {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  padding-left: 10px;
  text-align: left;
}
.sersidawadr {
  /* padding-left: 50px; */
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #6c6c6c;
  padding-right: 20px;
}
.sersideview {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  background-color: transparent;
  margin-top: 14px;
  float: right;
  margin-right: 39px;
}
.sersidchProv {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  text-align: center;
  color: #ffffff;
  padding-top: 70px;
}
.sersidinpu {
  width: 400px;
  height: 49px;
  background: #70bac5;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #FFFFFF !important;
  margin-top: 14px;
  padding-left: 10px;
}
.sersidedrop {
  width: 400px;
  height: 49px;
  background: #70bac5;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 12px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #ffffff;
}
.sersidebutsea {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  background: transparent;
  margin-top: 14px;
  margin-right: 39px;
}
.sersidemyuESTF {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.6;
  padding-top: 30px;
  padding-left: 45px;
}
.sersidefeed {
  width: 400px;
  height: 61px;
  background: #4453d6;
  border-radius: 10px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sersidefeedimg {
  width: 40px;
  height: 40px;
  border-radius: 86px;
}
.sersideeve {
  /* Beverly Walsh */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  padding-left: 15px;
}
.sersidereqw {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  opacity: 0.7;
}
.sersidemyda {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: right;
  color: #ffffff;
  opacity: 0.7;
}
.sersidemyst {
  width: 145px;
  height: 25.41px;
}

.multipleselect{
  margin-top: 10px;
}
.multipleselect .p-multiselect{
  border: 2px solid #FFFFFF !important;
  margin-left: 10px;
}
.multipleselect .p-multiselect .p-multiselect-label-container{
  background: #70bac5;
  height: 47px;
  padding-top: 6px;
  width: 400px !important;
  opacity: 0.8 !important;
  text-align: left;
  padding-left: 10px;
 color: #FFFFFF;
}