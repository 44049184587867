.accountList{
    /* background: #4453D6 !important; */
    font-style: normal;
    font-weight: normal;
    font-family: "Segoe";
    font-size: 16px;
    color: #FFFFFF !important;
    border-bottom: 1px solid #FFFFFF;
}
.bankListTitle{
    color: #FFFFFF ;
    font-family: "Segoe";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
}
.bankListContainer{
    /* width: calc(100vw - 100px); */
    padding: 50px;
    overflow: hidden;
    height: calc(100vh - 80px);
    overflow-y: auto;
    margin-top: 80px;
}
.titleText{
    color: #FFFFFF ;
    font-family: "Segoe";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.submitWithdrawButton {
    width: 156.76px;
    height: 40px;
    background: #4453d6 !important;
    color: #ffffff !important;
    text-align: center;
    border-radius: 30px;
    margin-right: 35px;
  
    font-family: 'Segoe';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-transform: capitalize;
    border: none !important;
  }

@media only  screen and (min-width: 1920px){

  .bankListContainer{
        padding-left: 400px;
    }
}