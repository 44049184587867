.ordermaninContainer {
  /* font-family: Helvetica; */

  display: flex;
  flex-direction: row;
  /* flex-grow: 1; */
  background: #ffffff;
}
.ordersprofileContent {
  /* display: flex; */
  /* flex: 6 1; */
  /* flex-direction: column; */
  width: calc(100vw - 10px);
}
.ordersProfilebackbutton {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #666666;
  background: transparent;
  border: none;
  margin-top: -50px;
}
.ordersProfilebackbutton:hover {
  background: transparent;
  color: #666666;
}
.ordersProfilebackbutton div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.ordersProfilebackbutton span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
}
.ordersProfilebackbutton img {
  width: 15px;
}
.ordersinnerContainer {
  margin-left: 50px;
  /* height: calc(100vh - 100px); */
  /* overflow: hidden; */
  /* overflow-y: scroll; */
  /* overflow: auto; */
}
.orderprofileImage {
  border: 5px solid #4453d6;
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border-radius: 86px;
}
.searchahndle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #17171d;
  padding-left: 20px;
}
.johndohds {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  color: #333333;
  padding-left: 20px;
}

.ordersworkplace {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 5px;
  margin-left: 20px;
}
.ordersworkplace img {
  width: 25px;
}
.orderuniverfr {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #4453d6;
  margin-bottom: 5px;
  margin-left: 20px;
}
.orderuniverfr img {
  width: 25px;
  margin-right: 5px;
}
.editproorderbutton {
  background: #4453d6;
  border-radius: 30px;
  width: 195px;
  height: 49px;
  border: none;
}
.returntoprofile {
  width: 138px;
  height: 32px;
  background: transparent;
  border: 2px solid;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #17171d;
  border-radius: 20px;
  line-height: 10px;
  margin-left: 820px;
}
.reputationbox {
  width: 959px;
  height: 513px;
  background: #333333;
  border-radius: 10px;
  margin-top: 13px;
}

.ordersreputationHeading {
  width: 158px;
  height: 44px;
  background: #4453d6;
  border-radius: 0px 30px 30px 0px;
  font-family: Segoe;
  font-style: normal;
  /* font-weight: bold; */
  font-size: 16px;
  line-height: 22px;
  padding: 11px;
  padding-left: 25px;
  color: #ffffff;
  margin-top: 16px;
}
.ordersreputationHeading span{
  font-weight: bold;
}
.ordertopjvgsjdfsy {
  width: 78px;
  height: 25px;
  border: none;
  border-radius: 30px;
  margin-top: 30px;

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-left: 12px;
  text-align: center;

  color: #17171d;
  background: #f5d028;
  opacity: 0.8;
  line-height: 7px;
}

.reviews4gj {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.6;
}
.ratingjhcgsd {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  text-align: center;
  color: #ffffff;
  padding-right: 19px;
}
.detailstabc {
  width: 811px;
  height: 400px;
  margin: 0 auto;
}
.OrdersComple {
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #17171d;
  background: rgba(245, 208, 40, 0.2);
  border-radius: 30px;
  width: 255px;
  height: 44px;
  border: none;
  /*ative color #F5D028 */
}
.dividerfjvk {
  opacity: 0.5;
  border: 3px dashed #ffffff;
  margin-top: 22px;
}
.laurenwalchkyc {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin: 0px;
  padding-left: 12px;
}
.orderdatesjsabcdJ {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.5;
  padding-left: 12px;
}

.ugksdsdsf {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  color: #feffff;
  opacity: 0.5;
  margin: 0px;
}
.Goodbuyer {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.activebuttonclassk {
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #17171d;
  background: #f5d028;
  border-radius: 30px;
  width: 255px;
  height: 44px;
  border: none;
}
.orderslocaltion {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 5px;
  margin-left: 20px;
}
.orderslocaltion img {
  width: 25px;
}
.activebuttonclassk:hover{
    background: #f5d028;
    color: #17171d;
} 
.OrdersComple:hover{
    background: rgba(245, 208, 40, 0.2);
    color: #17171d;
}
.returntoprofile:hover{
    background: transparent;
    color: #17171d;
}

@media only  screen and (min-width: 1920px){
  .ordersprofileContent{
    width: calc(100vw - 800px);
    margin-left: 300px;
    margin-top: 50px;
  }
}