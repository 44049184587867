.group-details-container {
    display: flex;
    width: 30vw;
    max-width: 350px;
    min-width: 200px;
    height: 10vh;
    max-height: 500px;
    flex-direction: column;
    flex-grow: 1;
}

.group-details-container .group-details-header {
    display: flex;
    color: white;
    align-self: center;
    flex: 1;
    font-weight: 800;
}

.group-details-container .group-details-body {
    display: flex;
    flex: 5;
    flex-direction: column;
}

.group-details-container .group-details-body .group-info-container {
    display: flex;
    flex: 2;
    flex-direction: row;
}

.group-details-container .group-details-body .group-info-container .group-detail-image-container {
    display: flex;
    flex: 4;
    flex-direction: column;
    justify-content: center;
}

.group-detail-image-container img {
    border-radius: 4vw;
    height: 8vw;
    width: 8vw;
    min-height: 50px;
    min-width: 50px;
    align-self: center;
}

.group-details-container .group-details-body .group-info-container .group-image-info{
    display: flex;
    flex: 6;
    flex-direction: column;
}

.group-details-container .group-details-body .group-info-container .group-image-info div {
    padding: 3px 0px;
}

.group-detail-picture-name {
    color: white;
    font-weight: 800;
    font-size: 0.9rem;
}

.group-image-detail {
    font-style: italic;
    font-size: 0.8rem;
    color: #707070;
}

.group-image-upload-button {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    background-color: #4453d6 !important;
    font-weight: bold;
    border-radius: 20px;
    text-decoration: none;
    margin-top: 1px;
    width: 95px;
    height: 32px;
    
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    border: none !important
}

.group-details-container .group-details-body .group-name-container {
    display: flex;
    flex: 3;
    padding: 15px 0px;
    flex-direction: column;
    margin-top: 17px;
}

.group-details-container .group-details-body .group-name-container input {
    padding: 0px 15px;
}

.group-details-container .group-details-body .group-name-container .footer-text {
    color: white;
    font-size: 0.7rem;
    padding: 5px 0px;
}

.group-details-container .group-details-footer {
    flex: 1;
    margin: 0 auto;
}
.firsttext{
    /* Provide a group subject and group icon */


font-style: normal;
font-weight: normal;
font-size: 18px;


color: #FFFFFF;



}
.grouppic{
/* Group Picture* */


font-style: normal;
font-weight: 500;
font-size: 14px;


color: #FFFFFF;


}
.picturewar{


font-weight: normal;
font-size: 12px;
font-style: italic;

color: #FFFFFF;

opacity: 0.5;

}
.submitbutton{
    background: linear-gradient(180deg, #439FC8 0%, #4358A7 100%);
border-radius: 5px;
width: 300px;
margin-top: 185px;
}
.inputdiv{
    margin-top: 17px; 
    width: 310px;
}
.colwidth{
width: 400px;
}
.buttondiv{
    margin-top: 200px;
    padding-left: 68px;
}
.footer-text{
    font-size: 12px;
    color: #A5A5A5;
    font-style: italic;
}

.maxNameMessage{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    color: #FFFFFF;
    opacity: 0.5;
    padding-left: 80px;
}
.groupIconMessage{
    font-family: Segoe;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    color: #FFFFFF;
    opacity: 0.5;
    width: 200px;
    margin-top: 10px;
}

.groupIconTitle{
    font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
display: inline;
color: #FFFFFF;
}
.EditGroupImageSection{
    display: inline-flex;
    justify-content: space-between;
}
.editGroupIConImg{
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 100px;
    width: 95px;
    height: 95px;

}

.SaveChangesButton {
    border-radius: 30px;
    width: 158px;
    height: 37px;
    background: #4453d6 !important;
    border: none;
    margin-left: 15px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;

    color: #FFFFFF !important;

  }
