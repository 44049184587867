
    .mobileLoginmaindiv{
        width: calc(100vw - 100px);
        margin-top: 27px;
        min-width: 260px;
        overflow: hidden;
    }

    .WELCOMEloginTitle{
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
    }

    .loginInputFields{
        margin-top: 15px;
    }
    .loginInputFields .inputLabel{
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        align-items: center;
        color: #FFFFFF;
    }

    .loginInputFields .loginInput{
        background: rgba(0, 0, 0, 0.5);
        border-radius: 30px;
        width: 100%;
        padding-left: 15px;
        color: #FFFFFF;
        font-family: Segoe UI;
        height: 45px;
        border: none !important;
        margin-top: 5px;
    }

    .mobileLoginNext{
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        align-items: center;
        text-align: center;
        color: #FFFFFF !important;
        width: 100%;
        background: #4453D6 !important;
        border-radius: 30px;
        border: none !important;
        height: 40px;
    }

    .eyeIconButton{
        background: transparent !important;
        padding: 0px !important;
        border: none !important;
        position: relative;
        top: -35px;
        right: 10px;

    }
    .eyeIconButton img{
        width: 30px;
    }
    .forgotPasswodButton{
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #FFFFFF;
        opacity: 0.8;
    }
    .forgotPasswodButton a{
        color: #FFFFFF !important;
        text-decoration: none !important;

    }

    .mobilePasswordless {
        width: 100% !important;
        height: 42px;
        background: #17171dcc !important;
        border-radius: 30px;
        font-family: Segoe;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        color: #ffffff !important;
        margin-top: 14px;
        border: none !important;
        cursor: pointer;
      }