.my-modal {
    background-color: #2a2e43;
    border-radius: 8px;
}

.my-modal .my-title {
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
}

.cancel-button {
    background-color: #6a6d7b;
}

.submit-button {
    background: #4453d6
}

.footer-buttons{
    color: white;
    text-align: center;
    font-weight: bold;
    max-width: 100px;
}