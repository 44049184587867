

.appsbackbutton {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #FFFFFF;
  background: transparent;
  border: none;
  margin-top: 0px;
}
.appsbackbutton:hover {
  background: transparent;
  color: #FFFFFF;
}
.appsbackbutton div {
  width: 25px;
  height: 25px;
  background: #FFFFFF;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.appsbackbutton span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  color: #FFFFFF;
}
.appsbackbutton img {
  width: 15px;
}
.playerCss{
  margin-top: 20px;
  width: 700px !important;
  height: 300px !important;
  background: #aaaaaa;
}

