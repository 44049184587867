.navigation-item {
    height: 70px;
    display: flex;
    align-items: center;
    padding: 16px 0px 16px 32px;
}

.navigation-item .row{
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: initial;
    margin-left: initial;
}

.marketplacesubmenuItem{
    padding: 16px 0px 16px 32px;
}

.marketplacesubmenuItem .image-container{
    display: none;
}

.navigation-item .image-container{
    display: flex;
    align-items: center;
    padding-left: 0;
}

.navigation-item img{
    vertical-align: initial;
}


.image-container.col-2{
    padding: 0;
    margin: 0 !important;
    margin-right: 28px !important;
}

.navigation-item .col-8{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
}

.dropDownIcon{
    margin-left: auto;
    opacity: 1;
    justify-self: flex-end;
}

.dropDownWrapperMenu{
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}

.navigation-item a , .marketplacesubmenuItem a {
    text-decoration: none;
    font-family: 'Segoe';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
}

.navigation-item .image-container {
    margin: 0 auto;
    text-align: center;
}
.marketplacesubmenuItemView{
    overflow:hidden;

}
.marketplacesubmenuItem{
    line-height: 55px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    opacity: 0.5;
    height: 57px;
}
.marketplacesubmenuItem a:hover{
    text-decoration: none; 
}
.activebackground{
    background-color: #4658d4;
}