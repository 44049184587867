.mailContainerScrolller{
    height: calc(100vh - 88px);
    z-index: 1;
}
.scrollbar-container{
    height: calc(100vh - 150px) !important;
}

@media only screen and (min-width: 1920px){
    .emailClientMainContainer {
        padding-left: 300px !important;
        width: calc(100vw - 300px) !important;
        height: calc(100vh - 10px) !important;
    }
}
.emailClientMainContainer{
    width: 100vw !important;
    padding-left: 10px;
}
