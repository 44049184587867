.message-list{
  background: #17171D80;
  border-radius: 10px;
  /* position: relative; */
  height: calc(100vh - 195px);
  /* min-height: calc(100vh - 220px);
  max-height: calc(100vh - 400px); */
  overflow: hidden;
  padding: 10px;

}

.goDownScrollGroupChatButton{
  position: fixed;
  left: calc(100vw - 220px);
  bottom: 150px;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background: #FFFFFF !important;
  border: none !important;
  padding: 0px;
}
@media only screen and (min-width: 400px) and (max-width: 1025px) {
.goDownScrollGroupChatButton {
  left: calc(100vw - 100px);
}


}

@media only  screen and (min-width: 1920px){
  .message-list{
    height: calc(100vh - 215px)  ;
    overflow: hidden;

  
  }
  .message-list-container {
    height: calc(100vh - 335px) !important;
    overflow-y: auto;

  
  }
  .goDownScrollGroupChatButton {
    left: calc(100vw - 250px);
    bottom: 200px;
  }
  
  
}

.message-list-container {
  padding: 10px;
  height: calc(100vh - 280px);
  /* scroll-behavior: smooth; */
  /* height: 475px; */
  /* min-height: calc(100vh - 300px);
  max-height: calc(100vh - 400px); */
  overflow-y: auto;
  z-index: 10;

  /* min-height: calc(100vh - 200px);
  max-height: calc(100vh - 250px); */

}

.message-list-container::-webkit-scrollbar-track {
  background: #17171D60;
  /* opacity: 0.2; */
  border-radius: 20px;
}
.message-list-container::-webkit-scrollbar-thumb {
  background: #4453D6;
  border-radius: 20px;

}

.message-list-container::-webkit-scrollbar {
  width: 10px;
}



.notification-modal {
  max-width: 350px;
}

.message-list-container .top-right-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.message-list-container .top-right-button .crumb-text {
  color: white;
  font-size: 2rem;
  text-align: right;
}

.message-list-container .top-right-button .child {
  background-color: white;
  font-size: 0.9rem;
  min-width: 120px;
  padding: 5px 10px;
}

.notification-option-list {
  padding: 30px 10px 0px 10px;
  margin: 0 auto;
}

.mute-notification-body {
  color: #a0a1ab;
  padding: 20px 10px 0px 10px;
}
.toolbarSticky{
  /* position: sticky; */
  height: 80px;
}
/* 
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #ddd;
    font-weight: bolder;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: transparent;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 18px;
    height: 18px;
    background: #439fc8;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
} */


