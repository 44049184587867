.yousearch {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  color: #4453d6;
}
.BACKTOSEARCH {
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;

  color: #c4c4c4;
  width: 100%;
  text-align: left;
}

.Mar {

  font-style: normal;
  font-weight: 300;
  font-size: 48px;

  color: #45443c;
}
.resultsfor {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #c4c4c4;
}

.Rectangle21 {
  padding: 15px;
  width: 305px;
  height: 211px;

  background: #4453d6;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.JulieMartha {
  /* Ms. Julie Martha */

  font-style: normal;
  font-weight: bold;
  font-size: 20px;

  color: #ffffff;
}

.California {
  /* San Francisco, California */

  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #eef3f7;
}
.roman {
  /* roman-kraft-260082-unsplash */

  width: 70px;
  height: 70px;

  border-radius: 86px;
}
.Average {
  /* Average Rate: */

  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  align-items: center;

  color: #eeeeee;
}
.perhour {
  /* $85 per hour */

  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  align-items: center;

  color: #ffffff;
}

.Rating {
  /* Rating: */

  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  align-items: center;

  color: #eeeeee;
}
.number47 {
  /* 4.7 */

  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  /* identical to box height */

  align-items: center;

  color: #ffffff;
}
.Services {
  /* 21 Services Provided */

  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  /* identical to box height */

  align-items: center;
  text-align: center;

  color: #ffffff;
}
.Rectangle22 {
  padding: 10px;
  width: 300px;
  height: 211px;

  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-right: 20px;
  margin-top: 10px;
}

.Martin {
  /* Dr. Martin James */

  font-style: normal;
  font-weight: bold;
  font-size: 20px;

  color: #45443c;
}
.Angeles {
  /* Los Angeles, California */

  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #6c6c6c;
}
.Average2 {
  /* Average Rate: */

  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #a5a5a5;
}
.per {
  /* $100 per hour */

  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  /* identical to box height */

  align-items: center;

  color: #6c6c6c;
}
.Rating2 {
  /* Rating: */

  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #a5a5a5;
}
.number48 {
  /* 4.1 */

  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  /* identical to box height */

  color: #6c6c6c;
}
.Services2 {

  font-weight: bold;
  font-size: 16px;


  text-align: center;

  color: #6c6c6c;
}

.serviceOnDemandSearchContainer{
    width: calc(100vw - 770px);
    padding-left: 15px;
    padding-top: 15px;
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100vh - 20px);
}
[type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after{
     /* content: ''; */
    /* position: absolute; */
    /* left: 0; */
    /* top: 0; */
    /* width: 0px; */
    /* height: 0px; */
    /* border: 1px solid #ddd; */
    /* border-radius: 100%; */
    /* background: transparent; */
}

.FrameImgIcon {
  width: 290px !important;
  margin-top: 80px;
}
.BoxouterDiv{
  flex-wrap: wrap;
  display: inline-flex
}
