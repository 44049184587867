/* .seetings {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #ffffff;
  }
  .bg {
    background: #4092b7;
    width: 130px;
    height: 115px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
  }
  .accsetting {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    opacity: 0.56;
    padding-top: 10px;
  }
  .bg2 {
    background: #4092b7;
    width: 130px;
    height: 115px;
    border-radius: 10px;
    text-align: center;
    margin-left: 19px;
    cursor: pointer;
  }
  .authsetting {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    opacity: 0.56;
    padding-top: 10px;
  }
  .bg3 {
    background: #4092b7;
    width: 130px;
    height: 115px;
    border-radius: 10px;
    text-align: center;
    margin-left: 19px;
    cursor: pointer;
  }
  .notisetting {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    opacity: 0.56;
    padding-top: 10px;
  }
  .bg4 {
    background: #4092b7;
    width: 130px;
    height: 115px;
    border-radius: 10px;
    text-align: center;
    margin-left: 19px;
    cursor: pointer;
  }
  .searsetting {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    opacity: 0.56;
    padding-top: 10px;
  }
  .bg5 {
    background: #4092b7;
    width: 130px;
    height: 115px;
    border-radius: 10px;
    text-align: center;
    margin-left: 19px;
    cursor: pointer;
  }
  .walletsetting {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    opacity: 0.56;
    padding-top: 10px;
  }
  .bg6 {
    background: #4092b7;
    width: 130px;
    height: 115px;
    border-radius: 10px;
    text-align: center;
    margin-left: 19px;
    cursor: pointer;
  }
  .abapsetting {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    opacity: 0.56;
    padding-top: 10px;
  }
  .contactsupp {
    background: #333333;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    height: 55px;
    width: 340px;
    border: none;
    cursor: pointer;
    margin-top: 19px;
    padding-left: 22px;
    padding-right: 155px;
  }
  .logoutsupp {
    background: #333333;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    height: 55px;
    width: 340px;
    border: none;
    cursor: pointer;
    margin-top: 19px;
    padding-left: 16px;
    padding-right: 218px;
  }
  .settdivider {
    width: 2px;
    height: 777px;
    background: #ffffff;
    opacity: 0.3;
  }
  .accosetsidi {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
  }*/
  .settinproim {
    width: 168px;
    height: 168px;
    border-radius: 86px;
  } 
  .editprobutset {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    background: transparent;
    width: 150px;
    height: 40px;
    margin-left: 48px;
  }
  .acuntsidero {
    display: flex;
    align-items: center;
  }
  .detailsdivset {
    height: 500px;
    background: #333333;
    border-radius: 9px;
    margin-top: 29px;
    padding: 20px;
  }
  .detailsdividser {
    background: #ffffff;
    opacity: 0.2;
    width: 403px;
    height: 1px;
    /* margin-left: 30px; */
    margin-top: 11px;
  }
  .titleserf {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
  }
  .setdetdr {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.6;
  }
  .setfirstname {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    padding-left: 25px;
  }
  .setjohndoe {
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.6;
    padding-left: 25px;
  }
  .lastsetk {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    padding-left: 25px;
  }
  .sefrvrdcvfvb {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.6;
    padding-left: 25px;
  }
  .adddreseserf {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    padding-top: 11px;
  }
  .setaccdresdkm {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.6;
  }
  .editaccsett {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    padding-left: 17px;
  }
  .editproimg {
    width: 120px;
    height: 120px;
    border-radius: 86px;
  }
  .uploadsetscr {
    width: 96px;
    height: 43px;
    background: #4190b7;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: pointer;
    margin-left: 30px;
  }
  .seteditjncd {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    padding-left: 30px;
    color: #ffffff;
  }
  .editproncsdjk {
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.5;
    padding-left: 30px;
  }
  .edirproseim {
    background: #0c0f1d;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: none;
    color: #ffffff;
    width: 150px;
    height: 49px;
    padding-left: 10px;
    margin-top: 11px;
  }
  .editnameset {
    background: #0c0f1d;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: none;
    color: #ffffff;
    width: 150px;
    height: 49px;
    padding-left: 10px;
    margin-top: 11px;
    margin-left: 5px;
  }
  .edilastymeset {
    background: #0c0f1d;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: none;
    color: #ffffff;
    width: 150px;
    height: 49px;
    padding-left: 10px;
    margin-top: 11px;
    margin-left: 5px;
  }
  .editbostonpro {
    background: #0c0f1d;
    border-radius: 10px;
    border: none;
    width: 225px;
    height: 49px;
    margin-top: 11px;
    padding-left: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;
    border: none;
  }
  .editmassatonpro {
      background: #0c0f1d;
      border-radius: 10px;
      border: none;
      width: 115px;
      height: 49px;
      margin-top: 11px;
      padding-left: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #ffffff;
      border: none;
      margin-left: 5px;
    }
    .editnumatonpro {
      background: #0c0f1d;
      border-radius: 10px;
      border: none;
      width: 121px;
      height: 49px;
      margin-top: 11px;
      padding-left: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #ffffff;
      border: none;
      margin-left: 5px;
    }
    .ediatcountronpro {
      background: #0c0f1d;
      border-radius: 10px;
      border: none;
      width: 225px;
      height: 49px;
      margin-top: 11px;
      padding-left: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #ffffff;
      border: none;
      margin-left: 5px;
    }
  .aseboutmeset{
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.5;
  padding-top: 14px;
    }
    .aboutsetinm {
      background: #0c0f1d;
      border-radius: 10px;
      border: none;
      width: 425px;
      height: 49px;
      margin-top: 3px;
      padding-left: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #ffffff;
      border: none;
      /* margin-left: 5px; */
    }
  .setedbubbut{
  background: linear-gradient(180deg, #439FC8 0%, #4358A7 100%);
  border-radius: 5px;
  border: none;
  height: 49px;
  width: 250px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 23px;
  
    }