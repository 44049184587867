// Mixins
@mixin inset {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
// Variables
// Colors
$white: #ffffff;
$light-blue: #24a2a8;
$button-blue: #4453d6;
$black-opacity: rgba(0, 0, 0, 0.5);
$black: #000000;
$black-other: #17171d;
$violet: #571b65;
$purple: #8b2fa0;
$navy: rgba(26, 34, 105, 0.2);
$gold: #f5d028;

// Fonts
$font: Segoe;

#rootContainerBG {
  height: 100%;
  font-family: $font;
}
.container-flex {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  .container-sidebar {
    position: fixed;
    z-index: 1000;
    transition: 0.5s;
  }
  .container-body {
    flex: 5;
    display: flex;
    flex-direction: column;
  }
}
.containerFlexBg {
  position: relative;
  overflow: auto;
}
.topHeaderMenu {
  background: transparent;
  display: flex;
  justify-content: space-between;
  z-index: 2;
}
.topHeaderMenuContainer {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  width: 490px;
}
.rightSideMenu {
  width: 500px;
  display: flex;
  justify-content: space-between;
}
.whiteBG {
  background: $white;
}
.messengerPurple {
  margin: 0 auto;
  padding: 0;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../../../images/icons/background/bluegradientbackground.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 1;
  min-height: 100vh;
}
.rdeePayBlue {
  margin: 0 auto;
  padding: 0;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../../../images/icons/background/rdeePayBack.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 1;
  overflow: auto;
  position: fixed;
  @include inset();
}
.vaultContainerBG {
  margin: 0 auto;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1;
  height: 100vh;
  background: linear-gradient(
    359.86deg,
    $light-blue 0.07%,
    $button-blue 98.68%
  );
}
#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  @include inset();
  background-color: $black-opacity;
  z-index: -1;
  cursor: pointer;
}
.hideoverlay {
  display: none !important;
}
.tranparentBg {
  background: transparent !important;
}
.webmainContainer {
  display: flex;
}
.mainBusinessContainer {
  height: 100%;
}
@media only screen and(min-width: 1401px) and(max-width: 1919px) {
  .webmainContainer {
  }
}
@media only screen and(min-width: 1920px) {
  #overlay {
    display: none;
  }
}
.userActiveStatusCircle {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin: 5px;
  padding-left: 10px;
}
.headerCircle {
  margin-left: 0;
  margin-right: 5px;
}
.statusText {
  font-family: $font;
  font-size: 10px;
  line-height: 22px;
  color: $black;
  cursor: pointer;
  margin-right: 10px;
  overflow: hidden;
  text-align: left;
}
.statusTextLable {
  font-size: 16px;
  color: black;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  display: flex;
  padding-left: 3px;
}
.dropActiveStatusBoxArrow {
  position: absolute;
  top: -8px;
  left: 0;
  // For now as it is blocking dropdown
  display: none;
}
.dropActiveStatusBox {
  display: block;
  position: absolute;
  padding: 20px 0 10px;
  border-radius: 0 0 10px 10px;
  z-index: -10;
  width: 148px;
  top: -15px;
}
.dropActiveStatusBox::before {
  content: '';
  position: absolute;
  @include inset();
  background: $white;
  border-radius: 0 0 10px 10px;
  width: 148px;
  z-index: -10;
}
.boxInline {
  display: inline-flex;
  width: 100%;
  align-items: center;
  padding-left: 10px;
  height: 35px;
}
.marketPlaceRightSidePurple {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.marketPlaceRightSidePurple::before {
  content: '';
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, $button-blue 0, $violet 100%);
  z-index: -1;
  width: 490px;
}
.marketPlaceSellerbg::before {
  content: '';
  position: fixed;
  @include inset();
  background: linear-gradient(127.69deg, $button-blue 17.04%, $purple 78.37%);
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -10000;
}
.cancelButtonUserStatus {
  background: none !important;
  border: none !important;
  font-size: 16px;
  line-height: 22px;
  color: $black !important;
}
.rightHeaderMenu {
  min-width: 200px;
  text-align: right;
}
.rightSideIcons {
  text-align: right;
  display: inline-flex;
  align-items: center;
  a {
    margin-right: 7px;
  }
}
.messageIconCircle {
  background: $black-other;
  box-shadow: 0 10px 18px $navy;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  padding-top: 15px;
  text-align: center;
  cursor: pointer;
}
.messengerIcon {
  height: 20px !important;
  width: 20px !important;
  border: none !important;
  border-radius: 0 !important;
  vertical-align: initial;
}
.yellowCircle {
  background: $gold;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  position: relative;
  float: right;
  top: 5px;
}
.downMenuContainer {
  background: $black-other;
  border-radius: 50px;
  color: $white !important;
  width: 148px;
  height: 100%;
  display: flex;
  align-items: center;
  .statusText {
    color: $white !important;
  }
  svg {
    color: $white !important;
  }
}
.closePopoverCircle {
  background: $button-blue;
  box-shadow: 0 10px 18px $navy;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  padding-top: 15px;
  text-align: center;
  cursor: pointer;
  img {
    width: 35px;
    position: relative;
    bottom: 7px;
  }
}
.noConnectionModal {
  width: 350px;
  min-height: 350px;
  background: $button-blue;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}
.noConnectionText {
  font-size: 30px;
  line-height: 22px;
  color: $white;
  cursor: pointer;
  text-align: center;
}
.topHeaderContianerBox {
  padding: 25px 27px 0;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
@media only screen and(min-width: 300px) and(max-width: 725px) {
  .topHeaderContianerBox {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-direction: row-reverse;
  }
  .topHeaderMenuContainer {
    width: max-content;
  }
  .rightHeaderMenu {
    padding-right: 0 !important;
    min-width: max-content !important;
  }
  .statusText {
    display: none;
  }
  .downMenuContainer {
    background: transparent;
    min-width: max-content;
    position: relative;
    right: 15px;
  }
  .profileboxImageContainer {
    margin-right: 0;
  }
  .dropActiveStatusBox {
    right: -8px;
  }
  .rightSideIcons a {
    margin-right: 0;
  }
}

.react-tiny-popover-container {
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0.5;
  transition: opacity 0.35s ease 0s;
}
