.SERVICEPROVIDER {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #4453d6;
}
.GOBACK {
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  color: #c4c4c4;
}
.Martha {
  font-style: normal;
  font-weight: 300;
  font-size: 48px;

  color: #45443c;
}
.Co-Founder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #c4c4c4;
}
.Everus {
 

  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #c4c4c4;
}
.ElMonte {
  /* 828 Middleville Road, 91731, El Monte, California */

  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #c4c4c4;
}
.rate {
  /* 4.7 / 5.0 */

  font-style: normal;
  font-weight: bold;
  font-size: 25px;

  /* identical to box height */

  color: #060f21;
}
.Reviews4 {
  /* 4 Reviews */

  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  /* identical to box height */
  text-align: center;

  color: #6c6c6c;
}
.ABOUTME {
  /* ABOUT ME */
  padding-top: 21px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #4453d6;
}
.longtext {
  /* I am the Co-Founder of Everus Medical Equipment and Supply. Please do not hesitate to contact me especially if you are looking for urgent services. I’m available 24 hours. */

  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  /* or 133% */

  color: #17171d;
}
.viewpro {
  /* Rectangle 31 */
  margin-top: 41px;
  width: 233px;
  height: 42.71px;

  background: #4453d6;
  border-radius: 10px;
}
.viewpro2 {
  /* Rectangle 32 */
  margin-top: 8px;
  width: 233px;
  height: 42.71px;

  background: #6dc5cc;
  border-radius: 10px;
}
.ServiceArea {
  /* Location (Service Area) */

  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  /* identical to box height */

  color: #c4c4c4;
}
.Francisco {
  /* San Francisco, California */

  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #6c6c6c;
}
.OFFERED {
  /* SERVICES OFFERED */

  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #4453d6;
}
.RatingsReviews {
  /* Ratings & Reviews */

  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  /* identical to box height */

  color: #6c6c6c;
}
.Rectangle082 {
  /* Rectangle 31 */
  margin-top: 5px;
  display: flex;
  width: 1032px;
  height: 58px;
  justify-content: space-around;
  align-items: center;

  background: #4453d6;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.Rectangle082ViewAll {
  /* Rectangle 31 */
  margin-top: 5px;
  display: flex;
  width: 960px;
  height: 58px;
  justify-content: space-around;
  align-items: center;

  background: #4453d6;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.GEServici {
  /* GE Servicing */

  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #ffffff;
}
.USDperhou {
  /* USD $85 per hour */

  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  /* identical to box height */

  align-items: center;

  color: #ffffff;
}
.Provided29 {
  /* 29 Services Provided */

  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  /* identical to box height */

  align-items: center;
  text-align: center;

  color: #ffffff;
}
.ratingno {
  /* 4.7 / 5.0 */

  font-style: normal;
  font-weight: bold;
  font-size: 20px;

  /* identical to box height */

  color: #ffffff;
}
.ViewAll {
  /* View All */
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  /* identical to box height */
  text-align: center;

  color: #ffffff;
}
.rec {
  /* Rectangle 31 */

  justify-content: space-evenly;
  align-items: center;
  width: 540px;
  height: 58px;
  display: flex;

  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.Shimadzu {
  /* Shimadzu Servicing */

  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #6c6c6c;
}
.usd85 {
  /* USD $85 per hour */

  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  /* identical to box height */

  color: #6c6c6c;
}
.ser {
  /* 21 Services Provided */

  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  /* identical to box height */

  align-items: center;
  text-align: center;

  color: #6c6c6c;
}
.Servicecard1 {
  /* Rectangle 32 */
  padding: 12px;
  width: 353px;
  height: 156px;
  margin-top: 10px;

  background: #333333;
  border-radius: 10px;
}
.LouisWen {
  /* Louis Wen */

  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  /* identical to box height */

  color: #ffffff;
}
.time {
  /* 06/08/2019; 4:32pm */

  font-style: italic;
  font-weight: normal;
  font-size: 12px;

  /* identical to box height */
  opacity: 0.6;
  color: #ffffff;
}
.S-o-D {
  /* Ms.Julie is very responsive on S-o-D. Whenever I needed guide, she always replies within 1 hours time. Her explaination is clear and detailed enough to guide you in your problem. Definitely one of the best service provider in RDEE! */

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
}
.allreviews {
  /* SERVICES OFFERED */

  margin-top: 22px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  color: #4453d6;
}
.notificationCSSProviderProfile {
  width: 480px !important;
}
.parentRowProviderProfile {
  padding: 30px;
  width: calc(100vw - 774px) !important;
  background: #ffffff !important;
  overflow-x: hidden;
  height: calc(100vh - 10px);
}
.inlineFlex100ProviderProfile {
  width: 100% !important;
  display: inline-flex !important;
}
.inline {
  display: inline-flex !important;
}
.scrollBehaveXMyProviderProfile {
  overflow-x: auto;
  padding: 10px !important;
}
