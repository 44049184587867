.rightPanelContainer {
  display: inline-flex;
  position: absolute;
  right: 0;
  top: 0;
  /* padding-top: 70px; */
  z-index: 2;
  /* background: linear-gradient(180deg, #4453D6 0%, #8B2FA0 100%); */
}

.rightPanelContainer::before {
  /* display: inline-flex;
    background: linear-gradient(180deg, #4453D6 0%, #8B2FA0 100%); */

  content: '';
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 490px;
  background: linear-gradient(180deg, #4453d6 0%, #8b2fa0 100%);
  z-index: -1;
}

.rightPanelContainerOverlay {
  position: absolute;
  right: 0;
  top: 0;
  /* padding-top: 70px; */
  z-index: 2;
}

/* .rightPanelContainerOverlay::before {
    content: "";
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 490px;
    background: #333333 !important;
    z-index: -1;
} */

/* .rightPannelTopMargin{
   display: inline-flex;
   min-height: 100%; 
} */

.leftVerticleDiv {
  /* height: 100vh; */
  width: 10px;
  display: inline-flex;
  align-items: center;
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(180deg, #4453d6 0%, #8b2fa0 100%);

  /* background: linear-gradient(180deg, #4453D6 0%, #8B2FA0 100%); */
}

.rightPanelContainerOverlay .leftVerticleDiv {
  width: 0px;
  z-index: 10;
}

.panelCollapseButton {
  background: linear-gradient(
    139.03deg,
    #4453d6 6.2%,
    #8b2fa0 85.48%
  ) !important;
  border: 1px solid #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 45px;
  height: 45px;
  position: absolute;
  z-index: 1024;
  right: 2px;
  border-radius: 30px;
  top: 120px;
  display: flex;
  align-items: center;
}
.panelCollapseButton:focus {
  border: 1px solid #ffffff !important;
}

.panelCollapseButton img {
  width: 14px;
  height: 20px;
  margin-left: 2px;
}
.openChildContainer {
  display: none;
  width: 490px;
  padding-top: 20px;
}

.bgTransparent {
  background: transparent !important;
}
@media only screen and (min-width: 1201px) and (max-width: 1919px) {
}
@media only screen and (min-width: 701px) {
  .openChildContainer {
    display: block;
    padding-top: 119px;
  }
}
@media only screen and (min-width: 1025px) {
  /* .rightPannelTopMargin{
        padding-top: 70px;
    } */
  .openChildContainer {
    display: block;
  }
  .panelCollapseButton {
    display: none;
  }
}

.showPanel {
  display: block;
}
