.paymentContainer {
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
.mainContainer {
  width: 100%;
  font-family: Helvetica;
  overflow: hidden;
  overflow-y: scroll;
}
.myProfileTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  margin-left: 40px;
  margin-bottom: 20px;
  margin-top: 10px;

}

.profileImage {
  /* border: 3px solid #1FE03E; */
  box-sizing: border-box;
  border-radius: 100px;
  width: 130px;
  margin: 15px;
}
.innerContainer {
  margin-left: 35px;
}
.userProfileBox {
  padding: 5px;
  background: #ffffff;
  border-radius: 9px;
  margin-right: 20px;
}

.userProfileBox .username {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  margin-top: 25px;
}

.userProfileBox .workplace {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
}
.workplace img {
  width: 25px;
}
.userProfileBox .univercityEduplace {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #4092b7;
  margin-bottom: 5px;
}
.univercityEduplace img {
  width: 25px;
}
.userProfileBox .localtionText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 5px;
}
.localtionText img {
  width: 25px;
}
.userProfileBox .buttonBox{
  float: right;
  display: grid;
}
.userProfileBox .ProfileButtons {
  margin: 15px;
  background: linear-gradient(180deg, #439FC8 0%, #4358A7 100%);
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: 150px;
  height: 50px;
  color: #FFFFFF;
  border: 0px;
}



.profileDiv {
  height: 100px !important;
  width: 100%;
  background: white;
  border-radius: 10px;
}
.txtColor {
  color: #fff !important;
}
.padd10 {
  padding: 10px;
}
.padd14 {
  padding: 14px;
  margin-top: 17px;
  word-break: break-word;
}
.marginT10 {
  margin-top: 10px;
}

.card {
  width: 100%;
  background: #333333;
  border-radius: 9px;
  padding: 10px;
}

.buttonCard {
  width: 100%;
  background: #333333;
  border-radius: 9px;
}

.titleP {
  margin-bottom: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.titlePColor {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;

  opacity: 0.6;
}

.nameStyle {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.dateText {
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.borderBotton {
  border-bottom: 1px solid #adadad;
}
.buttonStyle {
  background: #4092b7;
  width: 100%;
  border-radius: 0px 0px 9px 9px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  min-height: 50px;

  color: #ffffff;
}
.columnTitle{
  margin-bottom: 20px;
}

.backButton{
  background: transparent;
  border: 0px;
}
.backButton img{
  width: 23px;
}




