
.highlightsContainer{
  width: calc(100vw - 10px); 
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 40px;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.flex{
  height: 100% !important;
}
.highLightsTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 34px;
  color: #FFFFFF;
}
.highLightOuterBox{
  max-width: 1090px;
  background: #060F2130;
  /* opacity: 0.3; */
  border-radius: 30px;
  min-height: 100px;
  margin-top:5px;
  padding: 20px;
  display: inline-flex;
  flex-wrap: wrap;
  width: calc(100vw - 300px);

}
.HightlishtsInnerContentBoxes{
  width: 323.21px;
  min-height: 168px;
  background: #FFFFFF;
  border-radius: 13px;
  margin: 13px;
  cursor: pointer;
  padding-bottom: 10px;
  position: relative;
}
.HightlishtsInnerContentBoxes .highfeedTitle{
  min-width: 72px;
  width: max-content;
  min-height: 30px;
  background: #17171D;
  border-radius: 0px 30px 30px 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  align-items: center;

  color: #FFFFFF;
  display: inline-flex;
  position: absolute;
  top: 18px;
  text-transform: uppercase;
  padding-right: 10px;
  padding-left: 10px;
}

.HightlishtsInnerContentBoxes .imgsContentBox{
  width: 100%;
  overflow: hidden;
  height: 120px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #333333;
}
.HightlishtsInnerContentBoxes .highContentDate{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #333333;

  opacity: 0.8;
  padding-left:20px;
  margin-top: 8px;
  margin-bottom: 0px;

}
.HightlishtsInnerContentBoxes .highContentTitle{
  font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;

color: #17171D;
margin: 0px !important;
padding-left:20px;


}

.noHightLightsFound {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  opacity: 0.9;
  margin: auto;
  width: 100%;
  text-align: center;
}




.highLightbackbutton{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #D9D9D9 !important;
  background: transparent !important;
  border: none;
  margin-right: 20px;

}
.highLightbackbutton div{
  width: 30px;
  height: 30px;
  background: #4453D6;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
 

}
.highLightbackbutton span{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
}
.highLightbackbutton img{
  width: 20px;
}

@media only  screen and (min-width: 1920px){
  .highlightsContainer {
      width: calc(100vw - 300px);
      margin-top: 50px;
      padding-left: 40px;
      padding-bottom: 40px;
      margin-left: 300px;
  }

}

