.calendar-container,
.calendar,
.calendar-header,
td,
tr {
  padding: 0;
  margin: 0;
}

.calendar-container {
  position: relative;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  padding-right: 20px;
  z-index: 1;
  bottom: 22rem;
  left: 3rem;
  /* overflow: hidden; */
  /* border: 2px solid skyblue; */
}
/* Specifically in dropdown menu marketplace/sellerconsole */
.dropdoowndicaddpro .calendar-container {
  bottom: 0;
  left: 0;
}

.calendar-container::before {
  position: absolute;
  content: '';
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  background-color: #333333;
  /* opacity: 0.9; */
  border-radius: 10px;
  z-index: -1;
  height: 100%;
}

.calendar {
  /* position: relative; */
  /* background-color: white; */
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px;
  margin-left: 10px;
}
.calendar-header {
  /* border-bottom: 1px dashed skyblue; */
  /* font-size: 1.6em; */
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  /* display: flex;
    align-items: center; */

  color: #ffffff;
  padding: 10px;
  padding-bottom: 0px;
}

.calendar-header td {
  border-spacing: 0;
  padding-left: 5px;
}

.day,
.emptySlot,
.week-day {
  text-align: center;
  height: 28px;
  width: 32px;
  line-height: 28px;
  font-size: 14px;
}

.week-day {
  /* font-size: 0.8em; */
  opacity: 0.7;
}

.day {
  cursor: pointer;
  width: 32px;
  height: 28px;
  font-size: 14px;
  line-height: 28px;
}
.emptySlot {
  width: 32px;
  height: 28px;
}

.current-day {
  background-color: #4453d6;
  width: 28px;
  height: 28px;
  color: #ffffff;
  border-radius: 100%;
  line-height: 28px;
}
.selected-day {
  background-color: #4453d6;
  width: 28px;
  height: 28px;
  color: #ffffff;
  border-radius: 100%;
  line-height: 28px;
}

.month-popup {
  position: absolute;
  padding: 5px;
  background: #17171d;
  overflow: hidden;
  z-index: 1;
  height: 225px;
  overflow: auto;
  border-radius: 10px;
  top: 25px;
}
.month-popup::-webkit-scrollbar,
.year-popup::-webkit-scrollbar {
  width: 8px;
}
.month-popup::-webkit-scrollbar-thumb,
.year-popup::-webkit-scrollbar-thumb {
  background: #4453d6;
  border-radius: 20px;
}
.month-popup::-webkit-scrollbar-track,
.year-popup::-webkit-scrollbar-track {
  background: #4453d650;
  border-radius: 10px;
}

.month-popup div {
  padding-left: 0.3em;
  padding-top: 2px;
  padding-right: 0.3em;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
}

.nav-month {
  position: absolute;
  top: 0.6em;
  font-size: 0.6em;
  right: 3px;
}

.prev {
}

.next {
}

.editor-year {
  max-width: 3.6em;
}

.label-month,
.label-year {
  font-size: 14px;
  align-items: center;
  display: inline-flex;
  padding-top: 2px;
  cursor: pointer;
}

.label-month {
  cursor: pointer;
}

.cancelDateButton {
  border: 1px solid #ffffff !important;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  background: transparent !important;
  color: #ffffff;
  height: 30px;
  width: 82.7px;
  border-radius: 30px;
  margin: 10px;
  outline: none !important;
}
.submitDateButton {
  background: #4453d6 !important;
  border: none !important;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  height: 30px;
  width: 82.7px;
  border-radius: 30px;
  color: #ffffff;
  margin: 10px;

  outline: none !important;
}

.inlineDayBoxes {
  display: inline-flex;
  /* width: 100%; */
  flex-wrap: wrap;
  justify-content: flex-start;
}
.selectPickerValues {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.arrowButtons {
  background: transparent !important;
  color: #ffffff;
  border: none !important;
  cursor: pointer;
  opacity: 0.5;
}
.arrowButtons:focus {
  outline: none !important;
}

.year-popup {
  position: absolute;
  padding: 5px;
  background: #17171d;
  overflow: hidden;
  z-index: 1;
  height: 200px;
  overflow: auto;
  border-radius: 10px;
  top: 20px;
  left: 5px;
}

.year-popup div {
  padding-left: 0.3em;
  padding-top: 2px;
  padding-right: 0.3em;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  cursor: pointer;
}
.disableOpacity {
  opacity: 0.5;
  cursor: initial;
}
