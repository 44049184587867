// Variables
// Colors
$white: #fff;
$button-blue: #4453d6;
$button-black: #17171dcc;
// Breakpoints
$breakpoint-small: 569px;
// Mixins
@mixin reuse-button {
  width: 100%;
  max-width: 327px;
  height: 49px;
  border-radius: 30px;
  font-size: 16px;
  text-align: center;
  color: $white;
}

.landingmaindiv {
  font-family: Segoe;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  .logo {
    margin: 0 auto;
    max-width: 100%;
    // padding-bottom: 3.5rem;
  }
  .optionBox {
    margin: 0 auto;
    width: 100%;
    .MeetRDEE {
      text-align: center;
      font-size: 3rem;
      color: $white;
    }
    .TheSecure {
      text-align: center;
      font-size: 1rem;
      color: $white;
      padding-bottom: 20px;
      margin-bottom: 25px;
    }
    .landingPageForm {
      display: flex;
      flex-direction: column;
      align-items: center;
      .landbutton1 {
        @include reuse-button();
        background: $button-blue;
        margin-bottom: 19px;
      }
      .landloginbutt {
        @include reuse-button();
        background: $button-black;
      }
    }
  }
}

// To change width for mobile design
// @media (min-width: $breakpoint-small) {
//   .landbutton1,
//   .landloginbutt {

//   }
// }
