/* 
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.App {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Segoe Neue', sans-serif,
    'Segoe';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

/* width */
.container-children ::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  border-radius: 5px;
}

/* Track */
.container-children ::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
.container-children ::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

/* Handle on hover */
/* .container-children ::-webkit-scrollbar-thumb:hover {
  background: #dddddd; 
} */

.handpointer {
  cursor: pointer;
}
.search-container {
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-image: url('../images/marketplace.jpeg');
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}
.form-control:disabled,
.form-control[readonly] {
  background: #0c0f1d;
}
.submit-button {
  color: #ffffff;
  font-weight: 500;
  background: #4453d6;
  border-radius: 5px;
  text-align: center;
  padding: 10px 20px;
  min-height: 30px;
  width: 100%;
  min-width: 180px;
  font-size: 0.8rem;
  cursor: pointer;
}

.layout-view {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  overflow: auto;
  min-height: 500px;
}

.validationerrorMessage {
  color: #ff4b4b !important;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 5px;
  padding-left: 20px;
}
.redBoxErrorMsg {
  color: #ffffff;

  margin-bottom: 0;
  margin-top: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
  background: #d80031;
  border-radius: 10px;

  font-family: Helvetica;
  font-style: italic;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  min-height: 37px;
  color: #ffffff;
}
.agreeCheckbox {
  width: 30px !important;
}

.react-phone-number-input__input {
  background: transparent !important;
  border-bottom: 0px !important;
  color: #fff !important;
}
.react-phone-number-input__input input {
  background: transparent !important;
  border-bottom: none !important;
  color: #fff !important;
}
.react-phone-number-input__input:focus:focus {
  color: #fff !important;
}
.react-phone-number-input__icon {
  width: 50px !important;
}
.react-phone-number-input__icon-image {
  width: 50px !important;
  max-height: auto !important;
  max-width: auto !important;
  min-height: 50px;
  margin-top: -15px;
}
.react-phone-number-input__icon--international {
  width: 30px !important;
}
.yellowcircle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #f5d028;
}
.orangecircle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #efa986;
}
.greencircle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #6dcace;
}
.greycircle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #a5a5a5;
}
.lightgreen {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #94d6d9;
}
.whitecircle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #ffffff;
}
.bluecircle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #4180b2;
}
.backgroundcolor {
  background: linear-gradient(313.09deg, #571b65 5.62%, #4453d6 74.88%);
}

.center {
  text-align: center;
}

.padding {
  padding: 10px 30px;
}

.checkdropMenuFlex {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
}
.checkdropMenuFlex a {
  width: 40px;
}

.p-carousel-next,
.p-carousel-prev {
  height: 3.5em !important;
}

.toolTipStyle {
  /* margin: 0px;
   padding: 0px;
   font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 30px; */
}
.react-hint__content {
  padding: 5px !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
}

/* Blue Scroller Start */

/* width */
.contactListContainer::-webkit-scrollbar,
.contactScrollView::-webkit-scrollbar {
  width: 10px;
  background: #17171d80;
  opacity: 0.5;
  border-radius: 10px !important;
}

/* Track */
.contactListContainer:-webkit-scrollbar-track,
.contactScrollView::-webkit-scrollbar-track {
  background: #17171d;
  opacity: 0.5;
  border-radius: 10px !important;
  width: 10px;
}

/* Handle */
.contactListContainer::-webkit-scrollbar-thumb,
.contactScrollView::-webkit-scrollbar-thumb {
  background: #4453d6;
  opacity: 0.8;
  border-radius: 10px !important;
  width: 10px;
}
/* Blue Scroller end */

.yelloTextHiglight {
  padding: 0px !important;
  background: transparent !important;
  color: #f5d028 !important;
}
