// Mixins
@mixin button {
  width: 100%;
  max-width: 327px;
  height: 49px;
  border-radius: 30px;
  font-size: 16px;
  color: $white;
  border: none;
}
// Variables
// Colors
$button-blue: #4453d6;
$white: #fff;
.paymentStatusBoxContainer {
  font-family: Segoe;
  margin: 0 auto;
  max-width: 429px;
  .paymentBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 1.9rem 0;
    .verificationTitlesuccess {
      width: 100%;
      margin: 0;
      .VerifyEmailTitle,
      .verificationfailedDescription {
        margin: 0;
        color: $white;
        text-align: center;
      }
    }
    .returnRDEEButton {
      @include button();
      background-color: $button-blue;
    }
  }
}
