.servicreamaidi{
  padding: 15px;
  width: calc(100vw - 490px);
  overflow-x: auto;
  height: calc(100vh - 10px);
}
.cSERVICEROVIDER {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #c4c4c4;
}

.crePROVIDESERVICE {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  padding-top: 5px;
  color: #4453d6;
}


.cregoback {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  color: #c4c4c4;
  display: inline-flex;
  cursor: pointer;
}
.cregoback > div {
  background: #c4c4c4;
  border-radius: 5px;
  text-align: center;
  width: 25px;
  height: 26px;
}
.cregoback img {
  width: 14px;
}
.cregoback span {
  padding-left: 5px;
}
/* Profile Setup */
.creProfileSetup {
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  color: #45443c;
}
/* Profile Details: */
.crrerofileDetails {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  padding-top: 17px;
  color: #6c6c6c;
}
/* About Me* */
.creAboutMe {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-top: 22px;
  color: #8c8c8c;
}
/* Rectangle 27 */
.cretextarea {
  width: 554px;
  height: 152px;
  /* background: #c4c4c478; */
  border-radius: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #000000;
  padding-left: 10px;
  border: 2px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 10px;
  color: #A5A5A5 !important;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
}
.credropdow {
  width: 268px;
  height: 49px;
  background: #c4c4c4;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #a5a5a5;
  margin-top: 17px;
}
/* Services Offered: */
.crweServicesOffer {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  color: #6c6c6c;
  padding-top: 29px;
}
.creaddnewbut {
  width: 170px;
  height: 49px;
  border: none;
  background: #4453d6;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin-top: 26px;
}
.crenorvicesadd {
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  align-items: center;
  padding-top: 30px;
  color: #a5a5a5;
}
.creIncludeM {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #8c8c8c;
  padding-top: 160px;
}
.cretoglebut {
  background: #4092b7;
}
.addservicemodal {
  width: 422px;
  background: #333333;
  border-radius: 10px;
}
.creAddSe {
  font-weight: 300;
  font-size: 26px;
  line-height: 30px;
  color: #eeeeee;
  padding-top: 39px;
  padding-left: 39px;
}
.addxermod {
  width: 342px;
  height: 49px;
  background: #4ca9b7;
  border: 1px solid #4ca9b7;
  border-radius: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  align-items: center;
  margin-top: 22px;
  color: #ffffff;
  padding-left: 10px;
}
.crereateperin {
  width: 342px;
  height: 49px;
  background: #c4c4c4;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  margin-top: 17px;
  padding-left: 10px;
}
 /* input:focus{
    background-color: #c4c4c4 !important;
    background: #c4c4c4 !important;
    box-shadow: none !important;
    outline: 0px !important;

  } */

.creasddbut {
  width: 117px;
  height: 49px;
  border: none;
  background: #4ca9b7;
  border-radius: 10px;
  margin-top: 18px;
  float: right;
  margin-right: 20px;
}
.creADdatview {
  width: 1032px;
  height: 58px;
  background: #333333;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.creadviewt {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  align-items: center;

  color: #ffffff;
}
.creaddperhour {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  align-items: center;

  color: #ffffff;
}
.creServicesPro0 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}
.creatingsadded {
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  align-items: center;
  color: #eeeeee;
}
.crexzReviews {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  float: right;
  padding-bottom: 5px;
  color: #6c6c6c;
  padding-right: 137px;
  padding-top: 15px;
}
.crecongomoda {
  width: 422px;
  height: 320px;
  background: #333333;
  border-radius: 10px;
}
.moCongratulations {
  font-style: normal;
  font-weight: 300;
  font-size: 26px;

  text-align: center;
  padding-top: 25px;
  color: #eeeeee;
}
.crmoprofilehasbe {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;

  text-align: center;
  padding-top: 32px;
  color: #ffffff;
}
.cremodbutt {
  width: 277.51px;
  height: 49px;
  background: #4092b7;
  border-radius: 10px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  margin-top: 30px;
  color: #ffffff;
}
.creeditmy {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-top: 9px;
  text-align: center;

  color: #ffffff;
}
.creprochngmo{
width: 422px;
height: 191px;
background: #333333;
border-radius: 10px;
}
.cremoaveProf{
font-style: normal;
font-weight: 300;
font-size: 26px;
color: #EEEEEE;
padding-left: 39px;
padding-top: 30px;

}
.cremodpronobut{
width: 130.66px;
height: 49px;
background: #A5A5A5;
border-radius: 10px;
font-style: normal;
font-weight: bold;
font-size: 17px;
text-align: center;
color: #FFFFFF;
border: none;
margin-top: 33px;
margin-right: 21px;
}
.cremodproYESbut{
width: 117px;
height: 49px;
background: #4CA9B7;
border-radius: 10px;
font-style: normal;
font-weight: bold;
font-size: 17px;
text-align: center;
color: #FFFFFF;
border: none;
margin-top: 33px;
margin-right: 39px;
}

.submitProfileButton {
  width: 205px;
  height: 50px;
  background: #4ca9b7;
  border-radius: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  color: #ffffff;
  display: inline-flex;
  margin-top: 20px;
}
.submitProfileImg {
  width: 37px;
  height: 34px;
  background: #ffffff;
  border-radius: 8px;
  margin-left: 10px;
  
}

.profileSetup .p-dropdown{
  background: #FFFFFF !important;
  border: 2px solid #C4C4C4  !important;
  box-sizing: border-box  !important;
  border-radius: 10px  !important;
  color: #A5A5A5 !important;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;


}
.validationErrorMessage{
  font-size: 18px;
  color: #FF3C3C  !important;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 35px;
}
@media only screen and (min-width: 1001px) and (max-width: 1400px) {
  .servicreamaidi{
    padding: 15px;
    width: calc(100vw - 490px);
    overflow-x: auto;
  }
}
  @media only screen and (min-width: 300px) and (max-width: 400px) {
    .leftPannelScroll{
      height: 90vh;
      overflow: hidden;
      overflow-y: scroll;
      width: 650px;
      /* overflow: scroll; */
     
    }
  }
    @media only screen and (min-width: 701px) and (max-width: 1000px) {
      .leftPannelScroll{
        height: 90vh;
        overflow: hidden;
        overflow-y: scroll;
        width: 650px;
        /* overflow: scroll; */
       
      }
    }      
    @media only screen and (min-width: 401px) and (max-width: 700px) {
      .leftPannelScroll{
        height: 90vh;
        overflow: hidden;
        overflow-y: scroll;
        width: 650px;
        
        /* overflow: scroll; */
       
      }
    }    