.kycContainerBox{
    min-height: 206px;
    min-width: 327px;
    border-radius: 30px;
    background: #17171D;
    padding: 20px;
}
.kycBoxVerifyTitle{
    font-family: Segoe;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 15px;
margin-top: 10px;


}
.kycBoxVerifySubTitle{
    font-family: Segoe UI;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
padding-left: 15px;
margin-top: 15px;
opacity: 0.8;

}
.goverifyKYCButton{
    height: 37px;
    width: 128px;
    left: 0px;
    top: 0px;
    border-radius: 30px;
    background: #4453D6 !important;
    color: #FFFFFF !important;
    border-radius: 30px;
    font-family: Segoe;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    border: none !important;
    margin-left: 10px;
}
.cancelGoButton{
    font-family: Segoe;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    height: 37px;
    width: 102px;
    left: 0px;
    top: 0px;
    border-radius: 30px;
    color: #FFFFFF !important;
    background: #FFFFFF40 !important;
    border: none !important;
    /* opacity: 0.3; */

}