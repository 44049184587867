.uploadedFilesHeader{
    display: inline-flex;
    border-bottom: 1px dashed #FFFFFF;
    justify-content: space-between;
    /* width: calc(100% - 28px); */
    margin-top: 10px;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* max-width: 1300px;
    width: 100%;
    min-width: 600px;
    margin-right: 27px; */

    width: calc(100% - 27px);

    align-items: center;
    padding-left: 1px;
    min-width: 1280px;
    max-width: 1280px;

    margin-bottom: 10px;

}
.fileTypeTitle{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
}
.fileTypeTitle span{
    font-weight: bold;
}
.fileSortByTitle{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: right;
}
.sortByArrow{
    position: absolute;
}
.inlineFolderView{
    display: inline-flex;
    flex-wrap: wrap;
}
.folderNameBoxes{
    background: #333333;
    border-radius: 10px;
    min-width: 309.21px;
    min-height: 62px;
    align-items: center;
    display: inline-flex;
    padding: 10px;
    margin-top: 10px;
    margin-right: 10px;
    justify-content: space-between;
}
.folderNameText{

    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding-left: 7px;
}

.filesMainImagesBox{
    border-radius: 10px;
    width: 309.21px;
    /* min-height: 133px; */
    margin-top: 10px;
    margin-right: 10px;
}

/* .filesMainImagesBox .fileContentBox {
    width: 100%;
    overflow: hidden;
    height: 120px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #333333;
} */
.fileListingNameBox{
    /* background: #333333; */
    border-radius: 10px;
    width: 100%;
    height: 55px;
    display: inline-flex;
    align-items: center;
}
.fileNameBold{
    font-family: Segoe;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;

    color: #FFFFFF;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: inline-block;
}
.fileUploadedStatus{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    color: #FFFFFF;
    opacity: 0.8;
}

.gridfileMenuOption{
    background: transparent !important;
    border: none !important;
    font-size: 26px;
    color: #FFFFFF !important;
    padding: 0px;
    margin-left: 5px;
    width: 10px;
    line-height: 10px;
}
.gridfileStatusBox{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #FFFFFF;
    min-width: 170px;
    margin: auto;
    display: inline-flex;
    justify-content: flex-end;
}
.GriduploadProgressBar {
    width: 125px;
    height: 10px !important;
    background: #c4c4c4;
    border-radius: 10px;
    margin-right: 10px;
  }
  .GriduploadProgressBar .progress-bar{
    background-color: #4AEA87 !important;
  }
  .selectGridButton{
    background: transparent !important;
    border: none !important;
    color: #FFFFFF !important;
    padding: 0px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    margin-left: 50px;
  }
  .inlineFlexItem{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }


  

  @media only  screen and (min-width: 1920px){
 
    .uploadedFilesHeader{
      width: calc(100vw - 400px);
      max-width: 1280px;
      padding-right: 8px;
    }
  }