.aboutfirsttext{
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 24px;
color: #FFFFFF;
}
.aboutfirsttext p{

font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 14px;
color: #FFFFFF;
opacity: 0.7;

}
.Theworlds{
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 16px;
color: #FFFFFF;


}