.contactText{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 34px;
    
    color: #FFFFFF;
    margin-bottom: 16px;
    text-transform: uppercase;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.contactText span{
font-weight: bold;
}
.recievecol{
    height: 50px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 46px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}
.sentcol{
    height: 50px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 46px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;

}
.proimg{
width: 46px;
height: 46px;
border-radius: 86px;
}
.laura{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    
    color: #FFFFFF;


}
.neuro{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    
    color: #FFFFFF;


}
.date{
font-style: normal;
font-weight: 500;
font-size: 14px;

color: #FFFFFF;
width:120px;

opacity: 0.5;

}
.approve{
    font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 19px;
align-items: center;
text-align: center;

color: #FFFFFF;
background: #4453D6;
border-radius: 30px;
width:190px;
height:36px;
border: none !important;
}
.remove{
   width:121px;
    height:36px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    align-items: center;
    text-align: center;
    border-radius: 30px;

    color: #FFFFFF;
    margin-left:20px;
    border: none !important;

}
.row2{
    /* padding: 22px; */
    display: inline-flex;
    width: 100%;
}
.mainrow{
    padding: 20px;
    width: calc(100vw - 500px);
    /* padding-right: 40px; */
    overflow-x: auto;
    padding-left: 30px;
    /* max-width: 854px; */
}
.tab{
    margin-top: 17px;
    height: 44px;
    background: #333333;
    /* border-radius: 30px; */
    border-radius: 0px 30px 30px 0px;
    display: inline-flex
    ;
}
.tab .tabbox{
    width: 270px;
    font-family: Segoe;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    padding-top: 10px;
}
.tab .firsttabBox{
    border-radius: 0px 30px 30px 0px;
    
}
.tab .secondtabBox{
    opacity: 0.7;
    cursor: pointer;
}
.tab .firsttabBox .grpIcon{
    width: 34px;
    height: 25px;
    margin-right: 10px;
}
.divider{
  
    border: 1px dashed #FFFFFF;
    margin-top: 3px;
    margin-left: 60px;
    opacity: 0.5;
}
.sent{
    padding: 0px;;
}
.recieve{
    padding: 0px;
}
.requestContainer{
    margin:20px;
    overflow: hidden;
    overflow-x: auto;
    height: calc(100vh - 250px);
    overflow-y: auto;
}
.requestContainer .requestButtons{
    display: inline-flex;
    margin: auto;
    align-items: center;
}

.requestContainer .userNameBox{
    margin-left: 15px;
    max-width: 270px;
    min-width: 240px;
    cursor: pointer;
}

.requestContainer  .innerContainerBox{
    margin-bottom: 10px;
    margin-top: 15px;
    display: inline-flex;
    width: 100%;

}

.contactListContiner{
    display: inline-flex;
}
.rightContactListing .contactScrollView{
    width: 500px;
}

.contactBox{

background: #060F21;
/* opacity: 0.3; */
border-radius: 10px;
border-radius: 10px;
/* height: calc(100vh - 160px); */
}


.pendingButton{
    font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 19px;
align-items: center;
text-align: center;

color: #FFFFFF;

border-radius: 30px;
width:190px;
height:36px;
border: none !important;
background: #A5A5A590  !important;
;
}

.clearButton{
   width:121px;
    height:36px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    align-items: center;
    text-align: center;
    border-radius: 30px;

    color: #FFFFFF;
    margin-left:20px;
    border: none !important;
    background:#8B2FA0 !important;

}

.noRequestData{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 19px;
    align-items: center;
    color: #FFFFFF;
    text-align: center;
    margin-top: 20px;
}


.contactRequestbackbutton{
    font-family: 'Segoe';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: inline-flex;
    align-items: center;
    text-align: right;
    text-transform: uppercase;
    color: #C4C4C4;
    background: transparent;
    border: none;
  
  }
  .contactRequestbackbutton:hover{
    background: transparent;
    color: #666666;
  }
  .contactRequestbackbutton div{
    width: 25px;
    height: 25px;
    background: #4453D6;
    border-radius: 4px;
    margin-right: 10px;
    padding: 1px;
    margin-right: 5px;
    padding-right: 5px;
   
  
  }
  .contactRequestbackbutton span{
    font-family: 'Segoe';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin-left: 5px;
  }
  .contactRequestbackbutton img{
    width: 15px;
  }
  .myContactButtom{
    background: #FFFFFF !important;
    border-radius: 30px;
    width: 153px;
    height: 39px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #4453D6 !important;
    align-items: center;
    display: flex;
  }
  .myContactButtom img{
    width: 22px;
    margin-right: 10px;
  }
  .innerRequestBox{
    width: calc(100vw - 10px);
    max-width: 824px;
  }
  


  @media only screen and (min-width: 400px) and (max-width: 1025px) {
    .mainrow {
      width: calc(100vw - 10px);
      
    }
    .innerRequestBox{
        width: calc(100vw - 500px);
        max-width: 824px;
      }
  }



  @media only screen and (min-width: 1920px)   {
        .mainrow{
            padding: 20px;
            width: calc(100vw - 810px);
            padding-left: 30px;
            margin-left: 300px;
            margin-top: 20px;
        }
        .contactListContiner{
                position: absolute;
                width: 100%;
                top: 0px;
        }
        .innerRequestBox{
            width: calc(100vw - 900px);
            max-width: 824px;


          }
  }
