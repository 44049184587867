.seetings {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
}

.mainViewBox{
  background: #4092b7;
  min-width: 130px;
  height: 115px;
  border-radius: 10px;
  text-align: center;
  margin-right: 15px;
  cursor: pointer;
}
.mainViewBox .boxtitle{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.56;
  padding-top: 10px;
  line-height: 20px;
  padding: 15px;
}
.mainViewBox img{
  padding-top: 20px;
}

.contactsupp {
  background: #333333;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  height: 55px;
  width: 340px;
  border: none;
  cursor: pointer;
  margin-top: 19px;
  padding-left: 22px;
  padding-right: 155px;
}
.logoutsupp {
  background: #333333;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  height: 55px;
  width: 340px;
  border: none;
  cursor: pointer;
  margin-top: 19px;
  padding-left: 16px;
  padding-right: 218px;
}
.settdivider {
  width: 2px;
  height: 777px;
  background: #ffffff;
  opacity: 0.3;
}



  .mainLeftView{
    width: calc(100vw - 500px)
  }
  .rightSideBox{
    background-color: #000000;
    height: calc(100vh - 100px);
    overflow: hidden;
    overflow-y: scroll;
    z-index: 9;
    width: 480px;
  }
  .mainSettingContainer{
    height: calc(100vh - 100px);
  }