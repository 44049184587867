.GENERATEAAA {
  /* GENERATE A */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #4453d6;
}
.GOBACKkk {
  /* GO BACK */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  text-align: right;

  color: #c4c4c4;
}
.erviceRequest {
  /* Service Request */
  font-style: normal;
  font-weight: 300;
  font-size: 40px;

  color: #45443c;
}
.ServiceDetails {
  /* Service Details: */
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;

  color: #6c6c6c;
}
.Title {
  /* Title* */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #8c8c8c;
  padding-top: 23px;
}
.Rectangleinp {
  /* Rectangle 16 */
  width: 100%;
  height: 49px;
  background: #c4c4c4;
  /* opacity: 0.4; */
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 10px;
font-style: normal;
font-weight: 300;
font-size: 18px;
color: #000000;


}
.Rectangleinpii {
  /* Rectangle 27 */
  width: 100%;
  height: 152px;
  background: #c4c4c4;
  /* opacity: 0.4; */
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 10px;
font-style: normal;
font-weight: 300;
font-size: 18px;
color: #000000;


}
.Description {
  /* Description* */

  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #8c8c8c;
}
.Rectangle2002 {
  /* Rectangle 21 */
  margin-top: 22px;
  width: 230px;
  height: 296px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
  cursor: pointer;
}
.MsJulieMartha {
  /* Ms. Julie Martha */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #45443c;
}
.Californiaaaa {
  /* San Francisco, California */
  font-style: normal;
  font-weight: normal;
  font-size: 11px;

  color: #6c6c6c;
}
.AverageRate {
  /* Average Rate: */
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  align-items: center;

  color: #a5a5a5;
}
.s85perhour {
  /* $85 per hour */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #6c6c6c;
}
.ServicesProvided21 {
  /* 21 Services Provided */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  /* identical to box height */

  align-items: center;
  text-align: center;

  color: #6c6c6c;
}

.geRectangle {
  /* Rectangle 32 */
  width: 200px;
  height: 28px;
  margin-left: 5px;
  text-align: center;
  margin-top: 6px;
  background: #6dc5cc;
  border-radius: 7px;
  /* GE Servicing */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #ffffff;
}
.manurecc {
  /* Rectangle 17 */

  width: 45%;
  height: 49px;
  padding-left: 10px;
  background: #c4c4c4;
  opacity: 0.4;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
}
.systemrecc {
  /* Rectangle 17 */
  width: 140px;
  height: 49px;
  margin-left: 18px;
  background: #c4c4c4;
  opacity: 0.4;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
}
.Modality {
  /* Modality* */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-top: 17px;
  color: #8c8c8c;
}
.Ellipserad {
  /* Ellipse 12 */
  width: 14px;
  height: 14px;
  background: #4453d6;
  margin-right: 5px;
}
.CTtt {
  /* CT */
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  display: flex;
  align-items: center;

  color: #6c6c6c;
}
.CTtt div{
  margin-left: 20px;
  margin-top: 10px;
}
.ErrorCode {
  /* Rectangle 28 */
  /* Error Code */

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #8c8c8c;

  padding-top: 21px;
}
.Rectangle01256 {
  /* Rectangle 28 */
  width: 265px;
  height: 49px;
  padding-left: 10px;

  background: #c4c4c4;
  /* opacity: 0.4; */
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
font-style: normal;
font-weight: 300;
font-size: 18px;
color: #000000;


}
.ErrorLog {
  /* Error Log */

  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  padding-top: 20px;
  padding-left: 15px;
  color: #8c8c8c;
}
.PDFJPGonly {
  /* PDF/JPG only */
  padding-left: 15px;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #a5a5a5;
}
.re190 {
  /* Rectangle 19 */
  width: 120px;
  height: 40px;
  background: #4453d6;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: #ffffff;
  padding-top: 12px;
  cursor: pointer;
}
.Scheduleand {
  /* Schedule and Payment: */
  padding-top: 36px;
  font-weight: 300;
  font-size: 20px;

  color: #6c6c6c;
}
.Expecteddd {
  /* Expected Date of Delivery* */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-top: 17px;
  color: #8c8c8c;
}
.reccc2012 {
  /* Rectangle 29 */
  width: 230px;
  height: 49px;
 padding-left: 10px;
  background: #c4c4c4;
  /* opacity: 0.4; */
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
font-style: normal;
font-weight: 300;
font-size: 18px;
color: #000000;


}
.Resssctangle {
  /* Rectangle 19 */
  width: 220px;
  height: 50px;
  background: #4ca9b7;
  border-radius: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  color: #ffffff;
  display: inline-flex;
  float: right;
  margin-top: 20px;
}
.butondivvv {
  width: 37px;
  height: 34px;
  background: #ffffff;
  border-radius: 8px;
  margin-left: 10px;
  
}
.placemodal {
  /* Rectangle 660 */
  width: 422px;
  height: 252px;
  background: #333333;
  border-radius: 10px;
}
.Rssequestddd {
  /* Place Service Request */
  font-weight: 300;
  font-size: 26px;
  color: #eeeeee;
  padding-top: 32px;
}
.placethisservic {
  /* Do you wish to place this service request with [Service Provder Name]? */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  /* or 141% */
  padding-top: 12px;
  color: #ffffff;
}
.buuueefcv {
  /* Rectangle 32 */
  width: 170px;
  height: 49px;
  background: #4ca9b7;
  border-radius: 10px;
  /* SAVE AS DRAFT */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  text-align: center;

  color: #ffffff;

  margin-top: 34px;
}
.PLACENOWButton {
  /* Rectangle 31 */
  width: 152px;
  height: 49px;
  background: #4453d6;
  border-radius: 10px;
  /* PLACE NOW */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  text-align: center;

  color: #ffffff;

  margin-top: 34px;
}
.placesucmod{
  /* Rectangle 660 */
width: 422px;
height: 320px;
background: #333333;
border-radius: 10px;

}
.lacedSuccessfully {
  /* Request Placed Successfully */
font-style: normal;
font-weight: 300;
font-size: 26px;
text-align: center;
padding-top: 21px;
color: #EEEEEE;


}
.yuiosubmitted{
  /* Your request has been submitted to the service provider for review. You can contact the service provider on RDEE Messenger for further clarification. */
  padding-left: 48px;
  padding-top: 12px;
font-style: normal;
font-weight: normal;
font-size: 17px;

/* or 141% */

color: #FFFFFF;


}
.rdfseturn{
  /* Rectangle 31 */
width: 284px;
height: 49px;
background: #4453D6;
border-radius: 10px;
/* RETURN TO MY REQUESTS */
font-style: normal;
font-weight: bold;
font-size: 17px;
text-align: center;
margin-top: 32px;
color: #FFFFFF;


}
.PlaceAno{
  /* Place Another Request */
font-style: normal;
font-weight: bold;
font-size: 16px;
text-align: center;
padding-top:13px;
color: #FFFFFF;


}


/* .myCreateServiceRequestDetail
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  width: 145px !important;
  height: 49px !important;
  background: #C4C4C4 !important;
  opacity: 0.4 !important;
  border: 1px solid #C4C4C4 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  margin-right: 5px;
  color: #060e2170 !important;
  font-size: 14px !important;
} */

.myCreateServiceRequestDetail .btn-group,
.btn-group-vertical {
  width: 227px;
}


.myCreateServiceRequestDetail .btn-group-lg>.btn,
.btn-lg {
  width: 145px !important;
  height: 49px !important;
  background: #C4C4C4 !important;
  opacity: 0.4 !important;
  border: 1px solid #C4C4C4 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  margin-right: 5px;
  color: #060e2170 !important;
  font-size: 14px !important;
}

.myCreateServiceRequestDetail .dropdown-menu.show {
  width: 300px;
  height: 147px;
  left: 0px;
  top: 0px;

  background: #6975DE;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.myCreateServiceRequestDetail .dropdown-header {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;

  color: #FFFFFF;

}
.myCreateServiceRequestDetail .dropdown-item{
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid;
  border-bottom-style: dotted;
  padding: 10px;
  color: #FFFFFF;

}
/* .myCreateServiceRequestDetail .container-children ::-webkit-scrollbar-thumb{
  background-color: #fff !important;
} */
.myCreateServiceRequestDetail .container-children ::-webkit-scrollbar-track{
  background: #C4C4C4;
}
.myCreateServiceRequestDetail .dropdown-item:focus, .dropdown-item:hover{
  color: #fff ;
  background: #4453D6;
}
.geerdropdow{

font-style: normal;
font-weight: 300;
font-size: 18px;
/* color: #A5A5A5; */
}
.createServiceLeftContainer{
  padding: 15px;
  height: calc(100vh - 10px);
  overflow: hidden;
  overflow-y: scroll;
}

.myCreateServiceRequestDetail .p-dropdown{
  background: #FFFFFF !important;
  border: 2px solid #C4C4C4  !important;
  box-sizing: border-box  !important;
  border-radius: 10px  !important;
  color: #A5A5A5 !important;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;


}


.createServiceLeftContainer input[type="text"]{
  background: #c4c4c4 !important;
  color: #000000 !important;
  height: 49px !important;
}