.paymentContainer {
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
.profilemainContainer {
  width: calc(100vw - 500px);
  background: #FFFFFF;
  padding-bottom: 40px;
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: auto;
}
.myProfileTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  margin-left: 40px;
  margin-bottom: 20px;
  margin-top: 10px;

}

.userProfileBoxp .profileImageCircle {
  
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border-radius: 86px;
  position: relative;
  margin-top: 15px;
  margin-right: 20px;
}
.userProfileBoxp .profileImageCircle::before {
  position: absolute;
  content: '';
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border-radius: 86px;
  border: 5px solid #4453D6;
}
.userProfileBoxp .profileImageCircleImg {
  
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 86px;
}
.innerContainer {
  margin-left: 35px;
}
.userProfileBoxp {
  padding: 5px;
  /* background: #ffffff; */
  border-radius: 9px;
  margin-top: 10px;
  margin-right: 20px;
  /* width: calc(100vw - 200px); */
  min-width: 500px;
  display: flex;
  justify-content: flex-start;
}

.userProfileBoxp .usernamep {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 37px;
  margin-top: 1px;
  color: #333333;
  padding-top: 0px;
  margin-bottom: 7px;
}

.userProfileBoxp .workplace {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  line-height: 19px;
  margin-bottom: 5px;
  padding-top: 10px;
  display: flex;
}
.workplace img {
  width: 25px;
}
.userProfileBoxp .univercityplace1 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4453D6;
  line-height: 19px;
  margin-bottom: 5px;
  display: flex;

}
.univercityplace1 img {
  width: 18px;
  margin-right:5px;
}
.userProfileBoxp .localtion {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  margin-bottom: 5px;
  align-items:baseline;
  line-height: 19px;

  display: flex;
}
.localtion img {
  width: 15px;
}
.userProfileBoxp .editProfileButton {
  /* position: absolute;
  right: 0; */
  
  background: #4453D6;
  border-radius: 30px;
  box-sizing: border-box;
  border: none;
  padding: 10px;
  font-family: Segoe;
  margin-top: 60px;
  width: 249px;
  height: 49px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;

 
}

button:focus {
  outline: 0px !important;
}

.userListSearchBox {
  cursor: pointer;
  min-height: 65px;
}
.userListSearchBox img {
  /* width: 50px; */
  margin-right: 24px;
  /* margin-top: 6px; */

  width: 4.6vw;
  height: 4.6vw;
  border-radius: 2.3vw;
  min-width: 25px;
  min-height: 25px;
}
.userListSearchBox .username {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 4px;
}
.userListSearchBox .workstatus {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  opacity: 0.7;
  margin-bottom: 5px;
}
.userListSearchBox .aboutus {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  opacity: 0.7;
  margin-bottom: 5px;
}
.userListSearchBox .noDataFound {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  opacity: 0.6;
  position: relative;
  margin: auto;
  width: 220px;
  text-align: center;
  top: 100px;
}

.profileDiv {
  height: 100px !important;
  width: 100%;
  background: white;
  border-radius: 10px;
}
.txtColor {
  color: #fff !important;
}
.padd10 {
  padding: 10px;
}
.padd14 {
  padding: 14px;
  margin-top: 17px;
  word-break: break-word;
}
.marginT10 {
  margin-top: 10px;
}

.card {
  width: 100%;
  background: #333333;
  border-radius: 9px;
  padding: 10px;
}

.buttonCard {
  width: 100%;
  background: #333333;
  border-radius: 9px;
}
.titleP {
  margin-bottom: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-top: 2px;
  font-family: Segoe;
  color: #ffffff;
}

.titlePColor {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  
  color: #FFFFFF;
  
  opacity: 0.6;
}
.nameStyle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  padding-top: 2px;
  color: #FFFFFF;
}
.dateText {
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}


.borderBotton {
  border-bottom: 1px solid #adadad;
}
.BusinessProfilebuttonStyle {
  background: #4453D6;
  width: 200px;
  border-radius: 30px 0px 0px 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  height: 49px;
  color: #ffffff;
  float: right;
  margin-bottom: 10px;
}
.columnTitle {
  margin-bottom: 20px;
}
.editButtonIcons {
  background: transparent;
  border: none !important;
  float: right;
  margin-top: -10px;
}
.editButtonIcons img {
  width: 30px;
}
.editButtonIcons:hover,
.editButtonIcons:active,
.editButtonIcons:focus {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

/* top search bar  start */
.searchBox {
  display: -webkit-inline-box;
  /* margin-left: 70px; */
  margin-left: 245px;
  margin-top: 10px;
  /* z-index: 99999999 !important; */
  position: absolute;
  top: 0px;
}
.searchBox img {
  width: 20px;
  margin-top: 11px;
  opacity: 0.5;
}
.searchBox input {
  width: 500px;
  background: transparent;
  border: none;
  margin-left: 5px;
  margin-top: -10px;
}

/* top search bar end */

.editProfessionScroll {
  overflow: hidden;
  overflow-y: scroll;
  height: 85vh;
  margin-top: 10px;
  padding-top: 10px;
}

.editProfessionScroll1{
  overflow: hidden;
  overflow-y: scroll;
  height: 85vh;
  margin-top: 20px;
}
 .mLeft20{
  margin-left: 30px;
}
.widthDetails{
  width: 250px;
}
.profiledetailScroller{
    width: 100%;
    overflow: hidden;
    display: -webkit-inline-box;
    margin-bottom: 100px;
    
}
.userStatusText{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;

  color: #333333;
}
.profileTaglineMessage{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-top: 0px !important;
  color: #17171D;

  opacity: 0.5;
}
@media only screen and (min-width: 300px) and (max-width: 800px) {


  .profilemainContainer {
    width: 100%;
    font-family: Helvetica;
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100vh - 10px);
    width: calc(100vw - 10px);
    overflow-x: auto;
    background-color: #ffffff;
    padding-bottom: 40px;
  }
}

.headeingname2 {
  text-align: center;
  height: 40px;
  width: 175px;
  line-height: 37px;
  background: #4453d6;
  border-radius: 0px 30px 30px 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.servicewdklhfs2 {
  margin-top: 10px;
  background: #4453d6;
  border-radius: 30px;
  height: 40px;
  width: 100%;
  text-align: center;
  padding: 10px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
}
.viewallbuton2 {
  height: 50px;
  width: 130px;
  margin-top: 10px;
  background: #4453d6;
  border-radius: 30px 0px 0px 30px;
  border: none;
  cursor: pointer;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.ratingdiv2 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  text-align: center;
  color: #ffffff;
}
.reviiewscn2 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin: 0px;
  color: #a5a5a5;
}
.top1002 {
  background: #f5d028;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #17171d;
  border: none;
}
.order292 {
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-top: 10px;
}


.FrankPa2 {
  margin: 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 10px;
}
.date26012 {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  color: #ffffff;
  padding-left: 10px;
  /* opacity: 0.6; */
}
.timelkcnfjrf2 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
}

.profileCardBox{
  width:  472px;
  background: #333333;
  border-radius: 10px;
  padding-top: 20px;
  margin-right: 25px;
  min-height: 220px;
  padding-bottom: 20px;
}
.profileCardBox .innerContentBox{
  padding: 10px;
  padding-left: 25px;
  padding-right: 20px;
}
.profileContentDiv{
  width: 111px;
}
.profileContentTitleDiv{
  width: 90px;
}
.reputationHeading{
  width:  158px;
  height: 44px;
  background: #4453D6;
  border-radius: 0px 30px 30px 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  padding: 11px;
  padding-left: 25px;
  color: #FFFFFF;
}

.profileeducationCardBox{
  width:  313px;
  background: #333333;
  border-radius: 10px;
  padding-top: 20px;
  margin-right: 25px;
  min-height: 120px;
  margin-right: 15px;
  overflow: auto;
  padding-bottom: 10px;
}
.profileeducationCardBox .innerBox{
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 0px
}
.educationBlueButton{
  width: 161px;
  height: 44px;
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  background: #4453D6;
  border-radius: 0px 30px 30px 0px;
  color: #FFFFFF;
  padding: 10px;
  padding-left: 20px;
}
.profileregisterBoxInnerContent{
  padding-left: 25px;
  padding-right: 20px;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */

}



.Profilebackbutton{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #666666;
  background: transparent;
  border: none;
  margin-top: 0px;

}
.Profilebackbutton:hover{
  background: transparent;
  color: #666666;
}
.Profilebackbutton div{
  width: 25px;
  height: 25px;
  background: #4453D6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
 

}
.Profilebackbutton span{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
}
.Profilebackbutton img{
  width: 15px;
}
.noBusinessYet{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  padding-left: 25px;
  color: #FFFFFF;

  opacity: 0.6;
}
.addNewBusiness{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  border: 0px;
  color: #FFFFFF;
  background: #4453D6;
  border-radius: 30px;
  width: 190px;
  height: 36px;
}





/*
.widthDetails{
  width: 215px;
}
@media only screen and (min-width: 300px) and (max-width: 800px) {
  .widthDetails{
    width: 277px;
  }
  .widthInfoCard{
    width: 277px;
  }
  .mLeft20{
    margin-left: 0px;
  }
  .marginT15forMedia{
    margin-top: 15px;
  }
} */
/* .widthName{
  width: 40%;
} */

.editTagline{
  display: inline-flex;
  margin-top:15px; 
}
.editTagLineButton{
  background: transparent !important;
  border: none !important;
  padding: 0px !important;
  margin-left: 5px;
}
.editTaglineInputBox{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: #FFFFFF;
  background: #6C6C6C !important;
  border-radius: 30px;
  padding: 7px;
  padding-left: 16px;
  min-width: 250px;
  max-width: 316px;
}
.saveTagLine{
  border: 1px solid #6C6C6C;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  color: #6C6C6C !important;
  background: transparent !important;
  width: 138px;
  height: 37px;
  margin-left: 7px;

}


@media only screen and (min-width: 400px) and (max-width: 1025px) {
  .profilemainContainer{
    width: calc(100vw - 10px);
  }
}

@media only  screen and (min-width: 1920px){


  .profilemainContainer {
    /* width: 100%; */
    font-family: Helvetica;
    /* overflow: hidden;
    overflow-y: scroll;
    height: calc(100vh - 10px); */
    width: calc(100vw - 800px);
    /* overflow-x: auto; */
    /* background-color: #ffffff; */
    height: calc(100vh - 10px);
    padding-bottom: 40px;
    margin-left: 300px;
    margin-top: 25px;
    position: absolute;
    top: 10px;
  }
  .userProfileBoxp {
    width: 100%;
  }
}

.noReviewText{
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  margin-top: 20px;
}
