
.paymentContainer{
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
.mainContainerPacks{
  width: 100%;
  height: calc(100vh - 50px);
  padding-left: 10px;
}
.ListContainer{
  overflow: hidden;
  height: calc(100% - 100px);
  overflow-y: scroll;
}

.uploadImageTitle{
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
  margin-left: 50px;
  margin: 20px

}
.mediaListBackground{
  background: #0C0F1D;
  margin-top: 10px;
  cursor: pointer;
}

.mediaListBackground p{
  margin-bottom: 5px;
}
.listImageIcon{
  margin-right: 20px;
  width: 162px;
  /* height: 128px; */
}
.mediaTitle{
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #FFFFFF;
}
.mediaDescription{
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  color: #FFFFFF;
}


.plusButton{
bottom: 25px;
  position: fixed;
  right: 50px;

  height:60px ;
  width: 60px;
  border-radius:58px;
  border: 0px;
  background: linear-gradient(180deg, #4092B7 0%, #4358A7 100%);
}
.plusButtonImg{
  width: 22px;
}
.pictureTitle{
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}
.pictureMsg{
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;

}

.inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile:focus + label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
  
}
.inputfile + label * {
  pointer-events: none;
}
.labelButton{
  padding: 30px;
  background: #0C0F1D;
  border: 1px dashed rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 10px;
}

.errormessage{
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-top: 5px;
  color: #FF3C3C;
}
.uploadButton{
  background: linear-gradient(180deg, #439FC8 0%, #4358A7 100%);
  border-radius: 5px;
  margin-top: 10px;
  width: 300px;
  border: 0px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 50px;
}


/*  Success Modal*/

.modalTitle{
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  /* identical to box height */
  text-align: center;
  color: #FFFFFF;
}

.successFullyUpload{
  font-family: Helvetica;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 23px;
text-align: center;

color: #FFFFFF;
}

.modalLabel{
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
color: #FFFFFF;
opacity: 0.5;
margin-bottom: 9px

}
.modelBoldDescription{
  font-family: Helvetica;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;
color: #FFFFFF;

}
.successImageICon{
  width: 100px;
  margin-top: 10px;
  margin-bottom: 15px;
}


.blueBackground{
  background: linear-gradient(180deg, #4092B7 0%, #4358A7 100%);
border-radius: 10px;
padding: 30px;
padding-top: 40px;
   /* border-bottom-left-radius: 120px;
   border-bottom-left-radius: 120px; */

}
.uploadAnother{
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #4092B7;
  margin-top: 20px;
  cursor: pointer;
}
.ModalContainer{
  background: #333333;
  border-radius: 10px
}
.closeModal{
  background: transparent;
  border: 0px;
  float: right;
  width: 30px;
  position: absolute;
  right: 50px;
  top: 25px;
}
.closeModal:hover{
  background: transparent;
}
.closeModal img{
  width: 40px;
}

.iframeSize{
  width:500px;
  height: 600px;
  border: 0px;
}
.iframeSizeDiv{
  width:100%;
  height: 600px;
  text-align: center;
}

.backButton{
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
  background: transparent;
  border: none;
}
.backButton:hover{
  background: transparent;
}

.pacsOverley{
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  cursor: pointer;
  background: #4453D6;
}