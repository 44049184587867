.sellersidemenumaindiv {
  mix-blend-mode: normal;
  padding-bottom: 30px;
  height: calc(100vh - 125px);
  overflow-x: hidden;
  overflow-y: auto;
}

.orCenterText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  margin: 8px;
  opacity: 0.8;
}

.bulkUploadbutton {
  width: 268px;
  height: 51px;
  background: #4453d6 !important;
  border-radius: 30px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #ffffff !important;
  display: inline-flex;
  align-items: center;
}

.createistinbuttong {
  width: 268px;
  height: 51px;
  background: #f5d028 !important;
  border-radius: 30px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #333333 !important;
  display: inline-flex;
  align-items: center;
}
.createistinbuttong p {
  margin-bottom: 0;
}
.sellersalesActionsedemn {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  padding-top: 15px;
}
.sellersalesrequimen {
  width: 404px;
  min-height: 110px;
  background: #17171d50;
  /* opacity: 0.6; */
  border-radius: 10px;
  display: flex;
  padding: 10px;
  margin: auto;
  margin-top: 19px;
  cursor: pointer;
  filter: alpha(opacity=80);
}
.sellernssedmekjpr {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin: 0px;
  padding-left: 15px;
}
.sellerrID10sefv {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.8;
  margin: 0px;
  padding-left: 15px;
}
.sellelacecdcdfvfd {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.8;
  margin: 0px;
  padding-left: 15px;
}
.sellerAwinsefkrnf {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.8;
  padding-left: 15px;
  display: flex;
  align-items: baseline;
}
.sellerlightsedemnei {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background: #4aea87;
  margin-right: 5px;
}
.sellerscsdvf500 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: right;
  color: #ffffff;
  padding-left: 10px;
}
.sellerdiversiddemenu {
  width: 404px;
  height: 0px;
  border: 1px solid #f5d028;
  margin: auto;
  margin-top: 41px;
}
.sellerMyessidem {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  padding-top: 31px;
  padding-bottom: 15px;
}
.sellerfbidemenudiv {
  width: 403px;
  min-height: 61px;
  background: #8b2fa0;
  border-radius: 30px;
  margin-top: 32px;
  margin: auto;
  display: flex;
  align-items: center;
}
.sellerdptionFeedback {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-left: 15px;
  flex: 1;
}
.sellerBrlysidemen {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin: 0px;
}
.sellerrsedeminr {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  opacity: 0.8;
}
.starRating {
  justify-content: flex-end;
  padding-right: 25px;
}

.dv-star-rating-star {
  margin-bottom: 0;
}

.dv-star-rating-empty-star {
  margin-bottom: 0;
}

.createNewProductModal {
  background: #17171d;
  border-radius: 30px;
  padding: 25px;
  width: 327px;
}
.createNewProductModal .headerTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
}
.createNewProductModal .descriptionText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

.createNewProductModal .selectOptionsOpacity {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  margin-top: 10px;
}

.selectDownMenu {
  background: #4453d6;
  border-radius: 30px;
  width: 270px;
  height: 49px;
  display: inline-flex;
  justify-content: space-between;
  padding: 15px;
  margin-top: 10px;
  cursor: pointer;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: #ffffff;
  z-index: 2;
  position: relative;
}
.selectDownMenu img {
  margin-top: 5px;
  width: 24px;
}
.dropDownDiv {
  background: #333333;
  text-align: left;
  /* padding-left: 20px; */
  position: absolute;
  z-index: 10;
  width: 270px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  align-items: center;
  color: #ffffff;
  top: 35px;
  padding-top: 25px;
  z-index: 1;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.dropDownDiv div {
  cursor: pointer;
  min-height: 47px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.ppeDiv {
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
  color: #000000;
}
.closeButtonIcon {
  background: transparent !important;
  border: 0px;
  outline: 0px;
  padding: 0px;
}
.closeButtonIcon img {
  width: 30px;
}
