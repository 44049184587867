.setuthentic {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
}
.setingauthvec {
  background: #333333;
  border-radius: 10px;
  display: flex;
  height: 60px;
  width: 400px;
  align-items: center;
  margin-top: 60px;
  cursor: pointer;
}
.changesetjkh {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  padding-left: 27px;
}
.srtgtghange {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #999999;
  padding-left: 27px;
}
.setingauthvec2 {
  background: #333333;
  border-radius: 10px;
  display: flex;
  height: 60px;
  width: 400px;
  align-items: center;
  margin-top: 13px;
}
.srtgtngese {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #999999;
  padding-left: 7px;
}
.authchangep {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  padding-left: 18px;
}
.setenternew {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-top: 24px;
  padding-left: 42px;
}
.setininetrne {
  background: #0c0f1d;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.5;
  border: none;
  padding-left: 10px;
  height: 60px;
  width: 350px;
  margin-left: 36px;
  margin-top: 27px;
}
.setininetrne2 {
  background: #0c0f1d;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.5;
  border: none;
  padding-left: 10px;
  height: 60px;
  width: 350px;
  margin-left: 36px;
  margin-top: 13px;
}
.setedbubbut2 {
  background: linear-gradient(180deg, #439fc8 0%, #4358a7 100%);
  border-radius: 5px;
  border: none;
  height: 49px;
  width: 250px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin-top: 26px;
  
}
.mesaagesetin{
font-style: normal;
font-weight: normal;
font-size: 16px;
text-align: center;
color: #FF3C3C;
padding-top: 25px;

}
.nseewspassa{
font-style: normal;
font-weight: bold;
font-size: 20px;
text-align: center;
color: #FFFFFF;
padding-top: 47px;
}
.setinjhftext2{
font-style: normal;
font-weight: normal;
font-size: 16px;
text-align: center;
color: #FFFFFF;
padding-top: 18px;
}