.message-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 10px;
  background: #FFFFFF;
  
}
.message-content > iframe {
  width: 98%;
  height: 98%;
  border: 0;
}
.messageContentGoBackBox{
  width: 100%;
  text-align: right;
  z-index: 10;
  padding-right: 10px;
}
.messageContentGoBack{
  background: #333333 !important;
  color: #FFFFFF !important;
  font-size: 18px;
  z-index: 10;
  border: none !important;
  outline: none !important;
  border-radius: 5px;
}
