.compose {
  /* position: absolute;
  bottom: 0px; */
  /* display: flex;
  position: absolute;
  bottom: -110px;
  right: 0;
  left: 0; */
  background: #19191980;
  width: 100%; 
  border-radius: 10px;
  min-height: 80px;
  height: max-content;

  margin-top: 10px;
  padding-bottom: 18px;
  padding-top: 22px;
  /* padding: 17px 25px; */
}

.sendButton {
  border-color: transparent;
  width: 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  /* margin: auto; */
  /* margin: 20px; */
  margin: auto 15px 15px 15px;
  background: transparent;

  color: #FFFFFF;
  padding: 0px;
  border: none;
  display: inline-block;
  vertical-align: baseline;
}

.sendButton:last-child {
  margin-right: 20px;
}

.sendButton:hover, .sendButton:active, .sendButton:focus{
  background: transparent;
  color: #FFFFFF;
  border: none;

}  

.compose-input-wrapper{
  width: 100%;
  /* min-height: 50px; */
  display: inline-flex;
  /* align-items: center;
  position: relative; */
  background: #17171D80 !important;
  border-radius: 30px;
  padding-top: 15px !important;
  padding: 20px;
  padding-bottom: 0px !important;
  padding-left: 0px !important;




}


.compose-input {
  /* flex: 1; */
  border: none;
  font-size: 14px;
  background: transparent !important;
  color: #FFFFFF;
  border-radius: 30px;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  resize: none;
  max-height: 300px;
  padding-left: 20px;
  margin-bottom: 10px;
  height: 30px;
  min-height:30px;

}

.compose-input-placeholder{
  display: flex;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  align-items: center;
  opacity: 0.5;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 22px;
  padding: 25px !important;
  pointer-events: none;
}

.compose-input:focus{
  outline: none !important;
}


.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}
.composelabelButton{
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  margin: auto 15px 15px 15px;
}

.composelabelButton img {
  
  width: 34px;
  height: 34px;
}

.inputfile{
  display: none;
}

.filePreViewModal{
  padding-top: 10px !important;
  padding-right: 10px !important;

  padding: 20px ;
  background: #4453D6;
  width: 725px;
  border-radius: 30px;
  min-height: 200px;

}

.closePreviewActionButton{
  background: transparent !important;
  border: none !important;
  padding: 0px !important;
}

.messageAttcahementTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;

  color: #EEF3F7;
}

.fileCaptionBox{
  width: 350px;
}


.radioButtonsBox [type="radio"]:checked,
.radioButtonsBox [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.radioButtonsBox [type="radio"]:checked + label,
.radioButtonsBox [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;

    color: #FFFFFF;
}

.radioButtonsBox{
  margin-left: 20px;
}


.radioButtonsBox .radioButtonItem{
    min-width: 200px;
    margin-top: 5px;
}

.radioButtonsBox [type="radio"]:checked + label:before,
.radioButtonsBox [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #FFFFFF;
    border-radius: 100%;
    background: transparent;
}
.radioButtonsBox [type="radio"]:checked + label:after,
.radioButtonsBox [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.radioButtonsBox [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.radioButtonsBox [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.captionLabel{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  padding-left: 10px;
}
.captionTextAreaInput{
  background: #FFFFFF;
  border-radius: 10px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #17171D;;
  padding-left: 10px;
  resize: none;
  min-height: 270px;
  width: 324px;
  margin-top: 10px;
}
.sendAttachmentButton{
  height: 54px;
  width: 235px;
  border-radius: 30px;
  background: #17171D !important;
  border-radius: 30px !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  box-shadow: none !important;
  border: none !important;

  color: #FFFFFF !important;

}


.composefileListingNameBox{
  border-radius: 10px;
  width: 308px;
  height: 55px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 7px;
}

.composefileListingNameBox .fileNameWhite{
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;

  color: #FFFFFF;
  width: 230px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: inline-block;
  margin-left: 5px;
}

.composefileListingNameBox .fileSizeWhiteStatus{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #FFFFFF;
  opacity: 0.8;
}

.fileCaptionBox .removeFileButton{
    position: absolute;
    right: 0px;
    padding: 0px;
    width: 29px;
    height: 29px;
    background: #FFFFFF;
    border-radius: 100%;
    top: 10px;
}
.fileCaptionBox .ComposeFilesScroller{

  height: 205px;
  overflow: auto;
  margin-bottom: 10px;

}
