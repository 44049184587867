.Rectangle19 {

  float: right;
  width: 252px;
  height: 61px;

  background: #4ca9b7;
  border-radius: 10px;
  cursor: pointer;
}
.GENERATEA {
  /* GENERATE A */
  padding-left: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #ffffff;
}
.ServiceRequest {
  /* Service Request */
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  padding-left: 15px;
  color: #ffffff;
}
.Rectangle08 {
  /* Rectangle 22 */
  align-self: center;
  text-align: center;
  width: 37px;
  height: 38px;
  margin-left: 20px;
  background: #ffffff;
  border-radius: 8px;
}
.MyPlacedRequests {
  /* My Placed Requests */

  padding-top: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;

  color: #45443c;
}
.Rectangle09 {
  /* Rectangle 20 */

  width: 74.95px;
  height: 31px;

  background: #4ca9b7;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  text-align: center;

  color: #ffffff;
}

.Draft {
  /* Draft */

  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  text-align: center;

  color: #a5a5a5;
}
.Placed {
  /* Placed */

  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  text-align: center;

  color: #a5a5a5;
}
.Awarded {
  /* Awarded */

  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  text-align: center;

  color: #a5a5a5;
}

.Confirmed {
  /* Confirmed */

  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  text-align: center;

  color: #a5a5a5;
}
.Started {
  /* Started */

  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  text-align: center;

  color: #a5a5a5;
}
.JobComplete {
  /* Job Complete */

  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  text-align: center;

  color: #a5a5a5;
}
.Completed {
  /* Completed */

  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  text-align: center;

  color: #a5a5a5;
}
.Rectangle212 {
  /* Rectangle 21 */

  background: #4453d6;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 305px !important;
  margin: 5px !important;
  height: 211px !important;
  padding: 25px !important;
}

.Request {
  /* Request: #137512 */
  padding-bottom: 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #c4c4c4;
}
.Lorenda {
  /* Tanya Lorenda */
  padding-left: 13px;

  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  align-items: center;

  color: #ffffff;
}
.dateservice {
  /* 11/4/2019 */
  padding-left: 10px;

  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  align-items: center;

  color: #ffffff;
}
.Awarded {
  /* Awarded */

  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  align-items: center;

  color: #ffffff;
}
.Rectangle00 {
  /* Rectangle 22 */
  margin-top: 8px;
  /* margin-left: 15px; */
  width: 200px;
  height: 7px;

  background: #c4c4c4;
  border-radius: 10px;
}
.Rectangle007 {
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 305px !important;
  margin: 5px !important;
  height: 211px !important;
  padding: 25px !important;
}
.GEServicing {
  /* GE Servicing Session */
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 17px !important;
  color: #45443c !important;
  padding: 0px !important;

}
.RequestNA {
  /* Request: N/A */

  color: #c4c4c4;
  padding-bottom: 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #c4c4c4;
}
.MartinJames {
  /* Dr. Martin James */

  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  padding-left: 14px;

  color: #a5a5a5;
}
.date2 {
  /* 10/31/2019 */

  padding-left: 11px;

  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #a5a5a5;
}
.Draft {
  /* Draft */

  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #a5a5a5;
}
.Rectangle100 {
  /* Rectangle 22 */

  /* Rectangle 22 */

  margin-top: 8px;
  width: 200px;
  height: 7px;

  background: #c4c4c4;
  border-radius: 10px;
}
.rect {
  padding: 5px;
  /* Rectangle 21 */
  display: flex;
  align-items: center;
  width: 100%;
  height: 58px;
  justify-content: space-around;
  margin-top: 21px;
  background: #4453d6;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.Request137512 {
  /* Request: #137512 */

  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #c4c4c4;
}
.SiemensServicing {
  /* Siemens Servicing Session */
  font-style: normal !important;
    font-weight: bold !important;
    font-size: 17px !important;
    color: #ffff !important;
    padding: 0px !important;
}
.tanyaa {
  /* Tanya Lorenda */

  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #ffffff;
}
.reclo {
  /* Rectangle 22 */

  width: 200px;
  height: 7px;

  background: #c4c4c4;
  border-radius: 10px;
}
.rect2 {
  /* Rectangle 21 */
  display: flex;
  align-items: center;
  width: 100%;
  height: 58px;
  justify-content: space-around;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 5px;
}
.Requestttt {
  /* Request: #137512 */

  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #c4c4c4;
}
.Sessionnn {
  /* Siemens Servicing Session */

  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #45443c;
}
.DrMartinJames {
  /* Dr. Martin James */

  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #a5a5a5;
}
.dateee {
  /* 10/31/2019 */

  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #a5a5a5;
}
.reclo {
  /* Rectangle 22 */

  width: 253px;
  height: 7px;

  background: #c4c4c4;
  border-radius: 10px;
}
.AwardedText {
  /* Draft */

  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #ffff;
}
.Drafttt {
  /* Draft */

  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #a5a5a5;
}

.notificationCSSMyRequest{
  width: 480px !important;
  height: calc(100vh - 10px);
  overflow: hidden;
  overflow-y: scroll;
}
.parentRowMyRequest{
  padding: 30px;
  width:calc(100vw - 490px) !important;
  background: #FFFFFF !important;
  overflow-x: hidden;
  height:calc(100vh - 10px) !important;
}
.inlineFlex100MyRequest{
  width:100% !important;
  display: inline-flex !important;
}
.scrollBehaveXMyRequest{
  overflow-x: auto;
  padding: 10px;
  /* width:800px */
}


.buttonBar{
  margin-top: 10px;
  margin-bottom: 10px;
  display: 'flow-root';

}
.buttonBarMyRequest{
  display: inline-flex;
}

.buttonBarMyRequest button{
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #A5A5A5;
  background: transparent;
  border: none;
  /* min-width: 70px; */
  height: 30px !important;
  margin-right: 10px !important;
}

.activeButtonMyRequest{
  background: #4CA9B7 !important;
  border-radius: 10px !important;
  color: #FFFFFF !important;
  border: none !important;
  /* min-width: 100px !important; */

}
.buttonBarMyRequest  button:hover{
  background: #4CA9B7  !important;
  color: #FFFFFF !important;
  border-radius: 10px !important;
  /* min-width: 100px; */
}
.buttonBarMyRequest button:focus,button:active{
  border: 0px !important;
  box-shadow: none !important;
}
.mLeft5{
  margin-left: 5px !important
}
.AwardeddotMyRequest{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #F5D028;
  margin-top: 3px;
}
.CompleteLeaveFeedbackdotMyRequest {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #F58A28;
  margin-top: 3px;
}
.placeddotMyRequest {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #4180B2;
  margin-top: 3px;
}
.ConfirmeddotMyRequest {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #94D6D9;
  margin-top: 3px;
}
.StarteddotMyRequest {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #4AEA87;
  margin-top: 3px;
}
.completeddotMyRequest{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #A5A5A5;
  margin-top: 3px;
}
.inline{

  display: inline-flex;

}


.serviceOnDemandPlaceRequestBoxes{
  flex-wrap: wrap;
  display: inline-flex;
  margin-top: 20px;

}

.filterButtons{
  /* min-width: 100px; */
}
.noPlaceRequest{
  text-align: center;
  font-size: 18px;
  padding: 20px;
}