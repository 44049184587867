.toolbar {
  display: flex;
  align-items: center;
  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;
  /* position: sticky; */
  top: 0px;
  padding: 10px 0px;
  min-height: 80px;
  position: relative;
  z-index: 1 !important;
}

@supports (backdrop-filter: blur(20px)) {
  .toolbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    /* backdrop-filter: blur(20px); */
  }
}

.toolbar-title {
  margin: 0;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
}

.videoIconContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 75px;
}

.videoIcon{
  height: 35px;
  width: 35px;
  object-fit: contain;
}

.muteIcon {
  height: 20px;
  width: 20px;
  object-fit: contain;
  align-self: center;
  margin-left: 5px;
}

.muteIconContainer{
  display: flex;
  flex-direction: row;
}
.left-items, .right-items {
  flex: 1;
  padding: 10px;
  display: flex;
  padding-right: 100px;
}

.right-items {
  flex-direction: row-reverse;
}

.left-items .toolbar-button {
  margin-right: 20px;
}

.right-items .toolbar-button {
  margin-left: 20px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 0;
}

.crumb-container {
  position: absolute;
  top: calc(40% + 1px);
  transform: translateY(-50%);
  right: 30px;
  cursor: pointer;
}

.crumb-container .crumb-text {
  color: white;
  text-align: right;
  display: flex;
  align-items: center;
}

.crumb-container .child {
  background-color: white;
  font-size: 0.9rem;
  min-width: 120px;
  padding: 5px 10px;
}

.actionImages{
  width: 25px;
  height: 25px;
  cursor: pointer;
}





.dropdownArrowGroupDetailChat{
  position: absolute;
  /* top: 48px;
  left: -146px; */
  left: 0;
  right: 0;
}
.dropdownGroupChat{
  display: block;
  top: 14px;
  right: 0;
  position: absolute;
  width: 184px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #17171D;
  opacity: 0.8;
  border-radius: 10px;
  /* z-index: 100; */
}

.dropdownGroupChat div {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  cursor: pointer;
  padding: 5px 10px;
}
.dropdownGroupChat a , .dropdownGroupChat a:hover {
  color: #FFFFFF;
  text-decoration: none;
}


.chatStatusCircle{
  position: relative;
  right: 20px;
  top: 30px;
}
.ChatActiveStatus{
  width: 15px;
  height: 15px;
  border-radius: 50px;
  margin: 4px;
}