.mainContactContainer {
  width: 100%;
  font-family: Helvetica;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.contact-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  background: #FFFFFF;
  width: 100vw;
}
.contact-container .contact-container-parent {
  flex: 3 1;
  padding: 0px 5px;
}
.lightBlueTextHiglight{
  color: #B4BAEF !important;
  background: transparent !important;
  padding: 0px !important;
}

.darkBlueTextHiglight{
  color: #4453D6 !important;
  background: transparent !important;
  padding: 0px !important;
}


.searchContactContainer {
  display: flex;
  width: calc(100vw - 500px);
  flex-direction: column;
  /* border-right: 2px solid #aaa; */
  padding-left: 30px;
  padding-right: 10px;
  height: calc(100vh - 10px);
  overflow: auto;
  padding-bottom: 80px;
}

/* width */
/* .searchContactContainer::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
/* .searchContactContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #cccccc; 
  border-radius: 10px;
} */
 
/* Handle */
/* .searchContactContainer::-webkit-scrollbar-thumb {
  background: #17171D !important; 
  border-radius: 10px;
} */

.searchTitle {
  /* font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff; */
  /* margin-top: 50px;
  margin-left: 20px; */
  font-family: 'Segoe';
  font-style: normal;
  font-size: 25px;
  line-height: 34px;
  margin:20px;
  color: #17171D;
}
.searchTitle span{
  font-weight: bold;

}

/* top search bar  start */
.contactsearchBox {
  display: -webkit-inline-box;
  margin-left: 17px;
  
}
.searchContactInputDiv{
  position: relative;
  z-index: 1;
  width: 468px;
  height: 49px;

}
.searchContactInputDiv::before{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #C4C4C450;
  opacity: 0.4;
  width: 468px;
  height: 49px;
  border-radius: 300px;
  z-index: -1;

}
.searchContactInputDiv img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 34px;
  height: 34px;
  margin-left: 13px;
}
.searchContactInputDiv input {
  width: 100%;
  background: transparent !important;
  border: none;
  padding: 0;
  height: 100%;
  color: #17171D !important;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
}

.contactsearchBox input::placeholder{
  color: #17171D !important;
}
.contactsearchBox .searchButton{
  background: #4453D6 !important;
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  text-align: center;
  color: #FFFFFF !important;
  border-radius: 30px;
  width: 170px;
  height: 49px;
  margin-left: 11px;
  padding: 0;
  padding-left: 19px;
}

.contactsearchBox input:focus {
  background: transparent;
  color: #17171D;
}



/* top search bar end */

.contactListing {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  padding: 10px 0px 10px 20px;
  min-height: 80px;
  align-items: center;
}

.contactListing .contactListing-image-container {
  display: flex;
  /* flex: 1; */
  margin-right: 10px;

  align-items: center;
}

.contactListing-image {
  width: 50px;
  height: 50px;
  border-radius:100px;
}

.text-image {
  color: #ffffff;
  background-color: #4092b7;
  width: 2.6vw;
  height: 2.6vw;
  border-radius: 1.3vw;
  min-width: 25px;
  min-height: 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.contactListing .contactListing-info {
  display: flex;
  flex: 5;
  flex-direction: column;

}

.contactListing .contactListing-info .contactListing-name {
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 28px;

  color: #ffffff;
}

.contactListing .contactListing-info .contactListing-profile {
  font-family: "Segoe";
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  color: #ffffff;

  opacity: 0.7;
}
.contactListing .contactListing-info .contactListing-moreInfo {
  font-family: "Segoe";
  font-style: italic;
  font-weight: normal;
  font-size: 16px;

  color: #ffffff;

  opacity: 0.7;
}

.contactListing .contactListing-info div {
  display: flex;
  flex: 1;
}

.contactScrollView {
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 200px);
}

/* width */
/* .contactScrollView::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
/* .contactScrollView::-webkit-scrollbar-track {
  background: #17171D !important; 
  opacity: 0.5;
  border-radius: 10px !important;
} */
 
/* Handle */
/* .contactScrollView::-webkit-scrollbar-thumb {
  background: #4453D6 !important; 
  opacity: 0.8;
  border-radius: 10px !important;
} */

.searchcontactListing {
  display: flex;
  flex-direction: row;
  border-top: 1px dashed rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 10px 0px 10px 20px;
  min-height: 80px;
  align-items: center;
}

.searchcontactListing .searchcontactListing-image-container {
  /* display: flex; */
  /* flex: 1; */
  display: inline-block;
  margin-right: 10px;
  align-items: center;
  position: relative;
}
.searchcontactListing-div {
  position: relative;
  /* width: 50px;
  height: 50px;
  border-radius: 86px;
  align-items: center;
  display: flex;
  overflow: hidden;
  border: 2px solid #4453D6; */


}.searchcontactListing-div::before {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 86px;
  align-items: center;
  display: flex;
  overflow: hidden;
  border: 2px solid #4453D6;


}
.searchcontactListing-imageDiv{
  position: relative;

  /* width: 70px;
  height: 70px;
  border-radius: 86px;
  border: 2px solid #FFFFFF;
  align-items: center;
  display: flex;
  overflow: hidden; */
}
.searchcontactListing-imageDiv::before{
  content: '';
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 86px;
  border: 2px solid #FFFFFF;

}
.userContactSearchStatusCircle{
  width: 16px;
  height: 16px;
  border-radius: 50px;
  align-items: inherit;
  float: right;
  margin-right: 8px;
}
.userContactSearchStatusCircleSmall{
  width: 16px;
  height: 16px;
  border-radius: 50px;
  align-items: inherit;
  float: right;
}

.searchcontactListing-image {
  width: 70px;
  height: 70px;
  border-radius: 86px;
  /* min-width: 50px;
  min-height: 50px; */
  /* border: 2px solid #FFFFFF; */
}
.searchIscontactListing-image {
  width: 50px;
  height: 50px;
  border-radius: 86px;
  /* min-width: 50px;
  min-height: 50px; */
  /* border: 2px solid #4453D6; */
}


.searchIscontactListing-imageDiv {
  width: 50px;
  height: 50px;
  border-radius: 86px;
  /* min-width: 50px;
  min-height: 50px; */
  border: 2px solid #4453D6;
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
}
.searchIscontactListing-businessiconBox{
  text-align: right;
  /* position: relative;
  top: -20px; */
  position: absolute;
  width: 100%;
  bottom: 0px;
  display: inline-flex;
  justify-content: flex-end;
}

.searchcontactListing .searchcontactListing-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 10px;
}
.searchcontactListing .searchIscontactListing-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 10px;
  min-width: 250px;
  max-width: 400px;
}
.searchcontactListing .searchIscontactListing-info .searchIscontactListing-name {
  margin-bottom: 3px;

  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 24px;

  color: #333333;

}

.searchcontactListing .searchcontactListing-info .searchcontactListing-name {
  margin-bottom: 3px;

  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #FFFFFF;

}

.searchcontactListing .searchcontactListing-info .searchcontactListing-profile {

  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #FFFFFF;

  opacity: 0.7;
}
.searchcontactListing .searchIscontactListing-info .searchIscontactListing-profile {

  font-family: 'Segoe';
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;

  color: #333333;

  opacity: 0.7;
  text-transform: capitalize;
}
.searchcontactListing
  .searchcontactListing-info
  .searchcontactListing-moreInfo {
    font-family: 'Segoe';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    
    color: #FFFFFF;
    
    opacity: 0.7;

}
.searchcontactListing
  .searchIscontactListing-info
  .searchIscontactListing-moreInfo {
    font-family: 'Segoe';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    
    color: #333333;
    
    opacity: 0.7;
}

.contact-container-parent {
  flex: 3;
}
.no_contact_data {
  color: #ffffff;
  opacity: 0.5;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}

.searchcontactListing .searchcontactListing-info div {
  display: flex;
  flex: 1;
}

.searchContactViewScroll {
  margin-top: 30px;
  width:calc(100vw - 500px) ;
  max-width: 915px;

  /* height: calc(100vh - 250px); */
  /* overflow: hidden; */
  /* overflow-y: scroll; */
 
}

.addContactButtonList {
  /* margin: 15px; */
  background: #4453D6 !important;
  border-radius: 30px;
  width: 190px;
  height: 37px;
  color: #FFFFFF !important;
  border: 0px;
  margin-right: 15px;
  margin-top: 5px;

  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;

}
.removeContactButton {
  /* margin: 15px; */
  background: #a5a5a5;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: 150px;
  height: 42px;
  color: #ffffff;
  border: 0px;
  margin-right: 15px;
  margin-top: 5px;
}
.chatButton {
  border: 0px !important;
  background: #F5D028;
  border-radius: 30px;
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  width: 190px;
  color: #17171D;
  height: 37px;
  margin-right: 20px;
}
.chatButton img {
  width: 40px;
}

.chatButton:focus,
.chatButton:hover,
.chatButton:active {
  background: #F5D028;
  border: 0px !important;
  color: #17171D;
}

.no-searchcontactListing {
  color: #000000;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  opacity: 0.5;
  margin-top: 50px;
}

.chatButton1 {
  background: transparent;
  border: 0px !important;
  width: 30px;
  padding: 0px;
  float: right;
  margin-top: 20px;
  margin-right: 20px;
}
.chatButton1 img {
  width: 30px;
}
.myProfileTitle {
  text-transform: uppercase;
}
.globalSearchButton{
  display: flex;
  align-items: center;
  background: #4453D6;
  border-radius: 0px 30px 30px 0px;

  width: 235px;
  height: 44px;
  text-align: left;
  padding: 0;
  padding-left: 20px;
  border: none !important;
  font-size: 16px;

  margin-bottom: 15px;
}

.globalSearchButton div{
  padding-left: 11px;
}

.globalSearchButton img{
  width: 35px;
  height: 35px;
}

.globalSearchBox{
   background: #17171D;
   border-radius: 10px;
   padding-top: 15px;
   margin-left: 20px;
  width: 100%;
  min-width: 915px;

}
.mycontactSearchBox{
  /* background: #333333; */
  border-radius: 10px;
  padding-top: 15px;
  margin-bottom: 30px;
  margin-left: 20px;
  margin-top: 30px;
  width: 100%;
  min-width: 915px;


}

.myContactSearchButton{
  display: flex;
  align-items: center;
  /* background: #F5D028; */
  /* border-radius: 0px 30px 30px 0px; */
  text-align: left;
  padding: 0px;
  color: #17171D;
  margin-bottom: 15px;
  height: 44px;
  font-size: 25px;
  line-height: 34px;
}


/* .myContactSearchButton img{
  width: 30px;
  height: 30px;
} */




.requestConfirmationModal{
  background: #17171D;
  border-radius: 30px;
  padding: 23px;
}
.requestConfirmationModal .confirmmsg{
  
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.8;
}
.requestConfirmationModal .confirmTitle{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  
}
.requestConfirmationModal .buttons{
  min-width: 100px;
  height: 40px;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  border-radius: 30px;
 
  
  border-radius: 30px;
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  color: #FFFFFF !important;
  border: 0px;

}
.requestConfirmationModal .noButton{
  background: #FFFFFF50;
  color: #FFFFFF !important;

}
.requestConfirmationModal .yesButton{
  background: #4453D6;
  /* border-radius: 30px; */
  margin-left: 10px;
}
.requestConfirmationModal .buttonDiv{
  display: inline-flex;
  float: right;
}
.isSearchContactLabel{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin: 0px;
  color: #17171D;
  margin-left: 30px;
  margin-right: 25px;
  cursor: pointer;
}

.isSearchContactLabel > input[type='radio']{
  width: 20px !important;
}
.isSearchContactLabel > input[type='radio']:after {
  width: 22px;
  height: 22px;
  border-radius: 50px;
  /* top: -2px;
  left: -1px; */
  /* position: relative; */
  background: none !important;
  content: '';
  display: inline-block;
  visibility: visible;
  border: none !important;
  padding: 8px;
  right: 0px;
  bottom: 0px;
  position: relative;
}

.isSearchContactLabel input[type='radio']:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background: #000000;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #000000;
  padding: 8px;
  right: 0x;
  bottom: 0px;
  position: relative;

}
.IsContactActionButton{
  background: transparent !important;
  border: none !important;
  margin-left: 15px;
}
.contactModalBackdrops{
  background:  #4453D6;
  opacity: 0.8 !important;
}



.searchContactbackbutton{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #666666;
  background: transparent;
  border: none;
  margin-top: 20px;

}
.searchContactbackbutton:hover{
  background: transparent;
  color: #666666;
}
.searchContactbackbutton div{
  width: 25px;
  height: 25px;
  background: #4453D6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
 

}
.searchContactbackbutton span{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
}
.searchContactbackbutton img{
  width: 15px;
}



@media only screen and (min-width: 400px) and (max-width: 1025px) {
  .searchContactContainer {
    display: flex;
    width: calc(100vw - 10px);
    flex-direction: column;
    /* border-right: 2px solid #aaa; */
    /* padding-left: 30px;
    padding-right: 30px; */
  }


}

@media only screen and (min-width: 1920px)   {
  .searchContactContainer {
    display: flex;
    width: calc(100vw - 800px);
    flex-direction: column;
    /* border-right: 2px solid #aaa; */
    margin-left: 300px;
    position: absolute;
    top: 0px;
  }
    .searchContactbackbutton{
       margin-top:30px;
    }
}