.productBrowseContainer {
  /* background: '#FFFFFF'; */
  height: calc(100vh - 84px);
  display: inline-flex;
}

.Browsinxsdcg {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #17171d;
}
.arketplaceresults {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #45443c;
  padding-left: 15px;
  padding-top: 9px;
  margin-bottom: 10px;
}
.arketplaceresults span {
  font-weight: bold;
}
.maindivbrowsese {
  height: calc(100vh - 80px);
  padding-left: 25px;
  padding-right: 25px;
  width: calc(100vw - 500px);
  overflow-x: auto;
}


.browseMarketPlaceFiltersBoxes{
  margin-right: 10px;
  margin-top: 10px;
}
.marketBrowseProductinputDropdown {
  border-radius: 30px ;
  outline: none;
  height: 35px;
  width: max-content !important;
  min-width: 170px;
  padding: 20px !important;
  box-shadow: none !important;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  background: #17171D;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}
.marketBrowseProductinputContainerDropdown {
  color: #17171D;
  background: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 35px;
  width: 100% !important;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 15px;
}

.marketBrowseProductSortByinput{
  border-radius: 30px !important;
  outline: none;
  height: 35px;
  width: 270px !important;
  padding: 20px !important;
  box-shadow: none !important;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #17171D ;
  background: #ffffff !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  border: 2px solid #333333
}
.marketBrowseProductSortByinput > .select-box--selected-item{
  color: #17171D !important;
  opacity: 1 !important;
}


.marketBrowseProductSortByinputContainerDropdown {
  color: #17171D;
  background: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 35px;
  width: 100% !important;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.productHorizontalListView {
  display: inline-flex;
  margin: 10px;
  flex-wrap: wrap;
}





.noBrowseData {
  text-align: center;
  color: #333333;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  margin-top: 50px;
}
.browseMarketPlaceFilters{
  display: inline-flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1920px) {
  .maindivbrowsese {
    padding-left: 325px;
  }
  .productBrowseContainer {
    position: absolute;
    top: 20px;
    width: 100vw;
  }
}
