.walletsettinmaindiv {
  width: 360px;
  height: 413px;
  background: #333333;
  border-radius: 10px;
  margin-top: 15px;
  margin-left: 57px;
}
.heaallesetingr {
  background: #ffffff;
  border-radius: 0px 30px 30px 0px;
  width: 192px;
  height: 44px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 41px;
  text-align: center;
  color: #333333;
}
.RDEEPayPIsefr {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin: 0px;
  padding-left: 20px;
  padding-top: 18px;
}
.setkfhEnabled {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.6;
  padding-left: 20px;
}
.Authenticationtype {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin: 0px;
  opacity: 0.5;
  padding-left: 20px;
  padding-top: 18px;
}
.settingAuthentication {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.6;
  padding-left: 20px;
}
.ResetcsdAuth {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  opacity: 0.5;
  margin: 0px;
  padding-left: 20px;
  padding-top: 18px;
}
.Resettodefau {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.5;
  padding-left: 20px;
}
.rdeepaypaindiv {
  width: 414px;
  height: 242px;
  background: #333333;
  border-radius: 10px;
  margin-top: 50px;
  margin-left: 53px;
}
.hepaypinesetingr {
  background: #ffffff;
  border-radius: 0px 30px 30px 0px;
  width: 162px;
  height: 44px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 41px;
  text-align: center;
  color: #333333;
}
.walletonoff{
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 12px;
text-align: center;
color: #FFFFFF;
padding-left: 290px;
}
.walletonoff span{
    color:#4AEA87;
}
.RDEEscdscdPayPIN{
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 16px;
color: #FFFFFF;
padding-left: 30px;
}
.rdsinsavebutton{
   width: 344px;
   height:54px;
   background: #4453D6;
   border: none;
   border-radius: 30px;
}
.changenewpindivset{
width: 414px;
height: max-content;
padding-bottom: 20px;
background: #333333;
border-radius: 10px;
margin-top: 15px;
margin-left: 57px;
}
.EnterNsihodelfe{
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 18px;
text-align: center;
color: #FFFFFF;
padding-top: 28px;
padding-left: 50px;
padding-right: 50px;
}
.pinSavedDivsetvf{
background: #333333;
border-radius: 10px;
width: 414px;
height: 327px;
margin-top: 50px;
margin-left: 57px;
}
.newpinsacekj{
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 20px;
text-align: center;
color: #FFFFFF;
padding-top: 35px;
}
.YounescdwRDE{
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 14px;
text-align: center;
color: #FFFFFF;
width: 200px;
margin: auto;
}

.OTPCofirmField{
  width: 280px;
    height: 60px;
    background: #17171D;
    border-radius: 30px;
    border: none;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin-left: 40px;
}
.changepinotplabel {
  color: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-left: 45px;
  padding-top: 10px;
}