// Mixins
@mixin no-border-radius {
  border-radius: 0 !important;
}
// Variables
$font: Segoe;
// Colors
$black: #17171d;

.container-sidebar {
  font-family: $font;
}
.menuToggleButton {
  width: max-content;
  border-radius: 0 40px 40px 0;
  margin-top: 20px;
  padding: 10px 10px 10px 38px;
  border: none;
  z-index: 1;
  padding-right: 30px;
  .largeLogoStyle {
    width: 180px !important;
    border: none !important;
    padding: 7px;
    @include no-border-radius();
  }
  .smallLogoStyle {
    border: none !important;
    padding: 7px;
    @include no-border-radius();
  }
}

.gradientButtonMenuBG {
  background: $black !important;
}
.tranpaRentMenuBG {
  background: transparent !important;
  :hover {
    background: transparent !important;
  }
}
.largeRadioIcon {
  display: block;
}
.smallRadioIcon {
  display: none;
}

// Media Queires
@media only screen and(min-width: 300px) and(max-width: 725px) {
  .largeRadioIcon {
    display: none;
  }
  .smallRadioIcon {
    display: block;
  }
}
