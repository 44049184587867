.levbnm19 {
  /* Rectangle 19 */
  width: 252px;
  height: 61px;
  background: #4453d6;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  /* margin-left: 515px; */
}
.DASHBOARDPROVIDER {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #4453d6;
}
.Receivedxzscfg {
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 30px;
  color: #45443c;
}
.Rectangdxzle {
  /* Rectangle 22 */
  width: 37px;
  height: 38px;
  background: #ffffff;
  border-radius: 8px;
  text-align: center;
  padding-top: 5px;
}
.TOMYPROFILE {
  /* GO TO MY PROFILE */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #ffffff;
}
.ddAervice {
  /* Add A Service */
  font-style: normal;
  font-weight: 300;
  font-size: 24px;

  color: #ffffff;
}
.alzz {
  /* Rectangle 20 */
  min-width: 74.95px;
  height: 31px;
  background: #4453d6;
  border-radius: 10px;
  /* All */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  line-height: 28px;
  color: #ffffff;
  cursor: pointer;
  padding: 10px;
  line-height: 10px;

}
.cPendingsa {
  /* Pending */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
  color: #a5a5a5;
}
.csxaergg {
  /* Rectangle 21 */
  width: 305px;
  height: 211px;
  padding: 20px;
  background: #333333;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.Szscxession {
  /* GE Servicing Session */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;

  color: #ffffff;
}
.Rxzaequest {
  /* Request: #137512 */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #c4c4c4;
}
.orenda {
  /* Tanya Lorenda */
  margin-top: 13px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;

  color: #ffffff;
}
.xttimee {
  /* 11/4/2019 */
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 9px;
  color: #ffffff;
}
.qAwarded {
  /* Awarded */
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 9px;
  color: #ffffff;
}
.xload {
  /* Rectangle 22 */
  width: 243px;
  height: 7px;
  background: #c4c4c4;
  border-radius: 10px;
  margin-top: 19px;
  margin-left: 10px;
}
.paddingl {
  padding-left: 9px;
  display: inline-flex;
}
.paddingl div{
  margin-top: 5px;
  margin-right: 5px;
}
.mrexzzz {
  /* Rectangle 21 */
  width: 305px;
  min-height: 211px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  margin-left: 25px;
  cursor: pointer;
}
.xServicing {
  /* Siemens Servicing Session */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: #45443c;
}
.qweRequest {
  /* Request: #137511 */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #c4c4c4;
}
.Lilyordan {
  /* Dr. Lily Jordan */
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 13px;
  color: #a5a5a5;
}
.etimbc {
  /* 11/3/2019 */
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #a5a5a5;
  margin-top: 9px;
}
.rConfirmed {
  /* Confirmed */
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 9px;
  color: #a5a5a5;
}
.loadblue {
  /* Rectangle 24 */
  width: 243px;
  height: 7px;
  background: #c4c4c4;
  border-radius: 10px;
  margin-top: 19px;
  margin-left: 10px;
}
.xercvbd {
  /* Rectangle 21 */
  width: 1021px;
  height: 58px;
  background: #333333;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.xzRequest {
  /* Request: #137512 */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #c4c4c4;
}
.aSessionwe {
  /* GE Servicing Session */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #ffffff;
}
.whitload {
  /* Rectangle 22 */
  width: 243px;
  height: 7px;
  margin-top: 5px;
  background: #c4c4c4;
  border-radius: 10px;
}
.blackgss {
  /* Rectangle 21 */
  width: 1021px;
  height: 58px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 20px;
  margin-top: 10px;
  cursor: pointer;
}
.redxa {
  /* Request: #137511 */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  color: #c4c4c4;
}
.zxSiemens {
  /* Siemens Servicing Session */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #45443c;
}
.qwConfirmed{
    /* Confirmed */
font-style: normal;
font-weight: 300;
font-size: 14px;
display: flex;
align-items: center;

color: #A5A5A5;

}

.requestReceiveContainer{
  padding: 15px;
  width: calc(100vw - 490px);
}

.noServiceDataFound {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  opacity: 0.6;
  margin: auto;
  text-align: center;
  font-weight: bold;
  margin-top: 50px;
}