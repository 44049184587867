.crop-view {
    position: absolute !important;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: 100px;
    max-width: 500px;
    min-width: 100px;
};

@media only  screen and (min-width: 1920px){
   
    .crop-view {
        max-width: 1000px;
    }
}