.myRequestMaindiv {
  width: calc(100vw - 550px);
  height: calc(100vh - 122px);
  overflow: auto;
  margin-right: 60px;
}

.myRequestContantMainDiv {
  width: 970px;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 40px;
}
.myreqHeaderdiv {
  width: 970px;
  background: #ffffff;
  border-radius: 10px;
  padding-top: 15px;
}
.myRequestText {
  width: 170px;
  height: 44px;
  background: #007a7c;
  border-radius: 0px 30px 30px 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 43px;
  text-align: center;
  color: #ffffff;
}
.myRequestFiltersDiv {
  margin-top: 10px;
  display: flex;
}
.textCenter {
  text-align: center;
  padding-top: 10px;
}
.myRequestsearchDiv {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 0px 10px;
}
.RequestType {
  padding: 4px;
  width: 202px;
  background: #007a7c;
  border-radius: 25px;
  height: 36px;
  color: white;
}

.typeFilters {
  width: 141px !important;
  height: 36px;
  background: black !important;
  opacity: 0.9;
  border-radius: 25px !important;
  font-style: normal;
  font-weight: normal;
  font-family: "Segoe";
  font-size: 14px;
  color: #ffffff !important;
  border: none !important;
  margin: 10px;
  padding-top: 1px;
}

.typeFilters .p-dropdown-trigger {
  background: transparent !important;
  color: #ffffff !important;
  margin-right: 8px !important;
  border-radius: 100% !important;
  height: 40px;
}

.typeFilters label {
  background: transparent !important;
  color: #ffffff !important;
  padding-top: 5px !important;
  font-family: "Segoe";
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  border-radius: 25px !important;
  /* opacity: 0.5; */
  text-align: center;
}

.typeFilters .p-dropdown-panel .p-dropdown-items {
  background: #17171d !important;
  opacity: 0.9 !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.typeFilters .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  /* background: #17171D  !important; */
  /* opacity: 0.9 !important; */
  color: #ffffff !important;
  /* background-color: #2da0a7 !important; */
}
.typeFilters .p-dropdown-item .p-highlight {
  background-color: #007a7c !important;
}
.p-dropdown .p-dropdown-panel {
  border-radius: 32px !important;
}
.typeFilters .p-dropdown-panel {
  border: none !important;
  margin-top: -14px !important;
  /* padding-top: 10px; */
}

.typeFilters .p-dropdown {
  width: 80px !important;
}
.RequestTypeText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #ffffff;
}
.completeHistory {
  width: 244.12px;
  height: 36px;
  border: 1px solid;
  border-radius: 20px;
  margin-top: 10px;
  padding-top: 5px;
  text-align: center;
}
.completeHistoryText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #17171d;
}
.myRequestDotBoldDiv {
  opacity: 0.5;
  border: 1px dashed #333333;
  /* margin: 15px; */
}
.myRequestdisplayFlex {
  display: flex;
}
.nameMyRequestDiv {
  width: 340px !important;
}
.mLeft10 {
  margin-left: 10px;
}
.nameMyRequest {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin: 5px;
  color: #17171d;
}
.postMyRequest {
  /* width: 230px !important; */
  margin-left: 5px;
}
.statusMyRequestDiv {
  width: 180px !important;
  padding-top: 25px;
  display: flex;
  /* justify-content: center; */
}
.statuscircleRejected {
  height: 12px;
  width: 12px;
  border-radius: 12px;
  background: #fd4b4b;
  margin-right: 10px;
  margin-top: 5px;
}
.statuscircleWait {
  height: 12px;
  width: 12px;
  border-radius: 12px;
  background: #007a7c;
  margin-right: 10px;
  margin-top: 5px;
}
.statuscircleComplete {
  height: 12px;
  width: 12px;
  border-radius: 12px;
  background: #333333;
  margin-right: 10px;
  margin-top: 5px;
}
.statusMyRequest {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #333333;

  opacity: 0.7;
}
.dateMyRequestDiv {
  width: 235px !important;
  padding-top: 15px;
}
.dateStatusText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #333333;
  margin: 0px;
}
.dateMyRequest {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  text-align: center;

  color: #333333;

  opacity: 0.7;
}
.amountMyRequest {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  text-align: center;

  color: #17171d;
}
.detailsMyRequestDiv {
  width: 100px !important;
  padding-top: 10px;
}
.detailsMyRequest {
  padding: 4px;
  width: 100px;
  border-radius: 25px;
  height: 36px;
  color: #ffffff;
  background-color: #4453d6;
  text-align: center;
  cursor: pointer;
}

.detailModalTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #333333;
  opacity: 0.7;
}
.detailModalDate {
  display: flex;
  justify-content: space-between;

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-top: 6px;
  margin-bottom: 6px;

  color: #333333;
}
.detaillableTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #17171d;
}
.detailProfileBox {
  display: inline-flex;
}
.detailProfileBox .usernameTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin: 0px;
  color: #17171d;
  text-align: left;
}
.detailProfileBox .position {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  color: #757676;
}

.detailProfileBox .imageBox img {
  border: 2px solid #4453d6;
  box-sizing: border-box;
  border-radius: 86px;
  width: 50px;
  height: 50px;
}
.detailProfileBox .imageBox {
  width: 80px;
  text-align: center;
}
.detailModaldescription {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  color: #757676;
}
.detailBorder {
  border: 1px dashed #5d5d5d;
}

.editRequestButton {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  align-items: center;
  text-align: center;
  margin: 10px;
  color: #ffffff;
  background: #007a7c;
  border-radius: 30px;
  width: 161px;
  height: 45px;
}
.deleteRequestButton {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  align-items: center;
  text-align: center;
  margin: 10px;
  color: #ffffff;
  background: #333333;
  border-radius: 30px;
  width: 161px;
  height: 45px;
}
.confirmationModal {
  background: #4453d6;
  border-radius: 30px;
  padding: 25px;
  width: 327px;
  height: 200px;
}
.confirmationModal .confirmTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #ffffff;
}
.confirmationModal .confirmDescription {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #ffffff;

  opacity: 0.8;
}
.confirmationModal .confirmDescription span {
  font-weight: bold;
}

.confirmationModal .confirmButtons {
  text-align: right;
  padding-top: 10px;
}

.confirmationModal .BlackButton {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
  background: #17171d !important;
  border-radius: 30px;
  margin-left: 10px;
  border: none !important;
  width: 102px;
  height: 37px;
}
.confirmationModal .WhiteButton {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  width: 102px;
  height: 37px;
  color: #ffffff;
  background: transparent !important;
  border-radius: 30px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 30px;
}
