.REQUESTsss {
  /* REQUEST #137512 */

  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #4453d6;
}
.Servicinggg {
  /* Siemens Servicing Session */

  font-style: normal;
  font-weight: 300;
  font-size: 40px;

  color: #45443c;
}
.Description {
  /* Description: */

  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #6c6c6c;
}
.Status {
  /* Status: */

  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #6c6c6c;
}
.longtextttt {
  
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #17171d;
}
.Rectangleeee {
  width: 256px;
  height: 7px;
  background: #c4c4c4;
  border-radius: 10px;
}
.Awarded22 {
  padding-left: 5px;

  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  text-transform: capitalize;
  color: #6c6c6c;
}
.dateeee0 {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  padding-left: 5px;
  color: #6c6c6c;
}

.FOR {
  
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  height: 35 !important;
  padding-top: 4 !important;
  margin-top: 10 !important;
  color: #ffffff;
}
.JOBDETAILS {
  /* JOB DETAILS */

  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;

  color: #4453d6;
}
.Rectangle0000 {
  /* Rectangle 21 */
  padding: 10px;
  width: 256px;
  height: 120px;
  margin-top: 17px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.Lorendaaa {

  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #45443c;
}
.Angeless {
 
  font-style: normal;
  font-weight: normal;
  font-size: 11px;

  color: #6c6c6c;
}
.reccc {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-top: 5px;
  width: 125px;

  text-align: center;

  color: #ffffff;

  height: 30px;

  background: #4453d6;
  border-radius: 5px;
}
.Manufacturerr {
 
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  width: 250px;

  color: #8c8c8c;
}
.Sssiemens {
 
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  width: 250px;
  

  color: #17171d;
}
.Errorrr {
  
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* padding-left: 80px; */
  width: 125px;
  color: #8c8c8c;
}
.a5215 {
  
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  width: 130px;
  /* padding-left: 28px; */
  color: #17171d;
}
.Modality {
  

  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #8c8c8c;
}
.CT {

  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-left: 100px;

  color: #17171d;
}
.Errorll {
  

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding-left: 198px;

  color: #8c8c8c;
}
.errorlog {
  
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-left: 39px;

  color: #17171d;
}
.reccoo {
  

  background: #8c8c8c;
  border-radius: 5px;
  width: 120px;
  font-size: 14px;
}
.Sensation {
  
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-left: 97px;

  color: #17171d;
}
.InquiryParts {
  

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-left: 75px;

  color: #17171d;
}
.datttoo {
  
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-left: 30px;

  color: #17171d;
}
.revbu {
  
  width: 199px;
  height: 43px;

  background: #4453d6;
  border-radius: 10px;
}

.notificationCSSMyRequestDetails {
  width: 480px !important;
}
.parentRowMyRequestDetails {
  padding: 30px;
  width: calc(100vw - 480px) !important;
  background: #ffffff !important;
  overflow-x: hidden;
}
.inlineFlex100MyRequestDetails {
  width: 100% !important;
  display: inline-flex !important;
}
.scrollBehaveXMyRequestDetail {
  overflow-x: auto;
  padding: 10;
}
.statusDiv {
  width: '25%';
  margin-left: 75px;
}
.mleft15 {
  margin-left: 15px !important;
}
.mleft5 {
  margin-left: 5px !important;
}
.mTop5 {
  margin-top: 5px !important;
}
.mTop15 {
  margin-top: 15px !important;
}
.buttonStyleOne {
  height: 35px !important;
  background: #8c8c8c !important;
  color: #fff !important;
  font-size: 15px !important;
  border-radius: 10px !important;
  text-align: center !important;
  padding-top: 5px !important;
  width: 256px !important;
}
.ButtonStyleForTwo {
  background: '#4ca9b7' !important;
  height: 35px !important;
  color: #fff !important;
  border-radius: 10px !important;
  text-align: center !important;
  padding-top: 5px !important;
  font-size: 15px !important;
  width: 256px !important;
  border: none;
}
.Rectan {
  /* Rectangle */
  width: 256px !important;
  color: #ffffff !important;
  height: 35 !important;
  padding-top: 4 !important;
  margin-top: 10 !important;
  background: #8c8c8c;
  border-radius: 10px;
}

.feedmodal {
  /* Rectangle 660 */
  width: 422px;
  height: 480px;

  background: #333333;
  border-radius: 10px;
}
.Leave {
  /* Leave Feedback */
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  margin-top: 39px;
  color: #eeeeee;
}
.experienceee {
  /* How was your experience with [Service Provider Name]? */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;

  /* or 141% */
  margin-top: 12px;
  color: #ffffff;
}
.commentbox {
  /* Rectangle 27 */
  padding-left: 10px;
  margin-top: 15px;
  text-align: left;

  width: 344px;
  height: 152px;

  background: #c4c4c4;
  opacity: 0.4;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  border: none;
}
.commentbox:focus {
  outline: none !important;
  border-color: #719ece;
  box-shadow: 0 0 10px #719ece;
}
.Leaveareview {
  /* Leave a review of your experience */

  font-style: normal;
  font-weight: 300;
  font-size: 18px;

  padding-top: 15px;
  color: #eef3f7;
}
.Rectangle08036 {
  /* Rectangle 31 */
  width: 134px;
  height: 49px;
  /* SUBMIT */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;

  text-align: center;

  color: #ffffff;
  float: right;
  margin-right: 22px;
  margin-top: 15px;

  background: #4453d6;
  border-radius: 10px;
}
.payforservice {
  /* Rectangle 660 */
  width: 422px;
  height: 459px;

  background: #333333;
  border-radius: 10px;
}
.Serviceforpay {
  /* Pay for Service */
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  margin-top: 39px;

  color: #eeeeee;
}
.Wallet {
  /* Wallet Balance */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  /* identical to box height */
  padding-top: 17px;
  color: #eeeeee;
}
.US100000000 {
  /* USD$ 1,000,000.00 */
  font-style: normal;
  font-weight: bold;
  font-size: 24px;

  color: #ffffff;
}
.RDEECredits {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  /* identical to box height */

  color: #ffffff;
}
.AlfredHerman {
  /* Alfred Herman */
  font-style: normal;
  font-weight: bold;
  font-size: 20px;

  color: #ffffff;
}
.Request137508 {
  /* Request #137508 */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  /* identical to box height */

  color: #a5a5a5;
}
.SiemensServicingSessio {
  /* Siemens Servicing Session */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  /* identical to box height */

  color: #ffffff;
}
.PAYUS220000 {
  /* PAY USD$ 220,000 */
  font-style: normal;
  font-weight: bold;
  font-size: 24px;

  /* identical to box height */
  text-align: center;
  padding-top: 15px;
  color: #6dc5cc;
}
.RDEECreditsafte {
  /* RDEE Credits after placing order: 780,000 RDEE */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding-top: 15px;
  /* identical to box height */
  text-align: center;

  color: #c4c4c4;
}
.Rectangle9999 {
  /* Rectangle 31 */
  width: 134px;
  height: 49px;
  /* SUBMIT */

  font-style: normal;
  font-weight: bold;
  font-size: 17px;

  text-align: center;

  color: #ffffff;

  float: right;
  margin-right: 39px;
  margin-top: 10px;

  background: #4453d6;
  border-radius: 10px;
}
.pinrect {
  /* Rectangle 660 */
  width: 422px;
  height: 459px;
  background: #333333;
  border-radius: 10px;
}
.EnterPIN {
  /* Enter PIN */
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  color: #eeeeee;
  margin-top: 35px;
  padding-left: 39px;
}
.confirmsending {
  /* Enter your PIN to confirm sending money. */
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  padding-top: 35px;
  text-align: center;

  color: #ffffff;
}
.enteredYouhave {
  /* You have entered your PIN incorrectly more than 5 times. Your PIN has been reset. Kindly email support@radiologex.com to proceed further. */

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  padding-top: 10px;
  color: #ff3c3c;
}
.successRectangle {
  /* Rectangle 660 */
  width: 422px;
  height: 375px;
  background: #333333;
  border-radius: 10px;
}
.Successss {
  /* Success! */

  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  padding-left: 39px;
  padding-top: 39px;
  color: #eeeeee;
}
.Service-on-DemandRequest {
  /* You paid $220,000 to Alfred Herman for Service-on-Demand Request #137508. */
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  padding-top: 35px;
  /* or 141% */
  text-align: center;

  color: #ffffff;
}
.backtorect {
  /* Rectangle 31 */
  width: 284px;
  height: 49px;
  background: #4453d6;
  border-radius: 10px;
  /* BACK TO MY DASHBOARD */

  font-style: normal;
  font-weight: bold;
  font-size: 17px;

  text-align: center;

  color: #ffffff;
}
.jobcompleterec {
  /* Rectangle 660 */

  width: 422px;
  height: 252px;

  background: #333333;
  border-radius: 10px;
}
.JobComplete {
  /* Confirm Job Complete */
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  color: #eeeeee;
  text-align: left;
  padding-top: 25px;
  padding-left: 30px;
}
.iscomplete {
  /* Do you wish to mark that this service from [Service Provider Name] is complete? */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  padding-top: 12px;
  /* or 141% */

  color: #ffffff;
}
.butrecttt {
  /* Rectangle 32 */
  width: 130.66px;
  height: 49px;
  /* NO */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  text-align: center;

  color: #ffffff;

  background: #a5a5a5;
  border-radius: 10px;
}
.yesreccc {
  /* Rectangle 31 */
  width: 117px;
  height: 49px;
  background: #4453d6;
  border-radius: 10px;
  /* YES */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;

  text-align: center;
  margin-left: 22px;
  color: #ffffff;
}
.twobutttt {
  display: flex;
  margin-top: 31px;
  float: right;
  padding-right: 46px;
}
.jobmarkedrecc {
  /* Rectangle 660 */
  width: 422px;
  height: 320px;

  background: #333333;
  border-radius: 10px;
}
.MarkedComplete {
  /* Job Marked Complete */
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  padding-top: 30px;
  text-align: center;

  color: #eeeeee;
}
.Thiservice {
  /* This service from [Service Provider Name] has been marked as completed. Please rate your experience with [Service Provider Name]. */

  font-style: normal;
  font-weight: normal;
  font-size: 17px;

  /* or 141% */
  text-align: center;
  padding-top: 12px;
  color: #ffffff;
}
.buuurexxx {
  /* Rectangle 31 */
  width: 284px;
  height: 49px;
  background: #4453d6;
  border-radius: 10px;
  /* COMPLETE FEEDBACK */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;

  text-align: center;

  color: #ffffff;
}
.toMyRequests {
  /* Return to My Requests */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  text-align: center;
  padding-top: 13px;
  color: #ffffff;
}
.systemrecc {
  width: 100%;
  border-radius: 10px;
}

.DetailgobackButtons {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  color: #c4c4c4;
  display: inline-flex;
  cursor: pointer;
  background: none;
  border: 0px;
  float: right;
}
.DetailgobackButtons:hover {
  background: none !important;
  color: #c4c4c4;
  border-radius: 10px !important;
}
.DetailgobackButtons:focus,
DetailgobackButtons:active {
  border: 0px !important;
  box-shadow: none !important;
}
.DetailgobackButtons > div {
  background: #c4c4c4;
  border-radius: 5px;
  text-align: center;
  width: 25px;
  height: 26px;
}
.DetailgobackButtons img {
  width: 14px;
}
.DetailgobackButtons span {
  padding-left: 5px;
  padding-top: 2px;
}
.serviceProviderProfileImage {
  width: 65px;
  border-radius: 100px;
  height: 65px;
}
