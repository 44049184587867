/* Variables */
/* Colors */
:root {
  --white: #fff;
  --button-blue: #4453d6;
  --input-black: #17171d;
}

/* To overide fonts - WHERE IS DIV-FLEX? */
#loginScreen2 {
  font-family: Segoe;
}

/* Figma Landing */

/* Logo */
.logo {
  margin: 0 auto;
  max-width: 100%;
  padding-bottom: 1.9rem;
}

.div-flex {
  padding: 2rem;
  overflow-y: scroll;
}

.loginScreen3 {
  margin: 0 auto;
  max-width: 429px;
}

.loginmaindiv {
  width: 100%;
  max-width: 387px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: #17171d80;
  border-radius: 30px;
  padding: 1.9rem;
  margin: 0 auto;
}

.WELCOME {
  color: var(--white);
  font-size: 20px;
  margin-bottom: 17px;
}

.email {
  padding: 0 0 4px 12px;
  font-size: 15px;
  color: var(--white);
  align-self: flex-start;
}

.emalinppu {
  height: 49px;
  max-width: 327px;
  margin: 0 auto;
  background: #17171d;
  border-radius: 30px;
  font-size: 16px;
  color: #ffffff;
}

.emalinppu input {
  position: relative;
  width: 100%;
  height: 49px;
  border-radius: 30px;
  font-size: 16px;
  background: transparent;
  padding-left: 70px;
  color: #ffffff;
}

.relative {
  position: relative;
  width: 100%;
  opacity: 0.8;
}

.emalinppu input::placeholder {
  opacity: 0.5;
  color: #ffffff;
}

.signUpinputIcons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 24px;
  z-index: 1;
  opacity: 0.5;
}

/* Figma Login */

#loginScreenActionButton {
  width: 100%;
}

.lologinbutt {
  width: 100%;
  max-width: 327px;
  height: 49px;
  background-color: var(--button-blue);
  border-radius: 30px;
  font-size: 16px;
  text-align: center;
  color: var(--white);
  border: none;
  cursor: pointer;
}

.Don’thave {
  font-size: 16px;
  text-align: center;
  color: var(--white);
}
.Don’thave a {
  text-decoration: none;
  color: var(--white);
  font-weight: bold;
}
.Don’thave a:hover {
  text-decoration: none;
  color: var(--white);
}

/* Was pushing content off scren, for dev purposes */
.react-hint,
.react-hint--bottom {
  position: relative;
  top: 0;
  left: 0;
  display: none;
}

.react-hint__content {
  top: 0;
  left: 0;
  position: relative;
  display: none;
}

.password {
  font-size: 15px;
  color: var(--white);
  padding: 0 0 4px 12px;
}

.password-container {
  width: 100%;
}
.passwordinpu input {
  width: 100%;
  max-width: 327px;
  position: relative;
  height: 49px;
  font-size: 16px;
  background-color: var(--input-black);
  color: var(--white);
  opacity: 0.8;
  border-radius: 30px;
  padding-left: 70px;
  padding-right: 15px;

  border: none;
}

.passwordinpu input::placeholder {
  opacity: 0.5;
  color: var(--white);
}

.forgotPassw {
  text-align: center;
  margin-bottom: 19px;
  font-size: 14px;
}

.forgotPassw a {
  color: var(--white);
}

.passwordless {
  width: 100%;
  max-width: 327px;
  height: 49px;
  background: #17171dcc;
  border-radius: 30px;
  font-size: 16px;
  text-align: center;
  border: none;
  cursor: pointer;
  margin-bottom: 30px;
}

/* Figma Forget_Password_1   */

.forgotPassBox {
  font-family: Segoe;
}

#forgotpassPage35 {
  margin-bottom: 23px;
}

#forgotpassPage36 {
  font-size: 24px;
  color: var(--white);
  margin-bottom: 19px;
}

#forgotpassPage37 {
  font-size: 16px;
  color: var(--white);
  margin-bottom: 35px;
}

#forgotpassPage39 {
  width: 100%;
  margin-top: 25px;
  max-width: 327px;
  border-radius: 30px;
  background-color: black;
  height: 49px;
  border: none;
  cursor: pointer;
}

.Enteryou {
  font-size: 20px;
  color: var(--white);
  margin-bottom: 16px;
}

.forgetButtonDiv {
  margin: 0 auto;
  max-width: 451px;
  padding: 0;
}

.forget-button-inner-div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.forgetcd {
  font-size: 20px;
  text-align: center;
  color: var(--white);
  margin-bottom: 10px;
}

.forgotPassFieldTitle {
  font-size: 15px;
  padding: 0 0 4px 12px;
  color: var(--white);
  text-align: left;
}

#forgotpassPage26 {
  max-width: 327px;
  width: 100%;
}

#forgotpassPage29 {
  width: 100%;
  max-width: 327px;
  margin-bottom: 35px;
}

.textLeft {
  width: 100%;
  max-width: 327px;
  color: var(--button-blue);
}

.Passwordmustcontain {
  font-style: italic;
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  margin-bottom: 10px;
}

/* Component PasswordForget.js */
.forgotPassFieldTitle {
  margin: 0 auto;
  max-width: 327px;
}

.forpanexutton {
  width: 100%;
  max-width: 327px;
  height: 49px;
  background-color: var(--button-blue);
  border-radius: 30px;
  border: none;
  font-size: 16px;
  text-align: center;
  color: var(--white);
}

/* Figma Forgot Password_2 */
.forgotPassCountdown {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pwfordde {
  padding: 0 0 4px 80px;
  font-size: 15px;
  color: var(--white);
  align-self: flex-start;
}

#forgotpassPage16 {
  width: 100%;
  max-width: 327px;
}

.emailinpupwf {
  width: 100%;
  max-width: 327px;
  margin-bottom: 11px;
  height: 49px;
  background: #17171d;
  border-radius: 30px;
  font-size: 16px;
  color: var(--white);
  border: none;
  padding-left: 27px;
  opacity: 0.7;
}

.emailinpupwf2-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 327px;
}

.emailinpupwf2 {
  width: 100%;
  max-width: 247px;
  height: 49px;
  background: #17171d;
  margin-right: 15px;
  margin-bottom: 35px;
  border-radius: 30px;
  color: #fff;
  color: var(--white);
  border: none;
  padding-left: 27px;
  opacity: 0.7;
}

.otpellipse {
  display: flex;
  width: 100%;
  max-width: 49px;
  align-items: center;
  justify-content: center;
  height: 49px;
  border-radius: 30px;
  background-color: var(--white);
  color: #333333;
  text-align: center;
}

#forgotpassPage21 {
  width: 100%;
  max-width: 327px;
}

.restpasbut {
  width: 100%;
  max-width: 327px;
  height: 49px;
  background-color: var(--button-blue);
  border-radius: 30px;
  text-align: center;
  color: var(--white);
  border: none;
  cursor: pointer;
}

/* Media Queires */
@media screen and (max-height: 788px) {
  /* .signupstep1, */
  /* .signmaindiv {
    overflow-y: scroll;
  } */
}

/* Modal-dialog */
#businessconfirmModal::before {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  height: 100%;
  margin: 0 auto;
  padding: 1.9rem;
  height: 600px;
  width: 100%;
  min-width: 300px;

  max-width: 500px;
  background: #17171d;
  opacity: 0.7;
  border-radius: 30px;
  z-index: -1;
}
#businessconfirmModal {
  width: 100%;
  min-width: 300px;
  max-width: 500px;
  height: 600px;
  margin: 0 auto;
  overflow: hidden;
  color: var(--white);
  margin: 0 auto;
  padding: 1.9rem;
  position: relative;
  background-color: transparent;
  z-index: 100;
}

/* Modal-dialog */
#loginScreennotVerifyModal::before {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  height: 100%;
  margin: 0 auto;
  padding: 1.9rem;
  height: 600px;
  width: 100%;
  max-width: 387px;
  background: #17171d;
  opacity: 0.7;
  border-radius: 30px;
  z-index: -1;
}
#loginScreennotVerifyModal {
  width: 100%;
  max-width: 387px;
  height: 600px;
  margin: 0 auto;
  overflow: hidden;
  color: var(--white);
  margin: 0 auto;
  padding: 1.9rem;
  position: relative;
  background-color: transparent;
  z-index: 100;
}

.successAccount {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Thankyoumsg {
  text-align: center;
}

.Thankyoumsg span {
  font-weight: bold;
}

.successAccountFooter {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.step3buttonl {
  width: 100%;
  max-width: 327px;
  height: 49px;
  border-radius: 30px;
  background-color: var(--button-blue);
  margin-bottom: ;
  font-size: 16px;
  color: #fff;
  opacity: 0.8;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.resendConfirmation {
  width: 100%;
  max-width: 327px;
  height: 49px;
  border-radius: 30px;
  background-color: black;
  font-size: 16px;
  color: #fff;
  opacity: 0.8;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
}
.resendConfirmation p {
  margin: 0;
}

.passlessLoginModal {
  border-radius: 30px;
  margin: 0 auto;
  padding: 1.9rem;
  background: #17171d;
  min-width: 388px;
}
.passlessLoginVideoBox {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accountDisableTitle {
  color: var(--white);
}
.chooseBusinesslabel{
  font-size: 18px;
  font-weight: bold;
}
.businessSelectionBox{
  margin: 10px;
  height: calc(100% - 70px);
  overflow: auto;
}
.chooseBusinessBoxList{
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding-bottom: 5px;
  font-size: 16px;
}
.chooseBusinessBoxList .radioBusinessSelection{
  margin-right: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  display: inline-block;
  visibility: visible;
  border: 2px solid #FFFFFF;
  align-items: center;
  display: flex;
}

.radioBusinessSelection  .checkmarkRadio {
  width: 15px;
  height: 15px;
  border-radius: 50px;
  display: inline-block;
  background: #FFFFFF;
  margin-left: 3px;
}

