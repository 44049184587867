.CHOOSEA {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #4453d6;
}
.BACKjkjk {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: right;

  color: #c4c4c4;
}
.ServiceProviderrr {
  /* Service Provider */
  font-style: normal;
  font-weight: 300;
  font-size: 40px;

  color: #45443c;
}
.Browseall {
  padding-top: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #17171d;
}
.Rectangddffddle {
  color: #000000 !important;
  width: 600px;
  height: 49px;
  padding-left: 10px;
  margin-top: 35px;
  background: #c4c4c4;
  opacity: 0.4;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
}
.rreerr {
  /* Rectangle 17 */
  width: 140px;
  height: 49px;
  padding-left: 10px;
  background: #c4c4c4;
  opacity: 0.4;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  /* Select Service Area */
  font-weight: 300;
  font-size: 15px;
  color: #a5a5a5;
}
.recciii {
  /* Rectangle 19 */

  width: 125px;
  height: 49px;
  margin-left: 5px;
  background: #4453d6;
  border-radius: 10px;
}
.reccuuioo {
  /* Rectangle 18 */

  width: 143px;
  height: 49px;

  background: #c4c4c4;
  opacity: 0.4;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  /* Select Service Offered */
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  margin-left: 10px;
  color: #a5a5a5;
}

.carrrrrect {
  /* Rectangle 21 */
  margin-top: 22px;
  width: 200px;
  height: 296px;
  background: #4453d6;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
}

.MJulieMartha {
  /* Ms. Julie Martha */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #ffffff;
}
.aliforniaaaa {
  /* San Francisco, California */
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #ffffff;
}

.verageRate {
  /* Average Rate: */
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  align-items: center;

  color: #a5a5a5;
}
.s85perho {
  /* $85 per hour */
  font-style: normal;
  font-weight: bold;
  font-size: 15px;

  color: #ffffff;
}

.ServicesProvid21 {
  /* 21 Services Provided */
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  /* identical to box height */

  align-items: center;
  text-align: center;

  color: #ffffff;
}

.geRecngle {
  /* Rectangle 32 */
  width: 179px;
  height: 28px;
  text-align: center;
  margin-top: 6px;
  background: #6dc5cc;
  border-radius: 7px;
  /* GE Servicing */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #ffffff;
}
.anurec {
  /* Rectangle 17 */

  width: 140px;
  height: 49px;

  background: #c4c4c4;
  opacity: 0.4;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
}


.Rectangle2001 {
    /* Rectangle 21 */
    margin-top: 22px;
    width: 200px;
    height: 296px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-left: 12px;
    overflow-y: auto;
    cursor: pointer;
  }
  .MssJulieMart {
    /* Ms. Julie Martha */
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
  
    color: #45443c;
  }
  .Cxxaliforniaaaa {
    /* San Francisco, California */
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
  
    color: #6c6c6c;
  }
  .AssverageRate {
    /* Average Rate: */
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    align-items: center;
  
    color: #a5a5a5;
  }
  .szz85perhour {
    /* $85 per hour */
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
  
    color: #6c6c6c;
  }
  .SsddervicesProvided21 {
    /* 21 Services Provided */
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    /* identical to box height */
  
    align-items: center;
    text-align: center;
  
    color: #6c6c6c;
  }
  
  .gsaeRectangle {
    /* Rectangle 32 */
    width: 179px;
    height: 28px;
    text-align: center;
    margin-top: 6px;
    background: #6dc5cc;
    border-radius: 7px;
    /* GE Servicing */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  
    color: #ffffff;
  }
  .Rectangle2000 {
    /* Rectangle 21 */
    margin-top: 22px;
    width: 200px;
    height: 255px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-left: 12px;
  }
  .gobackButtonsRequest{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    color: #c4c4c4;
    display: inline-flex;
    float: right;
    background:none;
    border: none;
}
.gobackButtonsRequest:hover {
  background: none !important;
  color: #c4c4c4;
  border-radius: 10px !important;
}
.gobackButtonsRequest:focus,
gobackButtonsRequest:active {
  border: 0px !important;
  box-shadow: none !important;
}
.gobackButtonsRequest > div{
  background: #c4c4c4;
  border-radius: 5px;
  text-align: center;
    width: 25px;
    height: 26px;
}
.gobackButtonsRequest img{
  width: 14px;
}
.gobackButtonsRequest span{
  padding-left: 5px;
}


/* 
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  width: 145px !important;
  height: 49px !important;
  background: #C4C4C4 !important;
  opacity: 0.4 !important;
  border: 1px solid #C4C4C4 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  margin-right: 5px;
  color: #060e2170 !important;
  font-size: 10px !important;
} */

.btn-group,
.btn-group-vertical {
  width: 147px;
}


.btn-group-lg>.btn,
.btn-lg {
  width: 145px !important;
  height: 49px !important;
  background: #C4C4C4 !important;
  opacity: 0.4 !important;
  border: 1px solid #C4C4C4 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  margin-right: 5px;
  color: #060e2170 !important;
  font-size: 10px !important;
}

.dropdown-menu.show {
  width: 300px;
  height: 147px;
  left: 0px;
  top: 0px;

  background: #6975DE;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.dropdown-header {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;

  color: #FFFFFF;

}
.dropdown-item{
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid;
  border-bottom-style: dotted;
  padding: 10px;
  color: #FFFFFF;

}
.scrollable-element {
  scrollbar-color: red yellow !important;
}
/* .container-children ::-webkit-scrollbar-thumb{
  background-color: #fff !important;
}
.container-children ::-webkit-scrollbar-track{
  background: #C4C4C4;
} */
.dropdown-item:focus, .dropdown-item:hover{
  color: #fff ;
  background: #4453D6;
}
.searcprocardimg{
  height: 55px;
  width: 55px;
  border-radius: 55px;
}
.secmadi{
  padding: 15px;
  width: calc(100vw - 480px);
  overflow-x: scroll;
  height: calc(100vh - 0px);
}