.screen-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  position: relative;
  background: #17171d50;
  padding-top: 50px;
  /* opacity: 0.5; */
  border-radius: 10px;
  /* margin: 13px; */
  overflow-y: auto;
}


.screen-container .block-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.screen-container .block-container .text-container {
  font-family: Helvetica;
  font-style: italic;
  font-weight: normal;
  color: #707070;
  padding: 20px;
  text-align: center;
}

.screen-container .block-container .button-view {
  color: white;
  font-weight: 500;
  background: linear-gradient(180deg, #439fc8 0%, #4358a7 100%);
  border-radius: 5px;
  text-align: center;
  padding: 10px 20px;
  font-size: 0.8rem;
  cursor: pointer;
}
/* 
.screen-container .top-right-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.screen-container .top-right-button .crumb-text {
  color: white;
  font-size: 2rem;
  text-align: right;
}

.screen-container .top-right-button .child {
  background-color: white;
  font-size: 0.9rem;
  min-width: 120px;
  padding: 5px 10px;
} */

.creategroupmodal {
  width: 725px;
  height: 620px;
  background: #000000;
  border-radius: 20px;
  /* left: -72%; */
}
.CreateNew {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #c4c4c4;
  width: 270px;
  margin: 0 auto;
}
.CREATENEWMESSENGER {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #eef3f7;
  padding-top: 25px;
  padding-left: 30px;
}
.CREATENEWMESSENGER span {
  font-weight: bold;
}
.selectmembers {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  padding-left: 30px;
  padding-right: 30px;
}
.labejuegd {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  padding-left: 15px;
}
.searchinputmesen {
  background: #333333;
  opacity: 0.7;
  border-radius: 30px;
  width: 327px;
  height: 49px;
  display: flex;
  align-items: center;
}
.searchicsdfnput {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff !important;
  background: transparent;
  width: 280px;
}
.searchicsdfnput:focus {
  background: transparent;
}
.addeddfs {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #c4c4c4;
  padding-top: 25px;
  padding-left: 30px;
}
.MarcusBar {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin: 0px;
  padding-left: 10px;
}
.lastwswef {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #c4c4c4;
  padding-left: 10px;
  margin: 0px;
}
.crestejehbutton {
  background: #4453d6 !important;
  border-radius: 30px;
  width: 327px;
  height: 54px;
  border: none;
  margin-top: 30px;
}
.noGrpSerchContactFound {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #c4c4c4;
  padding-left: 18px;
  margin-top: 15px;
}
.clicktoaddckdjd {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #c4c4c4;
  /* padding-left: 18px; */
  margin: 0px;
  max-height:300px ;
}

.forwardOptionText{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: black;
  padding-bottom: 10px;
}

.selectForForwardMessage {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #c4c4c4;
  padding-left: 18px;
  margin: 0px;
  overflow: auto;
  max-height:500px ;
}

.createghfeftitl {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  padding-left: 40px;
  padding-top: 10px;
}
.grouonamwmkdhkj {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 40px;
  padding-top: 29px;
  margin: 0px;
}
.uploadbutton {
  background: #4453d6;
  border-radius: 30px;
  width: 95px;
  height: 32px;
  border: none;
  margin-left: 52px;
}
.groupnameucbj {
  background: #171717;
  opacity: 0.7;
  border-radius: 30px;
  width: 350px;
  height: 49px;
  display: flex;
  align-items: center;
  margin-left: 30px;
  padding-left: 10px;
}
.groupdpicdhf {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin: 0px;
  padding-top: 17px;
  padding-left: 50px;
}
.cdjvwarningj {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.5;
  padding-left: 50px;
}
.groupmembersjkd {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  color: #eef3f7;
  padding-left: 30px;
  padding-top: 70px;
}
.alfredejl {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin: 0px;
  /* padding-left: 51px; */
}
.groupmemberimg {
  width: 70px;
  height: 70px;
  /* margin-left: 20px; */
  /* margin-top: 20px; */
  border-radius: 35px;
  border: 2px solid #FFFFFF;
}
.creategrefjlkd {
  background: #4453d6 !important;
  border-radius: 30px;
  width: 327px;
  height: 54px;
  border: none;
  margin-top: 50px;
}
.grouberimg {
  width: 70px;
  height: 70px;
  margin-left: 30px;
  margin-top: 20px;
}
.alfredscdg {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  padding-left: 21px;
}
.meaagebox {
  width: 492px;
  height: 99px;
  background: #ffffff;
  /* border-radius: 0px 20px 20px 20px; */
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  text-align: left;
  padding: 10px;
  margin-right: 230px;
  margin-top: 15px;
}
.rightmsgbox {
  background: #4453d6;
  width: 471px;
  height: 77px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin-top: 20px;
  margin-left: 305px;
  padding: 15px;
}
.groupinfodiv {
  width: 440px;
  height: 521px;
  background: #333333;
  border-radius: 10px;
  margin-top: 406px;
}
.hedinginfogroulk {
  width: 146px;
  height: 44px;
  background: #4453d6;
  border-radius: 0px 30px 30px 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  line-height: 44px;
  margin-top: 17px;
  text-align: center;
}
.hedinginfogroulk span {
  font-weight: bold;
}
.MediGro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #ffffff;
  padding-left: 12px;
}
.Groupscdfb {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin-top: 29px;
  margin-left: 45px;
}
/* .Groupscdfb span {
  /* padding-left: 10px; */
/* } */

.Groupscdfb img {
  width: 20px;
  height: 16px;
  margin-right: 12px;
}
.onofftoggle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  padding-left: 121px;
}
.linedvfdg {
  margin: 0 auto;
  /* margin-left: 10px; */
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 327px;
  margin-top: 25px;
}
.Groupcrea {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.8;
  padding-left: 25px;
  margin-top: 21px;
}
.phoyos16 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.8;
  padding-left: 10px;
}
.buttonsdhjvs {
  background: #ffffff;
  border-radius: 30px;
  width: 158px;
  height: 37px;

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #17171d;
  border: none;
}

.leavegroupbutton {
  border-radius: 30px;
  width: 158px;
  height: 37px;
  background: #4453d6;
  border: none;
  margin-left: 15px;
}
.adminandmemberdiv {
  width: 393px;
  height: 411px;
  background: #333333;
  border-radius: 10px;
  margin-left: 20px;
}
.hedingadminmember {
  width: 209px;
  height: 44px;
  background: #4453d6;
  border-radius: 0px 30px 30px 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  line-height: 44px;
  margin-top: 17px;
  text-align: center;
}
.hedingadminmember span {
  font-weight: bold;
}
.member5 {
  font-family: Segoe;
  font-style: normal;
  font-size: 13px;
  color: #ffffff;
  padding-left: 72px;
}
.member5 span {
  font-weight: bold;
}
.adminmemembername {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
  text-align: left;
  padding-left: 10px;
}
.adminmemebmhgfj {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.7;
  margin: 0px;
  text-align: left;
  padding-left: 10px;
}
.admiinmemberjscbdsk,
k {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.7;
  text-align: left;
  padding-left: 10px;
}
.linedvfdg2 {
  margin: 0 auto;
  /* margin-left: 10px; */
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 327px;
}

.groupinfodivscds {
  width: 440px;
  height: 372px;
  background: #333333;
  border-radius: 10px;
}
.searchinputmesen2 {
  background: #17171d;
  opacity: 0.7;
  border-radius: 30px;
  width: 327px;
  height: 49px;
  display: flex;
  align-items: center;
  margin-left: 45px;
}
.searchicsdfnput2 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  background: transparent;
  width: 280px;
}
.searchicsdfnput2:focus {
  background: transparent;
}
.groupnamelabe {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  color: #ffffff;
  margin: 0px;
  padding-left: 65px;
  padding-top: 15px;
}
.hedingadgroupmember {
  width: 146px;
  height: 44px;
  background: #4453d6;
  border-radius: 0px 30px 30px 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  line-height: 44px;
  margin-top: 17px;
  text-align: center;
}
.hedingadgroupmember span {
  font-weight: bold;
}
.characrtermaxX30 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.5;
  text-align: left;
  padding-left: 100px;
}
.replacebutton {
  width: 95px;
  height: 32px;
  border: none;
  background: #4453d6;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin-left: 18px;
}
.Grouppoihedu {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
}
.Displaypiccdctur {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.5;
  width: 234px;
  text-align: left;
}
.savechbutton {
  width: 112px;
  height: 37px;
  border: none;
  background: #4453d6;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin-top: 33px;
  float: right;
  margin-right: 45px;
}
.groupjscuirowjkdc {
  display: flex;
  width: 350px;
  margin: 0 auto;
  align-items: center;
}

@media only  screen and (min-width: 1920px){

  .screen-container {
    height: calc(100vh - 110px);

  }
}
