

.menuButtonForComman{
    background: transparent;
    border: 0px !important;
    width: 200px;
    padding: 0px;
    /* margin-left: 20px; */
}
.menuButtonForComman img{
width: 200px !important;
border:none !important;
padding: 7px;
border-radius: 0px !important;
height: 50px !important;
/* height: 40px !important; */
}

.menuButtonForComman:focus , .menuButtonForComman:hover ,.menuButtonForComman:active {
background: transparent !important;
border: 0px !important;
box-shadow: none !important;
}