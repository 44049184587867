
.dropdown-parent {
    position: relative;
    display: inline-block;
    margin-right: 0;
    width: 100% !important;
    padding-right: 20px;
}


.dropdown-label {
    width: 180px;
   
    float: right;
    color: #FFFFFF;
    font-weight: bold;
    cursor: pointer;
}
.menuButton{
    background: transparent;
    border: 0px !important;
    width: 200px;
    padding: 0px;
    margin-left: 20px;
}
.menuButton img{
width: 200px !important;
border:none !important;
padding: 7px;
border-radius: 0px !important;
height: 50px !important;
/* height: 40px !important; */
}

.padding{
    padding: 10px 30px;
}


.dropdownArrowMessenger{
    position: absolute;
    top: 25px;
    right: 5px;
}

.hide .dropdownArrowMessenger{
    display: none;
}


.menuButton:focus , .menuButton:hover ,.menuButton:active {
background: transparent !important;
border: 0px !important;
box-shadow: none !important;
}


@media only screen and (max-width: 600px) and (min-width: 300px){

    .userNameMenu{
        display: none;
    }
    /* .dropdown-label {
        width: 185px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;

    }
    .dropdown-parent div {
        padding: 0;
        margin-right: 2px;
        z-index: 999999;
    }
    .dropdown{
        display: block;
        background-color: #FFFFFF;
        position: absolute;
        right: 30px;
        top: 5vw;
        width: 200px !important;
    } */
}
@media only screen and (min-width: 601px) and (max-width: 1500px){
    /* .dropdown-label {
        width: 220px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        margin-right: 2px;
    } */

}
@media only screen and (min-width: 601px) and (max-width: 1000px){
    /* .dropdown{
        display: block;
        background-color: #FFFFFF;
        position: absolute;
        right: 30px;
        top: 5vw;
        width: 20vw !important;
    } */
}

@media only screen and (min-width: 1501px){
    /* .dropdown-label {
        width: 250px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        margin-right: 2px;
        width: 20vw !important;
    } */
}



.dropdown-parent img {

    border-radius: 50%;
    border: 2px solid #27c443;
}

.dropdown-parent div {
    padding: 0;
    /* float: right; */
    margin-right: 2px;
    /* width: auto; */
    z-index: 999999;
}

.dropdown-parent .txt-middle {
    margin: auto;
}

.dropdown {
    display: block;
    /* position: absolute; */
    right: -10px;
    top: 42px;
    /* width: 150px;
    padding: 10px; */

    position: absolute;
    width: 158px !important;
    height: 107px;
    /* left: 0px;
    top: 18px; */

    background: #17171D;
    opacity: 0.8;
    border-radius: 10px;
}

.dropdown div {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #FFFFFF;
    cursor: pointer;
    padding: 5px 20px !important;
}
.dropdown a , .dropdown a:hover {
    color: #FFFFFF;
    text-decoration: none;
}

.hide {
    display: none;
}
.notificationIconCss{
    /* height: 2vw !important;
    width: 2vw !important; */
    height: auto !important;
    max-width: 34px !important;
    border: none !important;
    margin-right: 10px;
    border-radius:0px !important;
    vertical-align: initial;
    
}
.dropdown-label{
    margin-right: 10px;
    margin-left: 2px;
}

.menuDisplayHide{
    display: none !important;
}


.userNameMenu{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    text-align: left;
    padding-left: 2px;
    padding-right: 2px;
    width: max-content;
    text-transform: capitalize;

}