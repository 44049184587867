.vieROV {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #c4c4c4;
}
.viewVIDERPR {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #4453d6;
}
.viewdrjohn {
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  color: #45443c;
}

.viewCo-Founder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #c4c4c4;
}
.viewEverus {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #c4c4c4;
}
.viewElMonte {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #c4c4c4;
}
.vieerate {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  padding-top: 20px;
  color: #060f21;
  padding-right: 10px;
}
.viewReviews4 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  text-align: center;

  color: #6c6c6c;
}
.vieABOUTME {
  padding-top: 21px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #4453d6;
}
.vewlongtext {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #17171d;
}
.vvieviewpro {
  margin-top: 41px;
  width: 233px;
  height: 42.71px;
  border: none;
  background: #4453d6;
  border-radius: 10px;
}
.viewpro2 {
  margin-top: 8px;
  width: 233px;
  height: 42.71px;

  background: #6dc5cc;
  border-radius: 10px;
}
.viServiceArea {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding-top: 12px;
  color: #c4c4c4;
}
.viFrancisco {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #6c6c6c;
}
.viOFFERED {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #4453d6;
}
.viRatingsReviews {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  padding-left: 455px;

  color: #6c6c6c;
}
.vieRectangle082 {
  margin-top: 5px;
  display: flex;
  width: 1032px;
  height: 58px;
  justify-content: space-around;
  align-items: center;

  background: #4453d6;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.Rectangle082ViewAll {
  margin-top: 5px;
  display: flex;
  width: 100%;
  height: 58px;
  justify-content: space-around;
  align-items: center;

  background: #4453d6;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.vieGEServici {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #ffffff;
}
.vieUSDperhou {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  align-items: center;

  color: #ffffff;
}
.vieProvided29 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  align-items: center;
  text-align: center;

  color: #ffffff;
}
.viratingno {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;

  color: #ffffff;
}
.viViewAll {
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  text-align: center;

  color: #ffffff;
}
.virec {
  justify-content: space-evenly;
  align-items: center;
  width: 620px;
  height: 58px;
  display: flex;

  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.vieShimadzu {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #6c6c6c;
}
.vieusd85 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #6c6c6c;
}
.vieser {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  align-items: center;
  text-align: center;

  color: #6c6c6c;
}
.viewServicecard1 {
  padding: 12px;
  width: 353px;
  height: 156px;

  background: #333333;
  border-radius: 10px;
}
.viLouisWen {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #ffffff;
}
.vitime {
  font-style: italic;
  font-weight: normal;
  font-size: 12px;

  opacity: 0.6;
  color: #ffffff;
}
.viewS-o-D {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
}
.vieallreviews {
  margin-top: 22px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  color: #4453d6;
}
.notificationCSSProviderProfile {
  width: 480px !important;
}
.parentRowProviderProfile {
  padding: 20px;
  width: calc(100vw - 490px) !important;
  background: #ffffff !important;
  overflow-x: auto;
}
.inlineFlex100ProviderProfile {
  width: 100% !important;
  display: inline-flex !important;
}
.servproimg{
  width: 125px;
  height: 125px;
  border-radius: 125px;
}