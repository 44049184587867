.paymentContainer{
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
.notificationContainer{
  width: calc(100vw - 10px); 
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 40px;
  height: calc(100vh - 80px);
  overflow-y: auto;

  
}


.flex{
  height: 100% !important;
}
.displayInlineFlex{
  display: inline-flex;

}
.notificationText{
  color: #FFFFFF;
  text-transform: uppercase;
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
}
.rootNotificationContainer{
  /* overflow: hidden; */
  width: 100%;
  /* height: calc(100vh - 150px);
  overflow-y: auto; */
}
.mainCardDiv{
  margin-top: 20px;
  background: #060F2130;
  border-radius: 30px;
  padding: 20px;
  padding-left: 0px;
  width: calc(100vw - 100px);
  overflow: hidden;
}
.cardHeadingText{
  color: #FFFFFF;
  margin-top: 10px;
  margin-left: 18px;
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 28px;
  
}
.headingIcon{
  width: 30.87px;
  height: 30.87px;
  left: 372.82px;
  top: 190px;

}

.notificationMessagescontainer {
  /* display: flex;
  flex-direction: column; */
  border-radius: 8px;
  /* flex-grow: 1; */
  padding: 10px ;

  width: 328px;
  /* min-width: 328px;
  max-width: 328px; */

  height: 117px;

  /* background: #17171D; */
  box-sizing: border-box;
  border-radius: 10px;
  margin-right: 19px;

  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;
  overflow: hidden;

}


.notificationMessagescontainer .topTitleHeader{
  display: inline-flex;
  width: 100%;
}
.notificationMessagescontainer .topTitleHeader .headingContainer{
  width: 250px;

}

.notificationMessagescontainer .bottomContent{
  display: flex;
  flex: 4 1;
  flex-direction: row;
  padding: 2px 0px;
}


.notificationMessagescontainer .notification-image {
  display: inline-flex;
  width: 70px;
  /* display: flex;
  flex: 1; */
  align-content: center;
}

.notificationMessagescontainer .notification-image img {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 1px solid #FFFFFF;
  margin-right: 10px;
}
.mleft10{
  margin-left: 10px;
}
.messageDate{
  font-weight: normal;
  font-size: 14px;
  color: #FFFFFF;
}
.redColorEllipse{
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: #24A2A8;
  margin-right: 6px;
  margin-top: 8px
}
.titleName{
  font-size: 18px;
  margin: 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  height: 25px;
  color: #FFFFFF;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin: 0px;
  line-height: 19px;
  overflow: hidden;
}
.discription{
  font-weight: normal;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.6;
  margin-right: 10px;
}

.notificationMessagescontainer  .notification-info {
  display: inline-grid;
  align-items: center;
  /* display: flex;
  flex: 6;
  flex-direction: column; */
}

.notificationMessagescontainer .notification-info .notification-heading {
  /* display: flex;
  flex: 1; */
  font-family: Segoe;
  font-size: 18px;
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* height: 25px; */
  color: #FFFFFF;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin: 0px;
  line-height: 19px;
  overflow: hidden;
  width: 100%;
}

.notificationMessagescontainer  .notification-info .notification-body {
  /* display: flex;
  flex: 4; */
  width: 100%;
 
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-weight: normal;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.6;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin: 0px;
  line-height: 19px;
}
.mTop5{
  margin-top: 5px;
}
.profileIMGCss{
  width: 50px;
  height: 50px;
  margin-top: 5px;
  border-radius: 100px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  margin-right: 10px;

}
.padd0{
  padding: 0px;
}

.horizontalScroll{
  /* width: 75vw; */
  /* width:calc(100vh - 300px); */
  /* width: 800px; */
  /* width:calc(100vw - 120px); */

  /* overflow-x: auto;
  overflow-y: hidden; */
  /* margin-left: 30px; */
  padding-bottom: 5px;
}

.noNotificationFound {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  opacity: 0.6;
  margin: auto;
  width: 220px;
  text-align: center;
}
.removeButton{
  background: transparent;
  border: none;
  padding: 0px;
  margin-top: -5px;
}

.removeButton:hover , .removeButton:active , .removeButton:focus{
  background: transparent;
  border: none;
  box-shadow: none !important;
}

.removeButton img{
  width: 24px;
}
.deleteModal{
  background: #17171D;
  border-radius: 30px;
  padding: 23px;
}
.deleteModal .confirmmsg{
  
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.8;
}
.deleteModal .confirmTitle{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  
}
.deleteModal .buttons{
  min-width: 100px;
  height: 40px;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  border-radius: 30px;
 
  
  border-radius: 30px;
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  color: #FFFFFF !important;
  border: 0px;

}
.deleteModal .noButton{
  background: #FFFFFF50;
  color: #FFFFFF !important;

}
.deleteModal .yesButton{
  background: #4453D6;
  /* border-radius: 30px; */
  margin-left: 10px;
}
.deleteModal .buttonDiv{
  display: inline-flex;
  float: right;
}

.viewAllnotificationButton{
    background: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    height: 45px;
    width: 150px;
    margin-left: 100px;
}
.viewAllnotificationButton:active , .viewAllnotificationButton:focus , .viewAllnotificationButton:hover {
  background: none;
  box-shadow: none !important

}
.categoryBg{
  min-width: 231px;
  width: max-content;
  background: #17171D;
  border-radius: 0px 30px 30px 0px;
  margin-bottom: 10px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 20px;

}

.viewButton{
  background: transparent !important;
  border: none;
  padding: 5px;
}
.viewButton:hover{
  background: transparent !important;
}

.notibackbutton{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #D9D9D9 !important;
  background: transparent !important;
  border: none;
  float: right;

}
.notibackbutton div{
  width: 30px;
  height: 30px;
  background: #4453D6;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
 

}
.notibackbutton span{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
}
.notibackbutton img{
  width: 20px;
}


@media only  screen and (min-width: 1920px){
  .notificationContainer {
      width: calc(100vw - 300px);
      margin-top: 50px;
      padding-left: 40px;
      padding-bottom: 40px;
      margin-left: 300px;
  }
  .mainCardDiv{
    margin-top: 20px;
    border-radius: 30px;
    padding: 20px;
    padding-left: 0px;
    width: calc(100vw - 400px);
    overflow: hidden;
  }

}

