
.welcomeScreenContainer{
  width: calc(100vw - 10px); 
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 40px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  justify-content: center;
  display: inline-flex;

  
}

.flex{
  height: 100% !important;
}
.welComeMainTitle{
  font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 48px;
line-height: 50px;
/* or 104% */

text-align: center;

color: #FFFFFF;
}
.welcomeContentDescription{

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 28px;
  text-align: center;

  color: #FFFFFF;
}
.WelcomeinnerBox{
  max-width: 1000px;
  width: 1000px;
  min-width: 500px;
  width: calc(100vw - 20px);
  margin-top: 50px;




}
.titleBlackButton{
  min-width: 119px;
  min-height: 46px;

  background: #17171D !important;
  color: #FFFFFF !important;
  border-radius: 30px;
  border: none !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: inline-flex;
  width: max-content;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  cursor: initial !important;
}
.welcomeSmalldescription{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-align: left;


  color: #FFFFFF;
  min-width: 550px;
}
.welcomeinnerSmall{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.categoryButtonsdiv{
  width: 170px;
  margin-right: 20px;

}

.welcomeinnerSmall .categorydiv{
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  justify-content: flex-end;
}
.readyTogoButton{
  background: #4453D6 !important; 
  border-radius: 30px;
  height: 54px;
  width: 327px;
  border-radius: 30px;
  border: none !important;
  font-family: Segoe;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF !important;
  margin-top:50px;


}

@media only  screen and (min-width: 1920px){
  .welcomeScreenContainer {
      width: calc(100vw - 300px);
      margin-top: 50px;
      padding-left: 40px;
      padding-bottom: 40px;
      margin-left: 300px;
  }

}

