.Everustextf {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #333333;
  padding-left: 20px;
}
.Middleville {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  margin: 0px;
  padding-left: 10px;
}
.registereddetaildiv {
  width: 472px;
  min-height: 200px;
  background: #333333;
  border-radius: 10px;
  margin-top: 17px;
  padding-bottom: 20px;
}
.Registeredbusines {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0px;
  color: #ffffff;
  opacity: 0.6;
  padding-left: 10px;
  padding-top: 20px;
}
.EverusbisimedsMedica {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 10px;
  margin: 0px;
  word-break: break-word;
}
.displayedbusnieenam {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0px;
  color: #ffffff;
  opacity: 0.6;
  padding-left: 10px;
  padding-top: 10px;
}
.YearofEsta {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.6;
  margin: 0px;
  padding-left:10px;
  padding-top: 10px;
}
.bsuniessState {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.6;
  padding-left: 124px;
  padding-left: 10px;
  margin: 0px;
  padding-top: 10px;
}
.businessCountry {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  margin: 0px;
  opacity: 0.6;
  padding-top: 10px;
  padding-left: 10px;
}
.adminmemeredildiv {
  width: 472px;
  min-height: 200px;
  background: #333333;
  border-radius: 10px;
  margin-top: 8px;
  margin-left: 15px;
  padding-bottom: 10px;
}
.membsccers5 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  text-align: right;
  color: #ffffff;
  margin: 0px;
  padding-right: 25px;
}
.businessprofiemaindiv{
    padding: 30px;
    height: calc(100vh - 130px);
    overflow-y: auto;
    width: calc(100vw - 10px);
    overflow-x: auto;
}


/* width */
.businessprofiemaindiv::-webkit-scrollbar {
  width: 8px;
  background: #17171D !important; 

}

/* Track */
.businessprofiemaindiv::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey !important;  */
  border-radius: 10px;
  background: #17171D !important; 
}
 
/* Handle */
.businessprofiemaindiv::-webkit-scrollbar-thumb {
  background: #17171D !important; 
  border-radius: 10px;
}

.Amarclacrekfnv {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
  padding-left: 10px;
}
.CoFocdscvusijnunder {
  padding-left: 10px;
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  margin: 0px;
  opacity: 0.7;
}
.Universscdscity {
  padding-left: 10px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin: 0px;
  color: #ffffff;
  opacity: 0.7;
}
.dbusniessivider {
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 424px;
  margin: auto;
  margin-top: 2px;
}
.businessprofileEditbutton {
  width: 234px;
  height: 50px;
  background: transparent !important;
  border-radius: 30px;
  border: 1px solid #4453d6 !important;

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #4453d6 !important;
}
.sendmsgbuttoon {
  background: #4453d6 !important;
  border-radius: 30px;
  width: 234px;
  height: 49px;
  border: none;
  margin-top: 46px;
  color: #ffffff !important;

  /* margin-left: 120px; */
}
.yearcdscsd20 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
  padding-left: 10px;
}

.year2008 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
  padding-left: 10px;
}
.registreddetailhead {
  background: #4453d6;
  border-radius: 0px 30px 30px 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  margin-top: 17px;
  width: 203px;
  height: 44px;
}
.businessInf0Box{
  padding-left: 35px;

}
.backbuttonbusiness {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #666666;
  background: transparent;
  border: none;
  margin-top: 5px;
  margin-right: 31px;
}
.backbuttonbusiness:hover {
  background: transparent;
  color: #666666;
}
.backbuttonbusiness div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.backbuttonbusiness span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  color: #666666;
}
.backbuttonbusiness img {
  width: 15px;
}

.paymentContainer {
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
.mainContainer {
  width: 100%;
  font-family: Helvetica;
  overflow: hidden;
  overflow-y: scroll;
}
.myProfileTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  margin-left: 40px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.bussinessprofileImage {
  box-sizing: border-box;
  margin: 15px;
  margin-right: 25px;

  border: 3px solid #1fe03e;
  box-sizing: border-box;
  width: 10.6vw;
  height: 10.6vw;
  border-radius: 5.3vw;
  min-width: 50px;
  min-height: 50px;
}
.innerContainer {
  margin-left: 35px;
  margin-right: 35px;
  position: relative;
}
.userProfileBox {
  padding: 5px;
  background: #ffffff;
  border-radius: 9px;
  margin-right: 20px;
}

.userProfileBox .username {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  margin-top: 25px;
}

.userProfileBox .workplace {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
}
.workplace img {
  width: 18px;
  margin-right: 5px;
}

.userProfileBox .mailPlace {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4092b7;
  margin-bottom: 5px;
}
.mailPlace img {
  width: 25px;
  margin-right: 5px;
}

.userProfileBox .univercityplace {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4092b7;
  margin-bottom: 5px;
}
.univercityplace img {
  width: 18px;
  margin-right: 10px;
  margin-left: 5px;
}
.userProfileBox .localtion {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 5px;
}
.localtion img {
  width: 15px;
  margin-right: 9px;
}
.userProfileBox .messageProfileButton {
  float: right;
  margin: 15px;
  background: linear-gradient(180deg, #439fc8 0%, #4358a7 100%);
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: 150px;
  height: 50px;
  color: #ffffff;
  border: 0px;
}

.profileDiv {
  height: 100px !important;
  width: 100%;
  background: white;
  border-radius: 10px;
  margin-right: 25px;
}
.txtColor {
  color: #fff !important;
}
.padd10 {
  padding: 10px;
}
.padd14 {
  padding: 14px;
  margin-top: 17px;
  word-break: break-word;
}
.marginT10 {
  margin-top: 10px;
}

.businesscard {
  width: 100%;
  background: #333333;
  border-radius: 9px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 25px;
}

.buttonCard {
  width: 100%;
  background: #333333;
  border-radius: 9px;
}

.titleP {
  margin-bottom: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.titlePColor {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 5px;
}

.nameStyle {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.dateText {
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.columnTitle {
  margin-bottom: 20px;
}

.userProfileBox .messageButton {
  float: right;
  margin: 15px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #c4c4c4;
  background: linear-gradient(180deg, #439fc8 0%, #4358a7 100%);
}

.backButton {
  background: transparent;
  border: 0px;
}
.backButton img {
  width: 23px;
}

.businessMemberProfile{
  width: 50px;
   height: 50px;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 86px;
}
.adminIconImage{
  width: 25px;
  position: relative;
  left: 10px;
  top: 30px;
  height: 20px;
}
.connectBlueButton{
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  display: inline-flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF !important;
  background: #4453D6 !important;
  border-radius: 30px;
  min-width: 89px;
  height: 31px;
  border: 0px !important;
  width: max-content;
}
.businessMemberList{
    display: inline-flex;
    margin: 5px;
    align-items: center;
}

.businessprofileDetailImageDiv{
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 86px;
  margin-right: 20px;
  /* border: 4px solid #4453D6; */
  position: relative;
}
.businessprofileDetailImageDiv::before{
  position: absolute;
  content:'';

  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 86px;
  margin-right: 20px;
  border: 4px solid #4453D6;
}
.manageBusinessMemberButton{
  border-radius: 30px;
  border: none !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #FFFFFF;
  padding-right: 15px;
  height: 40px;
}




@media only screen and (min-width: 1025px) and (max-width: 1919px) {

  .businessprofiemaindiv{
    width: calc(100vw - 500px);
    
  }
}

@media only screen and (min-width: 1920px)   {

  .businessTopContainer{
    display: inline-flex;
    position: absolute;
    top: 15px;
    width: 100%;
  }

  .businessprofiemaindiv{
    padding: 30px;
      height: calc(100vh - 50px);
      /* overflow-y: auto; */
      width: calc(100vw - 800px);
      margin-left: 330px;
      /* overflow-x: auto; */
  }
  .backbuttonbusiness{
    margin-top: 20px;
  }
 }


