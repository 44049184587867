.marketplacedovox {
  /* width: 1007px; */
  min-height: 200px;
  border-radius: 10px;
  background: #333333;
  padding-top: 1px;
}
.SliderBox2 {
  width: 208.22px;
  overflow: hidden;
  background: #17171d30;
  /* opacity: 0.3; */
  border-radius: 10px;
  padding-bottom: 10px;
  margin: auto;
  cursor: pointer;
  /* height: 300px; */
}

.SliderBox2 .sliderProductImg{
    width: 208px;
    height: 200px;
}

.SliderBox2 .ProductBox{
  width: 150px;
  margin-left:20px;
}
.p-button{
  background: transparent !important;
  border: 0px  !important;
  color: #FFFFFF  !important;
  padding: 0px !important;
  box-shadow: none !important;
}

.p-carousel-prev span, .p-carousel-next span{
  font-size: 50px !important;
}
.placedorderhesoihjcl{
background: #4453D6;
border-radius: 0px 30px 30px 0px;
width: 185px;
height: 44px;
margin-top: 17px;
font-family: Segoe;
font-style: normal;
font-size: 16px;
color: #FFFFFF;
text-align: center;
line-height: 43px;
}
.placedorderhesoihjcl span{
    font-weight: bold;
}

.maindivorderhistory{
    padding-left: 25px;
    padding-right: 25px;
    /* width: calc(100vw - 10px); */
    margin-top: 20px;
}
.marketPlaceTitle{
  padding-left: 20px;
  padding-top: 20px;
}

.marketPlaceTitle .MainTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 38px;
  margin: 0px;
  color: #17171D;
}
.marketPlaceTitle .subTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 40px;
  margin: 0px;
  color: #333333;
}

.mainContainerPlaceOrders{
  display: inline-flex;
  width: calc(100vw - 500px);
  height: calc(100vh - 90px);
  overflow: auto;
}
.placeOrdersChildContainer{
  width: calc(100vw - 0px);
}

.buyerSideorderhistory{
  background: #4453D6;
  border-radius: 10px;
  margin-top: 25px;
  padding-top: 20px;
  /* width: 1007px; */
  min-height: 200px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  }

  .OrderHistoryButtonBox{
    background: #333333;
    border-radius: 0px 30px 30px 0px;
    width: 185px;
    height: 44px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 10px;
  }

  .buyerOrderHistoryList{
    display: inline-flex;
    background: #17171D;
    opacity: 0.9;
    border-radius: 10px;
    padding: 18px;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 30px;
    cursor: pointer;
  }

  .buyerOrderHistoryList .orderDate{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    color: #FFFFFF;
    min-width: 130px;
  }
  .buyerOrderHistoryList .orderID{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    align-items: center;
    min-width: 150px;
    
    color: #FFFFFF;
  }
  .buyerOrderHistoryList .orderName{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    align-items: center;
    min-width: 150px;

    color: #FFFFFF;
  }
  .buyerOrderHistoryList .orderPrice{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    align-items: center;
    color: #FFFFFF;
    min-width: 150px;
    text-align: center;
  }
  .buyerOrderHistoryList .orderStatus{
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    color: #C4C4C4;
    min-width: 150px;
    text-align: center;
    display: inline-flex;
    justify-content: center;

  }
  .buyerOrderHistoryList .orderStatus div{
    width: 12px;
    height: 12px;
    border-radius: 50px;
    margin-right: 5px;
  }

  .noOrderHistory{
    text-align: center;
    color: #FFFFFF;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 19px;
    /* padding-left: 200px; */
    text-align: center;
  }

 

  @media only  screen and (max-width: 1024px){
    .mainContainerPlaceOrders{
      display: inline-flex;
      width: calc(100vw - 20px) !important;
      height: calc(100vh - 90px);
      overflow: auto;
    }
  
  }

  @media only  screen and (min-width: 1920px){
    .maindivorderhistory {
      padding-left: 25px;
      padding-right: 25px;
      width: 100%;
      margin-top: 20px;
  }
  .placeOrdersChildContainer{
    width: calc(100vw - 800px);
    margin-left: 300px;
  }

  }

 