.errormaindiv {
  background: linear-gradient(
    314.28deg,
    #892f96 30.57%,
    #6243ba 45.87%,
    #4453d6 60.29%,
    #4453d6 75.6%
  );
  height: 100vh;
}
.errorimg {
  width: 100px;
  padding-top: 186px;
}
.oops {
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  text-align: center;
  color: #ffffff;

  padding-top: 17px;
}
.errortext {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  padding-top: 27px;
}
.errorbutton {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  width: 275px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-top: 40px;
  height: 38px;
  background: transparent;
}
.aligncenter{
    text-align: center;
}