.app-logo {
    height: 40px;
}

.App-logo-container {
    height: 100px
    /* padding: 36px 0px 31px 37px;
    margin: auto; */
}

.app-logo.sideMenucollapsing{
    width: 170px;
}

.side-drawer {
    background-color: #17171d;
    width: 294px;
    height: 100vh;
    border-radius: 0px 30px 30px 0px;
    overflow: hidden;
}

.side-drawer .navigation-item{
    background-color: #3333334d;
  
}


.side-drawer .navigation-item.disabled{
    background-color: #292929;
    opacity: 0.25;
}

.side-drawer .navigation-item.is-open{
    background-color: #4453D6;
}


.marketplacesubmenuItem {
    opacity: 1;
}
.side-drawer .marketplacesubmenuItemView.is-open{
    background-color: #4453d680;
}


.sideMenucollapsing{
    cursor: pointer;
}
.innerMenuItem{
    /* height: 100%; */
    height: calc(100vh - 100px);
    overflow: hidden;
    overflow-y: scroll;
}

.innerMenuItem::-webkit-scrollbar {
    display: none;
}

@media only screen and (min-width: 1920px)   {
    .container-flex .container-sidebar{
        display: block !important;
    }

}
