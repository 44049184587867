.group-detail-container {
  display: flex;
  flex-grow: 1;
  padding-left: 25px;
}

.group-detail-container .info-container {
  display: flex;
  flex: 6;
  padding: 20px;
}

.group-detail-container .contact-container {
  display: flex;
  flex: 3;
  padding: 10px;
}

.contacts-header-img {
  height: 2vh;
  cursor: pointer;
}

.detail-notification-modal {
  max-width: 350px;
}

.detail-notification-modal .member-edit-child {
  color: #ddd;
  font-weight: bolder;
  padding: 20px;
  cursor: pointer;
}

.detail-notification-modal .border-bottom {
  background-color: #ddd;
  padding-top: 0.1px;
}

.detail-notification-modal .modal-body {
  padding: 0px;
}

.remove-member-modal {
  max-width: 350px;
}

.remove-member-modal .remove-member-modal-body {
  padding-top: 20px;
  color: #a0a1ab;
}

.group-info-modal {
  max-width: 350px;
  height: 350px;
}

.group-info-modal .modal-body {
  display: flex;
  flex-grow: 1;
}

.add-new-members-modal {
  max-width: 320px;
}

.contactGroupList {
  background: #333333;
  border-radius: 10px;
  margin-left: 35px;
  min-width: 200px;
  margin-top: 0px;
  height: max-content;
  width: max-content;
  padding-bottom: 10px;
}
.contactGroupList .userNameContactBox{
  margin-left: 10px;
  width: 200px;
}

.contactAdminButtonTitle {
  background: #4453d6 !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  color: #ffffff;
  border: none !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 209px;
  height: 44px;
}
.contactAdminButtonTitle span {
  font-weight: bold;
}
.memberCount {
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  width: 180px;
  padding-top: 15px;
}

.contactMemberGroupList {
  display: inline-flex;
  margin-top: 5px;
  padding-bottom: 7px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  /* width: 340px; */
  padding-left: 10px;
  width: 100%;
}

.contactMemberGroupList .userImageIcon {
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 86px;
  width: 50px;
  height: 50px;
}
.contactMemberGroupList .userNameText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin: 0px;
}
.contactMemberGroupList .userPositionText {
  margin: 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #ffffff;

  opacity: 0.7;
}
.EditListTopBar {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  min-width: 320px;
  color: #ffffff;
  margin-bottom: 2px;
  margin-top: 20px;
}
.removeMemberBtn {
  background: transparent !important;
  border: none !important;
  padding: 0px;
  width: 100px;
}
.adminShowButton{
  background: transparent !important;
  border: none !important;
  padding: 0px;
  width: 100px;
  cursor: unset !important;
}

.addNewMeberTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  align-items: center;

  color: #ffffff;
  margin: 0px;
  margin-bottom: 5px;
  padding-left: 20px;
}

.searchMemberInputBoxField {
  background: #17171d;
  /* opacity: 0.5; */
  border-radius: 30px;
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;

  color: #ffffff;
  height: 45px !important;
  width: 300px;
  padding-left: 50px;
}
.searchMemberInputBox {
  position: relative;
  margin-left: auto;
}
.memberSearchIcon {
  width: 25px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.addedUsersScroller{
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  padding: 20px;
  padding-top: 10px;
  /* justify-content: center; */

}

.groupDetailScroller{
  display: inline-flex;
  height: auto;
  margin-top:10px; 
  padding-left: 20px;
}

@media only screen  and (min-width: 600px)  and (max-width: 1024px) {

.groupDetailScroller{
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 90px);
  display: initial;
}

.contactGroupList {
  background: #333333;
  border-radius: 10px;
  margin-left: 0px !important;
  margin-top: 15px !important;
  min-width: 350px;
  width: max-content;
}
.contactGroupList .userNameContactBox{
  width: 150px;
}
.addedUsersScroller {
  height: auto;
  overflow-y: hidden;
}
.groupInfoCard{
  min-width: 300px;
  width: 360px !important;
}
}

@media only screen and (min-width: 1920px) {
  .group-detail-container {
    padding-left: 322px;
  }
}
