.compose-panel {
  
  
  border-bottom: 1px solid #d9dfe2;
  .compose-btn {
    height: 50px;
  }
}
.deleteComponseButton{
  background: transparent !important;
  border: 1px solid #17171D;
}
.sendMailButton{
  background: #17171D !important;
  color: #FFFFFF !important;
}
.disableOpacity{
  opacity: 0.7 !important;
}
.removeOpacity{
  opacity: initial !important;
}

.compose-dialog {
  height: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  > .modal-content {
    height: 80%;
    .editor-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      top: 190px;
      bottom: 42px;
      .quill {
        height: 100%;
        .ql-container.ql-snow {
          border: 0;
        }
      }
      
    }
  }

  .message-fields {
    .input-group {


      margin-bottom: 2px;

      .componseInput{
        background-color: #f9f9f9;
        min-height: 40px;
        color: #000000;
        border: 1px solid #ced4da;
      }
      .componseInput::placeholder {
        color: #999999 !important;
      }

      .input-group-text {
        min-width: 75px;
        background-color: #f9f9f9;
      }

      .form-control {
        border-left-width: 0;
        &.is-invalid {
          border-color: red;
        }
        &:placeholder-shown {
          font-style: italic;
          font-size: 11px;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

    }

  }
}

